import React, { useState, useEffect } from 'react';

import { Text, HStack, Box, Select, Button,
  Modal, ModalBody, ModalContent, ModalOverlay, ModalHeader, ModalFooter, ModalCloseButton, Input,
  useColorModeValue, Tooltip,
} from '@chakra-ui/react';

import { useGetCompareSaves, useSaveCompare } from './compareHooks';
import { BookmarkIcon } from '@heroicons/react/24/outline';


function CompareSave(props) {
  const { currentCompare, currentView } = props;

  const [saveOpen, setSaveOpen] = useState(false);
  const [loadSavedOpen, setLoadSavedOpen] = useState(false);
  const [nameValid, setNameValid] = useState(true);
  const [saveId, setSaveId] = useState(null);
  const [newCompareName, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { data, error, refetch } = useGetCompareSaves();
  const saveCompare = useSaveCompare(saveId);

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    setErrorMessage('');
    setName('');
  }, [saveOpen])

  const handleChange = event => {
    setErrorMessage('');
    const newName = event.target.value;
    if (newName.length > 0 && newName.length < 4) {
      setErrorMessage('Name must be at least 4 characters');
    } else {
      // see if name is used
      const saves = data.response || [];
      saves.forEach(save => {
        if (save.name.toLowerCase().trim() === newName.toLowerCase().trim()) {
          setErrorMessage('Name already in use')
        }
      })
    }
    setName(newName);
  }

  const doSave = () => {
    const saveData = {
      name: newCompareName.trim(),
      lessons: currentCompare,
      view: currentView,
    }
    saveCompare.mutate(
      { saveData  },
      { onSuccess: () => { 
        refetch()
      }, 
        onError: (err) => {alert(err)} },
    );
    setSaveOpen(false);
  }
 
  return (
    <>
      {/* <div style={{ float: 'right', marginLeft: 24, marginRight: 12 }}>
        <Button size='sm' variant='secondary' onClick={() => setSaveOpen(true)}>
          Save Comparison 
        </Button> */}
         <Tooltip label='Save This Comparison'>
        <Button
          variant="ghost"
          size='md'
          color={useColorModeValue('primary.200', 'primary.400')}
          onClick={() => setSaveOpen(true)}
          ml={2}
          p={0}
          >
          <BookmarkIcon className="h-6 w-6" color={useColorModeValue('secondary.200', 'secondary.400')} />
        </Button>
        </Tooltip>

      <Modal isOpen={saveOpen} size='xl'>
        <ModalOverlay />
        <ModalContent m={48}>
          <ModalHeader>
            <Text>Save this Comparison</Text>
          </ModalHeader>
          <ModalCloseButton onClick={() => setSaveOpen(false)} />
          <ModalBody>
            <Text mb='8px'>Name:</Text>
            <Input
              value={newCompareName}
              onChange={handleChange}
              placeholder='Enter a name for this comparison'
            />
            {errorMessage && (
              <Text as='i' fontSize='xs' color='#ff0000' ml={3}>{errorMessage}</Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={() => setSaveOpen(false)}>
              Cancel
            </Button>
            <Button
              variant='secondary'
              isDisabled={newCompareName.length > 0 && !!errorMessage}
              onClick={doSave}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  );
}

export default CompareSave;