import { useEffect, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Stack,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Select,
  HStack,
  VStack,
  filter
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useDeleteUser, useFilterUsers } from './userHooks';
import { DataTable } from '../../components/Tables';
import PageTitle from '../../components/PageTitle/PageTitle';
import { EditUserDrawer, CreateUserDrawer } from './components';
import trackEvent from '../../utlis/trackEvents';
import { useUser } from '../../store/useAppStore';

const capitalizeFirstLetter = (string) => string?.charAt(0).toUpperCase() + string?.slice(1) || '';

const columnHelper = createColumnHelper();
const columns = [
  columnHelper.accessor('firstName', {
    cell: (data) => capitalizeFirstLetter(data.getValue()),
    header: 'First Name',
  }),
  columnHelper.accessor('lastName', {
    cell: (data) => capitalizeFirstLetter(data.getValue()),
    header: 'lastName',
  }),
  columnHelper.accessor('email', {
    cell: (data) => data.getValue(),
    header: 'Email',
  }),
  columnHelper.accessor('institute', {
    cell: (data) => data.getValue(),
    header: 'Institution',
    sortingFn: (a, b) => {
      //get list of institutes
      console.log(a,b)
      const aInst = a.original.organizations.filter((el) => el.type === 'institute');
      const bInst = b.original.organizations.filter((el) => el.type === 'institute');
      if (aInst.length === 0) return 1;
      if (bInst.length === 0) return -1;
      return aInst[0]?.name > bInst[0]?.name ? 1 : -1;
    },
    sortDescFirst: false,
  }),
  columnHelper.accessor('district', {
    cell: (data) => data.getValue(),
    header: 'District',
    sortingFn: (a, b) => {
      const aDist = a.original.organizations.filter((el) => el.type === 'district');
      const bDist = b.original.organizations.filter((el) => el.type === 'district');
      if (aDist.length === 0) return 1;
      if (bDist.length === 0) return -1;
      return aDist[0]?.name > bDist[0]?.name ? 1 : -1;
    },
    sortDescFirst: false,
  }),
  columnHelper.accessor('school', {
    cell: (data) => data.getValue(),
    header: 'School',
    sortingFn: (a, b) => {
      const aSchool = a.original.organizations.filter((el) => el.type === 'school');
      const bSchool = b.original.organizations.filter((el) => el.type === 'school');
      if (aSchool.length === 0) return 1;
      if (bSchool.length === 0) return -1;
      return aSchool[0]?.name > bSchool[0]?.name ? 1 : -1;
    },
    sortDescFirst: false,
  }),
  columnHelper.accessor('role', {
    cell: (data) =>
      data.row.original.role.map((el) => (
        <Box key='el' textTransform='capitalize'>
          {el.replace('-', ' ')}
        </Box>
      )
      ),
    header: 'Role',
  }),
  columnHelper.accessor('status', {
    cell: (data) => capitalizeFirstLetter(data.getValue()),
    header: 'Status',
  }),
];
const AdminUsers = () => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [confimrationModal, setConfirmationModal] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filterBy, setFilterBy] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [tableData, setTableData] = useState([]);
  const user = useUser();
  const { data: users } = useFilterUsers({});
  console.log(users)
  const { mutate: deleteUser } = useDeleteUser();
  const onCloseEdit = () => {
    setIsOpenEdit(false);
    setEditUser(null);
  }

  // If the role changes, clear the errors as the validation for school and institute will change
  useEffect(() => {
    if (editUser) setIsOpenEdit(true);

  }, [editUser])

  const handleDelete = () => {
    trackEvent({
      page: 'AdminUsers',
      params: '',
      event: 'Delete User',
      me: user,
      description: 'Delete User',
      details: `deleted userId: ${editUser._id}`,
      value: 0,
    })
    deleteUser(editUser._id);
    setConfirmationModal(false);
    onCloseEdit();
  };
  // If the role changes, clear the errors as the validation for school and institute will change

  useEffect(() => {
    if (users) {
      if (filterBy === '') {
        setTableData(users?.map((user) => { 
          return {
            ...user,
            roles: user.role.includes(filterBy)
          }
        }
        ));
      } else {
        setTableData(users?.filter((user) =>  user.role.includes(filterBy) ).map((user) => {
          return {
            ...user,
            type: user.role.includes(filterBy)
          }
        }
        ));
      }
    }
  }, [users])


  const filterData = (chosenRole) => {
    setFilterBy(chosenRole);
    if (chosenRole === '') {
      setTableData(users?.map((user) => { 
        return {
          ...user,
          roles: user.role.includes(chosenRole)
        }
      }
      ));
    } else {
      setTableData(users?.filter((user) =>  user.role.includes(chosenRole) ).map((user) => {
        return {
          ...user,
          type: user.role.includes(chosenRole)
        }
      }
      ));
    }
  };

  return (
    <>
      <Box>
        <PageTitle title='Manage Users'>
          <Button variant='secondary' leftIcon={<AddIcon />} size='sm' onClick={onOpen} >
            {' '}
            Invite User
          </Button>
        </PageTitle>
   
      </Box>
      {/* THIS SHOUL BECOME ITS OWN COMPONENT */}
      <CreateUserDrawer isOpen={isOpen} onClose={onClose} users={users}/>
      <EditUserDrawer isOpen={isOpenEdit} onClose={onCloseEdit} users={users} editUser={editUser} openConfirmation={() => setConfirmationModal(true)}/>
      <Select 
        placeholder='Filter By...' 
        onChange={(e) => filterData(e.target.value)} 
        size='sm'
        w='10%'
        fontSize='sm'
        alignSelf='flex-start'
        pl={6}
        >
        <option value=''>All</option>
        <option value='admin'>Admin</option>
        <option value='instructor'>Instructor</option>
        <option value='coach'>Coach</option>
        <option value='teacher'>Teacher</option>
        <option value='teacher-candidate'>Teacher Candidate</option>
        <option value='practicing-teacher'>Practicing Teacher</option>
      </Select>
      <DataTable columns={columns} data={tableData} customPageSize={25} onClickAction={(e) => setEditUser(e)} defaultSort='lastName'/>
      {/* <BasicDataTable columns={columns} data={users} customPageSize={25} noRedirect /> */}
      
      <Modal isOpen={confimrationModal} onClose={() => setConfirmationModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete {editUser?.email}?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={() => handleDelete()}>
              Delete
            </Button>
            <Button variant='ghost' onClick={() => setConfirmationModal(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

AdminUsers.propTypes = {};
export default AdminUsers;
