import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../store/useAppStore';
import { ROLES } from '../constants';

/**
 * A component that restricts access to a route based on user roles or redirects to the restricted error page if the user is not authorized.
 * @param {string[]} allowedRoles - The roles allowed to access the component.
 * @returns {JSX.Element} - The component's UI.
 */
const RestrictByRole = ({ allowedRoles }) => {
  const userRole = useUser().role;
  const hasRole = userRole.some((role) => allowedRoles.includes(role));
  let supportAllowed = false;
  if (allowedRoles.includes('support')) {
    // see if this user is a support user
    ROLES.forEach(role => {
      if (role.group === 'support' && userRole[0] === role.value ) {
       supportAllowed = true;
      }
    })
  }
  if (!hasRole && !supportAllowed) {
    return <Navigate to='/restricted' replace />;
  }
  return <Outlet />;
};

export default RestrictByRole;
