/* eslint-disable import/prefer-default-export */
// primary.400 and secondary.900 are the brand colors
const colors = {
  black: '#101010',
  lightGray: '#F1F1EF',
  mediumGray: '#DADADA',
  textGray: '#666666',
  uvaCyan: '#009FDF',
  uvaYellow: '#FDDA24',
  uvaTeal: '#25CAD3',
  uvaMagenta: '#EF3F6B',
  uvaGreen: '#62BB46',
  darkBlue: '#001016',
  gray: {
    50: '#fafafa',
    100: '#f1f1f1',
    200: '#e7e7e7',
    300: '#d4d4d4',
    400: '#adadad',
    500: '#7f7f7f',
    600: '#545454',
    700: '#373737',
    800: '#202020',
    900: '#191919',
  },
  orange: {
    50: '#fefaf6',
    100: '#fbeada',
    200: '#f7d2b0',
    300: '#f0ae70',
    400: '#ea8b31',
    500: '#dd6b00',
    600: '#bb5b00',
    700: '#954800',
    800: '#763900',
    900: '#612f00',
  },
  yellow: {
    50: '#fffefb',
    100: '#fdf9e9',
    200: '#f8edbd',
    300: '#f3df88',
    400: '#ebca38',
    500: '#c9a400',
    600: '#a18300',
    700: '#7d6600',
    800: '#5e4d00',
    900: '#4e3f00',
  },
  green: {
    50: '#f3fef8',
    100: '#bbf8d9',
    200: '#60efa5',
    300: '#00db6a',
    400: '#00c05d',
    500: '#00a550',
    600: '#008842',
    700: '#006a34',
    800: '#00572a',
    900: '#004823',
  },
  teal: {
    50: '#ecfdfd',
    100: '#a7f6f5',
    200: '#3cebe8',
    300: '#00d3d0',
    400: '#00b4b1',
    500: '#009996',
    600: '#007c7a',
    700: '#00615f',
    800: '#00514f',
    900: '#004341',
  },
  cyan: {
    50: '#f1fcfe',
    100: '#c4f1f9',
    200: '#a7eaf6',
    300: '#85e2f3',
    400: '#0bc5e6',
    500: '#00b5d5',
    600: '#00a4c0',
    700: '#00879f',
    800: '#006f83',
    900: '#005665',
  },
  blue: {
    50: '#f0f7fd',
    100: '#c7e1f9',
    200: '#9dcaf5',
    300: '#6eb1f0',
    400: '#3e98eb',
    500: '#117fe7',
    600: '#0069cb',
    700: '#00509c',
    800: '#004280',
    900: '#003669',
  },
  purple: {
    50: '#f8f6fe',
    100: '#e4dafb',
    200: '#d1bef8',
    300: '#b495f4',
    400: '#9f78f1',
    500: '#8250ed',
    600: '#6c31ea',
    700: '#4e07e6',
    800: '#3e00c4',
    900: '#2f0095',
  },
  pink: {
    50: '#fef5fa',
    100: '#fbd7ea',
    200: '#f8b6d8',
    300: '#f387bf',
    400: '#ef61aa',
    500: '#e81b85',
    600: '#cd006a',
    700: '#a90057',
    800: '#850045',
    900: '#640034',
  },
  primary: {
    50: '#fdf6ef',
    100: '#f8dbbe',
    200: '#f2bb84',
    300: '#eb9137',
    400: '#E57200',
    500: '#c46200',
    600: '#a65300',
    700: '#854200',
    800: '#713800',
    900: '#522900',
  },
  secondary: {
    50: '#f7f7f8',
    100: '#dfe0e4',
    200: '#c3c5ce',
    300: '#a1a6b2',
    400: '#8f94a3',
    500: '#767d8f',
    600: '#62697e',
    700: '#4c546c',
    800: '#3e4761',
    900: '#232D4B',
    950: '#00202d',
  },
};
export default colors;
