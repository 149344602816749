/* eslint-disable react/function-component-definition */
import React, { useState, useEffect, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { Navbar, Footer } from './components';
import { useAppActions } from '../store/useAppStore';

const AuthLayout = () => {

  const { signOut } = useAppActions();
  const navigate = useNavigate();
  const activityWatcher = useCallback(() => {
    
    let secondsSinceLastActivity = 0;

    const maxInactivity = 1800; // signout out after 30 minutes of inactivity

    setInterval(() => {
      secondsSinceLastActivity += 1;
      if (secondsSinceLastActivity > maxInactivity) {
        secondsSinceLastActivity = 0;
        signOut();
        navigate('/');

      }
    }, 1000);

    const activity = () => {
      secondsSinceLastActivity = 0;
    }

    const activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

    activityEvents.forEach((eventName) => {
      document.addEventListener(eventName, activity, true);
    });
    return () => {
      activityEvents.forEach((eventName) => {
        document.removeEventListener(eventName, activity, true);
      });
    };
  }, [navigate, signOut]);

  useEffect(() => {
    activityWatcher();
  }, [activityWatcher]);


  
  return (
    <>
      <Navbar />
      <Box minH='100vh' bg={useColorModeValue('white', 'darkBlue')}>
        <Outlet />
      </Box>
      <footer>
        <Footer />
      </footer>
    </>
  );
};
export default AuthLayout;
// https://chakra-templates.dev/navigation/navbar
