/* eslint-disable arrow-body-style */
import { Children, createElement } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

const Form = ({ defaultValues, resolver, children, onSubmit, ...rest }) => {
  const methods = useForm({
    defaultValues,
    resolver: async (data, context, options) => {
      // you can debug form values and validation errors here
      // console.log('form Data ====>', data);
      // console.log('validation result =====>', await resolver(data, context, options));
      return resolver(data, context, options);
    },
  });
  const { handleSubmit, formState, register, watch, control } = methods;
  return (
    <Box as='form' onSubmit={handleSubmit(onSubmit)} {...rest}>
      {Children.map(children, (child) =>
        child?.props?.name
          ? createElement(child.type, {
              ...{
                ...child.props,
                register,
                control,
                errors: formState.errors,
                key: child.props.name,
              },
            })
          : child,
      )}
    </Box>
  );
};
Form.propTypes = {
  defaultValues: PropTypes.object,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
Form.defaultProps = {
  defaultValues: {},
};
export default Form;
