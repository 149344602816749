/* eslint-disable react/function-component-definition */
import { Outlet } from 'react-router-dom';
import { Navbar, Footer } from './components';
import { Box, useColorModeValue } from '@chakra-ui/react';

const VisitorLayout = () => (
  <>
    <header>
      <Navbar />
    </header>

    <Box as='main' h='calc(100vh - 60px)' bg={useColorModeValue('white', 'darkBlue')}>
      <Outlet />
    </Box>
    <footer>
      <Footer />
    </footer>
  </>
);
export default VisitorLayout;
// https://chakra-templates.dev/navigation/navbar
