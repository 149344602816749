import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import Form from '../../../components/forms/Form';
import { FormInput, FormSelect } from '../../../components/forms/FormElements';
import { useAddOrganization, useGetOrganizationsByType, useUpdateOrganization } from './orgHooks';
import { US_STATES } from '../../../utlis/US_STATES';
import { DistrictSchema } from '../../../schemas/orgs.ts';
import { useUser } from '../../../store/useAppStore';
import trackEvent from '../../../utlis/trackEvents';

const defaultValues = {
  name: '',
  city: '',
  state: { label: '', value: '' },
  parent: { name: '', _id: '' },
};
const DistrictForm = ({handleClose, defaultEditValues=null}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const addDistrict = useAddOrganization(handleClose, setErrorMessage);
  const updateInstituteHook = useUpdateOrganization();
  const { data: institutes } = useGetOrganizationsByType({ orgType: 'institute' });
  if(defaultEditValues && defaultEditValues.parent === null) {
    defaultEditValues.parent = {name: '', _id: ''}
  }
  const user = useUser();

  const createDistrict = (data) => {
    const organization = {
      ...data,
      type: 'district',
    };
    trackEvent({
      page: 'AdminOrgs',
      params: '',
      event: 'Create District',
      me: user,
      description: 'Create District',
      details: `Created District: ${data.name}, ${data.state.label}, ${data.city}, parent: ${data.parent}`,
      value: 0,
    })
    addDistrict.mutate(organization);
  };

  const updateDistrict = (data) => {
    const organization = {
      ...defaultEditValues,
      name: data.name,
      area: data.area,
      parent: data.parent,
      state: data.state
    };
    trackEvent({
      page: 'AdminOrgs',
      params: '',
      event: 'Edit District',
      me: user,
      description: 'Edit District',
      details: `Edited District: ${data.name}, ${data.state.label}, ${data.city}, parent: ${data.parent}`,
      value: 0,
    })
    updateInstituteHook.mutate(organization);
    handleClose()
  }

  return (
    <Form
      id='create-district'
      onSubmit={defaultEditValues ? updateDistrict : createDistrict}
      resolver={zodResolver(DistrictSchema)}
      display='flex'
      flexDirection='column'
      gap={4}
      defaultValues={defaultEditValues || defaultValues}
    >
      <FormInput name='name' label='District Name' />
      <Box color='red.500'>{errorMessage}</Box>
      <FormInput name='city' label='City' />
      <FormSelect name='state' label='State' options={US_STATES} />
      <FormSelect
        name='parent'
        label='Teacher Preparation Institution (Parent)'
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option}
        options={institutes}
      />
    </Form>
  );
};
export default DistrictForm;
