// ******************
// utils/utils.js
// ******************

export function getParameterByName(name) {
  const location = window.location || '';
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export function getPath() {
  const location = window.location.pathname || '';
  const path = location.replace('/', '');
  return path;
};

export function getTH(num) {

  if (isNaN(num)) {
      return (num);
  }

  if ( parseInt(num, 10) === 1) { return ('1st'); } 
  if ( parseInt(num, 10) === 2) { return ('2nd'); }
  if ( parseInt(num, 10) === 3 ) { return ('3rd'); }
  return (`${num}th`);
}