import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import api from "../../queries/api";
import { useUser } from "../../store/useAppStore";
import { useContactList, useContactActions } from "../../store/useContactStore";

export const useEditContacts = () => {
    const user = useUser();
    const queryClient = useQueryClient();
    const contactList = useContactList();
    const contactActions = useContactActions();
    const toast = useToast();
    const addContact = useMutation({
        mutationFn: async (contactId) => {
            const newUser = await api.post(`/users/addContact/${user._id}`, { contactId });
            contactActions.addToList(newUser.data.data, contactList);
        },
        onSuccess: () => {
            toast({
                title: 'Success',
                description: 'Contact added successfully.',
                status: 'success',
                duration: 6000,
                isClosable: true,
            });
            queryClient.invalidateQueries('contacts');
        },
        onError: (err) => {
            console.log('EDIT COMMENT ERROR ', err);
            toast({
                title: 'Something went wrong.',
                description: 'There was an error fetching this lesson. Please try again.',
                status: 'error',
                duration: 6000,
                isClosable: true,
            });
        }
    });
    const removeContact = useMutation({
        mutationFn: async (contactId) => {
            await api.post(`/users/removeContact/${user._id}`, { contactId });
            contactActions.removeFromList(contactId, contactList);
        },
        onSuccess: () => {
            toast({
                title: 'Success',
                description: 'Contact removed successfully.',
                status: 'success',
                duration: 6000,
                isClosable: true,
            });
            queryClient.invalidateQueries('contacts');
        },
        onError: (err) => {
            console.log('EDIT COMMENT ERROR ', err);
            toast({
                title: 'Something went wrong.',
                description: 'There was an error fetching this lesson. Please try again.',
                status: 'error',
                duration: 6000,
                isClosable: true,
            });
        }
    });
    return { addContact, removeContact };
        
}

export const useGetContactList = (orgId) => {
    return useQuery({
        queryKey: ['contacts', { orgId }],
        queryFn: async () => {
            const { data } = await api.get(`/users/getContactsByOrg/${orgId}`);
            console.log(data)
            return data?.data || [];
        },
    });
}