import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogOverlay,
  IconButton,
  useDisclosure,
  AlertDialogContent,
  AlertDialogHeader,
  Button,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useDeleteComment } from './commentHooks';
import trackEvent from '../../utlis/trackEvents';
import { useUser } from '../../store/useAppStore';

const DeleteCommentConfirmation = ({ commentId, lessonId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const deleteComment = useDeleteComment(lessonId);
  const user = useUser();
  const handleDelete = () => {
    trackEvent({
      page: 'Lesson',
      params: '',
      event: 'Remove Comment',
      me: user,
      description: `Lesson ${lessonId} Comment`,
      details: `commentId ${commentId}`,
      value: 0,
    });
    deleteComment.mutate(commentId);
    onClose();
  };
  return (
    <>
      <IconButton variant='ghost' colorScheme='red' onClick={onOpen} icon={<DeleteIcon />} />
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Comment
            </AlertDialogHeader>
            <AlertDialogBody>Are you sure? This can&apos;t be undone.</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
DeleteCommentConfirmation.propTypes = {
  commentId: PropTypes.string.isRequired,
  lessonId: PropTypes.string.isRequired,
};
export default DeleteCommentConfirmation;
