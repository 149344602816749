import { Box, Center, Flex, Heading, Text } from '@chakra-ui/react';
import { LinkComp } from '../../components';

const Restricted = () => (
  <Center h='100vh'>
    <Box w='400px' p='4'>
      <Heading textAlign='center' mb='4'>
        Oops!
      </Heading>
      <Text textAlign='center' mb='8'>
       Page Not Found.
      </Text>
      <Flex flexDirection='row' justifyContent='center'>
        <LinkComp to='/' label='Return to Home' fontSize='xl' color='primary.500' />
      </Flex>
    </Box>
  </Center>
);

export default Restricted;
