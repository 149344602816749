import { AddIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import { Box, Collapse, Textarea, HStack, Button } from '@chakra-ui/react';

const ReplyInput = ({ handleSubmit, handleChange, value, showReply }) => (
  <Collapse in={showReply} animateOpacity style={{ width: '100%' }}>
    <Box w='full' minH={125}>
      <Textarea value={value} name='commentBody' onChange={handleChange} w='full' />
      <HStack w='full' justifyContent='flex-end'>
        <Button
          onClick={() => handleSubmit(true)}
          variant='secondary'
          size='xs'
          mt={1}
          leftIcon={<AddIcon />}
        >
          Add Reply
        </Button>
      </HStack>
    </Box>
  </Collapse>
);
ReplyInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  // handleChange: PropTypes.func,
  // value: PropTypes.string,
  showReply: PropTypes.bool.isRequired,
};
export default ReplyInput;
