import { z } from 'zod';

const selectSchema = z.object({
  label: z.string(),
  value: z.string(),
});
export const InstituteSchema = z.object({
  name: z.string().min(4, ' Name must be at least 4 characters'),
  area: z.string().min(4, 'area must be at least 4 characters'),
});
export const DistrictSchema = z.object({
  name: z.string().min(4, ' Name must be at least 4 characters'),
  city: z.string().min(4, ' Please enter a city'),
  state: z
    .object({
      value: z.string().min(1, 'Please select a state'),
    })
    .transform((data) => data.value),
  parent: z
    .object({
      _id: z.string().optional(),
    })
    .optional()
    .transform((data) => data?._id || null).optional(),
});
export const SchoolSchema = z.object({
  schoolId: z
    .string()
    .max(10, 'School id must be less than 10 characters')
    .optional(),
  name: z.string().min(4, ' Please enter a name'),
  city: z.string().min(4, ' Please enter a city'),
  state: z
    .object({
      value: z.string().min(1, 'Please select a state'),
    })
    .transform((data) => data.value),
  parent: z
    .object({
      _id: z.string().min(2, 'Please select a parent institute'),
    })
    .transform((data) => data._id),
});
export const ClassSchema = z.object({
  name: z.string().min(4, ' Please enter a class name'),
  userId: z
    .object({
      _id: z.string().min(1, 'Please select a teacher'),
    })
    .transform((data) => data._id),
  description: z.string().max(450, 'Description must be less than 450 characters'),
  grade: z
    .object({
      value: z.string().min(1, 'Please select a grade'),
    })
    .transform((data) => data.value),
  period: z.string().optional(),
  // subject: z
  //   .object({
  //     value: z.string().min(1, 'Please select a subject'),
  //   })
  //   .transform((data) => data.value),
  subject: z.string().min(3, ' Please enter a subject'),
  subjectId: z.string().max(10, 'Subject ID must be less than 10 characters').optional(),
  classId: z.string().max(10, 'Class ID must be less than 10 characters').optional(),
  districtId: z.string().max(10, 'District ID must be less than 10 characters').optional(),
  parent: z
    .object({
      _id: z.string().min(1, 'Please select a school'),
    })
    .transform((data) => data._id),
  term: z.string().optional(),
  endDate: z.string().optional(),
  schoolYear: z
    .object({
      value: z.string().min(1, 'Please select a school year'),
    })
    .transform((data) => data.value),
  supportTeam: z
    .array(
      z.object({
        value: z.string()}))
        .transform((data) => data?.map((item) => item.value))
        .optional(),
});

export const editClassSchema = z.object({
  name: z.string().min(4, 'Please enter a class name'),
  description: z.string().min(1, 'Please enter a description'),
  supportTeam: z
  .array(
    z.object({
      value: z.string(),
      label: z.string()}))
      .optional()
      .transform((data) => data?.map((item) => {
        console.log(item)
        return item.value
      })),
})
