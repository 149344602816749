/* eslint-disable prefer-destructuring */
// Stripe URLs down to just hostname
function extractHostname(url, tld) {
  let hostname;

  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('://') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // find & remove port number
  hostname = hostname.split(':')[0];

  // find & remove "?"
  hostname = hostname.split('?')[0];

  if (tld) {
    const hostnames = hostname.split('.');
    hostname = `${hostnames[hostnames.length - 2]}.${hostnames[hostnames.length - 1]}`;
  }

  return hostname;
}

function getStageByDomain() {
  const url = window.location.hostname;

  // Determine deployment stage by browser's location
  if ((url.indexOf('localhost') > -1) || (url.indexOf('127.0') > -1)) return 'local'; // 'localhost:8050/{API_AWS_GW_STAGE}/api/v1';
  if (url.indexOf('dev.') > -1 || url.indexOf('lab.') > -1) return 'dev';
  if (url.indexOf('stage.') > -1) return 'stage'; // staging.someaddress.com
  return 'prod'; // someaddress.com
}

function getAPIByDomain() {
  const url = window.location.hostname;

  // Determine deployment stage by browser's location
  if (url.indexOf('localhost') > -1 || url.indexOf('127.0.0.1') > -1) return 'local';
  return 'remote'; // Terraform determines API URL via .env file
}

export { extractHostname, getStageByDomain, getAPIByDomain };
