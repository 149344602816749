/* eslint-disable no-nested-ternary */
import { useState, useLayoutEffect } from 'react';
import { Text } from '@chakra-ui/react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";

// eslint-disable-next-line camelcase
// eslint-disable-next-line camelcase
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import { ACTIVITY_TYPES } from '../../constants';

const SmallGraph = ({ lesson, activities }) => {
  const chartDivName = `small-chart-${lesson._id}`;
  const [displayChart, setDisplayChart] = useState(true);
  
  useLayoutEffect(() => {
    //check if chartDivName exists
    if(!lesson.lessonActivities) {
      setDisplayChart(false);    
      return;
    }
    if (!document.getElementById(chartDivName)) {
      return;
    }

    am5.addLicense("AM5C450941845");
    
    // Create root element
    const root = am5.Root.new(chartDivName);

    // set the theme
    // eslint-disable-next-line camelcase
    root.setThemes([am5themes_Animated.new(root)]);

    // const chart = root.container.children.push(
    //   am5percent.PieChart.new(root, {})
    // );
    const chart = root.container.children.push( 
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        radius: am5.percent(70)
      }) 
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        categoryField: "category",
        valueField: "value",
        alignLabels: false
      })
    );

    series.get("colors").set("colors", [
      am5.color(0xec5140),
      am5.color(0xf9dcbe),
      am5.color(0x010766),
      am5.color(0x415287),
      am5.color(0xbb9f06)
    ]);


    const chartData = [];
    Object.keys(lesson?.lessonActivities).map(act => {
      if (activities.includes(act)) {
        const moments = lesson.lessonActivities[act].numMoments;
        chartData.push({ category: ACTIVITY_TYPES[act], value: moments})
      }
    });

    series.data.setAll(chartData);

    series.labels.template.set("text", "{category}");
    series.labels.template.setAll({
      text: "{category}",
      fontSize: 8,
      maxWidth: 60,
      oversizedBehavior: "wrap", // to truncate labels, use "truncate"
    });



    // Make an object with all the colors so we can map them to the activity types, and they don't change with filters
    // const colors = chart.get('colors');
    // Object.values(ACTIVITY_TYPES).forEach((el, index) => {
    //   colorMap[el] = colors.getIndex(index);
    // });

    // Add legend
    // let legend = chart.children.push(am5.Legend.new(root, {
    //   centerX: am5.percent(50),
    //   x: am5.percent(50),
    //   layout: root.horizontalLayout
    // }));

     // legend.data.setAll(series.dataItems);
    return () => {
      root.dispose();
    };
  }, [lesson, activities, chartDivName]);

  return (
    <div>
      {displayChart ? <div id={chartDivName} style={{width: 300, height: 250 }}></div> : <Text width='200px'>No Data Available</Text>}
    </div>
  );
};

export default SmallGraph;

