import {
  Box
} from '@chakra-ui/react';

const VisitorPage = (props) => {
  const { title, breadCrumbs, children } = props;
  return (
    <Box
    w='100%'
    bgImage='https://aiai-static-assets.s3.amazonaws.com/signin.png'
    bgSize='cover'
    h='full'
    
    bgPos='center center'
  >
    {children}
    </Box>
  );
};

export default VisitorPage;
