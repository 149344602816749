import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Text,
  FormControl,
  FormLabel,
  ModalCloseButton,
} from '@chakra-ui/react';
import api from '../../queries/api';
import { set } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export default function ReprocessConfirmation({
  reprocessConfirmation,
  setReprocessConfirmation,
  handleReprocessLesson,
  setModelVersion,
}) {
  const [imageOptions, setImageOptions] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const result = await api.get('/lessons/get-nn-image-option');
      const options = new Set(result.data.tags.map((option) => option.name));
      setImageOptions(Array.from(options));
    };
    fetchData();
  }, []);
  
  const closeModal = () => {
    setReprocessConfirmation(false); 
    navigate('/')
    }

  return (
    <Modal isOpen={reprocessConfirmation} onClose={() => closeModal()}>
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />{' '}
        <ModalBody>
          <FormControl mt={6}>
            <FormLabel>Choose model version to reprocess against:</FormLabel>
            <Select
              placeholder='Select model version'
              onChange={(e) => setModelVersion(e.target.value)}
            >
              {imageOptions.map((option) => (
                <option value={option}>{option}</option>
              ))}
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              handleReprocessLesson();
              setReprocessConfirmation(false);
            }}
            mr={3}
          >
            Reprocess
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
