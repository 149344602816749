import { useState, useEffect } from "react";
import moment from "moment";
import {
  Stack,
  HStack,
  Button,
  Select,
  ScaleFade,
  Input,
  VStack,
  useColorMode
} from "@chakra-ui/react";
import { AddIcon } from '@chakra-ui/icons';

import { useListTeacherLessons } from "../Lesson/components/lessonHooks";
import { useGetOrganizationsByType } from "../AdminOrganizations/components/orgHooks";
import { DataTable } from "../../components/Tables";
import { 
  createColumnHelper 
} from "@tanstack/react-table";
import { useUser } from "../../store/useAppStore";
import { useNavigate } from "react-router";
import PageTitle from "../../components/PageTitle/PageTitle";
import MyClassesLessons from "./MyClassesLessons";


const headCells = [
  // note: ID is the name used for sorting
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Class Name',
  },
  // {
  //   id: 'subject',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Subject',
  // },
  // {
  //   id: 'grade',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Grade',
  // },
  // {
  //   id: 'period',
  //   numeric: false,
  //   disablePadding: false,
  //   searchable: false,
  //   label: 'Period',
  // },

];

const MyClasses = (props) => {
  const { asPanel } = props;
  const [filterBy, setFilterBy] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [tableRows, setTableRows] = useState([]);  
  const {colorMode} = useColorMode();

  const user = useUser();
  const navigate = useNavigate();
  const addClassUrl = `/organizations/class${asPanel ? '?from=dashboard' : ''}`

  const { data, error, refetch } = useGetOrganizationsByType({orgType: 'class'});

  useEffect(() => {
    if (data) {
      setTableRows(data);
    }
  },[data])

  const handleChangeFilterBy = (value) => {
    setFilterBy(value);
  };
  const handleChangeFilterQuery = (value) => {
    setFilterQuery(value);
  };
  const lookForEnter = (e) => {
    if (e.key === 'Enter') {
      const tableData = [...data]
      setTableRows(tableData.filter((row) => {
        switch (filterBy) {
          case 'name':
            return row.nameLower.includes(filterQuery.toLowerCase());
          case 'sharedWithNames':
            return row.sharedWithNames?.filter((shared) =>  shared?.includes(filterQuery) || false )?.length > 0;
          case 'lessonDate':
            return moment(row.created).format('MM/DD/YYYY').includes(filterQuery);
          default:
            return row[filterBy]?.includes(filterQuery.toLowerCase());
        }
      }
      ))
    }
  };
  const getFilterDisplayName = (filterBy) => {
    const filter = headCells.find((h) => h.id === filterBy);
    return filter ? filter.label : '';
  };

  const columnHelper = createColumnHelper();
  const columns = headCells.map((el) => {
    if (el.link) {
      return columnHelper.accessor(el.id, {
        cell: (info) => (
          <>
          <VStack alignItems='flex-start'>
            <HStack alignItems='flex-start' gap={8}>
                <a onClick={() => redirectToLesson(info.getValue())} className="text-blue-600 underline hover:underline hover:text-blue-900">
                  Add/Edit Reflections
                </a>
                <a onClick={() => redirectToLesson(info.getValue())} className="text-blue-600 underline hover:underline hover:text-blue-900">
                  View Analysis
                </a>
              </HStack>
            </VStack>
            <VStack alignItems='flex-start' spacing={'0px'}>
              <HStack gap={14}>
                <a onClick={() => redirectToLesson(info.getValue())} className="text-blue-600 underline hover:underline hover:text-blue-900">
                  Share Lesson
                </a>
                <a onClick={() => navigate(`/lesson/create?id=${info.getValue()}`)} className="text-blue-600 underline hover:underline hover:text-blue-900">
                  Re-upload Video
                </a>
              </HStack>
          </VStack>
          </>
        ),
        header: el.label,
        disableNavigation: el.disableNavigation,
        enableSorting: el.enableSorting,
      });
    }
    if (el.id === 'created') {
      return columnHelper.accessor(el.id, {
        cell: (info) => (!info.getValue() ? '' : moment(info.getValue()).format('MM/DD/YYYY')),
        header: el.label,
      });
    }
    if (el.id === 'teacherData') {
      return columnHelper.accessor(el.id, {
        cell: (info) => {
          const teacher = info.getValue();
          return teacher?.email || '';
        },
        header: el.label,
      });
    }
    // if (el.id === 'lessonDetails') {
    //   return columnHelper.accessor(row => el.id, {
    //     cell: (info) => {
    //       const lesson =info.getValue();
    //       const name = lesson?.name || '';
    //       // console.log(lesson)
    //       return `test ${name}`;
    //     },
    //     header: el.label,
    //   });
    // }

    /*
          cell: ({ row, getValue }) => (
          <div
          >
            <div>
              {row.getCanExpand() ? (
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' },
                  }}
                >
                  {row.getIsExpanded() ? '👇' : '👉'}
                </button>
              ) : (
                '🔵'
              )}{' '}
              {getValue<boolean>()}
            </div>
          </div>
        ),
        footer: props => props.column.id,
      },
    */
    if (el.id === 'subject') {
      return columnHelper.accessor(el.id, {
        cell: (info) => {
          const subject = info.getValue();
          return subject || '';
        },
        header: el.label,
        sortingFn: (rowA, rowB) => {
          console.log(rowA)
          return rowA.original.name.toLowerCase() > rowB.original.name.toLowerCase ? 1 : -1;
      }
    });
    }
    if (el.id === 'name') {
      return columnHelper.accessor(row => el.id, {
        cell: (info) => {
          const { row } = info;
          const { original } = row;
          const name = `${original.name} | Grade ${original.grade} ${original.period ? 'Period' : ''} ${original.period} ${original.subject}`;
          return (
            <>
            {row.getCanExpand() && (
              <button
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: 'pointer' },
                }}
              >
                {row.getIsExpanded() 
                ? 
                <div style={{rotate: '180deg', display: 'inline-block', marginRight: 6, fontSize: 16, fontWeight: 'bold', verticalAlign: 'bottom'}}>^</div> 
                :
                <div style={{rotate: '90deg', display: 'inline-block', marginRight: 6, fontSize: 16, fontWeight: 'bold', verticalAlign: 'bottom'}}>^</div> 
                }
                 {name}
              </button>
            )}
            </>
          );
        },
        header: el.label,
    });
    }
    return columnHelper.accessor(el.id, {
      cell: (info) => info.getValue() || null,
      header: el.label,
    });
  });
  return (
    <>
    <PageTitle title={asPanel ? `Classes I'm Supporting` : 'My Classes'}>
    <Button variant='secondary' size='xs' leftIcon={<AddIcon />} onClick={() => navigate(addClassUrl)}>
          {' '}
          Class
        </Button>
    </PageTitle>
    <div className='mt-0 pl-6 pt-2'>
        <Stack
          direction='row'
          // className='-mt-8 mb-4 whitespace-nowrap'
          // justifyContent='space-between'
          pl={6}
          // pb={0}
        >
          {!asPanel && (
          <HStack direction='row' flexWrap='wrap' gap='2' w={'full'}>
            <div className='w-44 pl-0'>
              <Select
                id='filter-by'
                fontSize='sm'
                value={filterBy}
                onChange={e => handleChangeFilterBy(e.target.value)}
                size='sm'
                // style={{border: 'none', background: '#222327', borderRadius: 5}}
                className='darkSelect'
              >
                <option value='' key='nothing-selected'>Search by...</option>
                {headCells
                  .filter(h => h.searchable !== false)
                  .map(h => (
                    <option value={h.id} key={h.id}>
                      {h.label}
                    </option>
                  ))}
              </Select>
            </div>

            <ScaleFade initialScale={0.9} in={filterBy}>
              <Input
                //  variant="flushed"
                placeholder={`${getFilterDisplayName(filterBy)}`}
                variant='filled'
                style={{background: colorMode === 'dark' ? '#222327' : '', borderRadius: 5, border: '2px'}}
                fontSize='sm'
                minWidth='240px'
                size='sm'
                id='filter-query'
                value={filterQuery}
                onChange={e => handleChangeFilterQuery(e.target.value)}
                onKeyDown={lookForEnter}
                onBlur={() => refetch()}
              />
            </ScaleFade>
          </HStack>
          )}
        </Stack>
      <DataTable columns={columns} data={tableRows} noRedirect defaultSort='name' SubTable={MyClassesLessons} />
    </div>
    </>
  );
};

export default MyClasses;
