import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// state is persisted in local storage for everything but actions.
let store = (set) => ({
  isAuthenticated: false,
  error: '',
  user: null,
  token: '',
  actions: {
    authenticateUser: (user) => set(() => ({ isAuthenticated: true, user, token: user.token })),
    setUserData: (user) => set(() => ({ user })),
    resetPW: () => set(() => ({ })),
    signOut: () => set(() => ({ isAuthenticated: false, user: null, token: '' })),
  },
});
store = persist(store, {
  name: 'app-data',
  partialize: ({ actions, ...rest }) => rest,
});
const useAppStore = create(store);

let redirectStore = (set) => ({
  redirectLocation: null,
  redirectActions: {
    setRedirect: (location) => set(() => ({ redirectLocation:location })),
    clearRedirect: () => set(() => ({ redirectLocation: null })),
  },
});
redirectStore = persist(redirectStore, {
  name: 'redirect-data',
  partialize: (state) => ({ redirectLocation: state.redirectLocation }),
});
export const useRedirectStore = create(redirectStore);

export const useUser = () => useAppStore((state) => state.user);
export const useToken = () => useAppStore((state) => state.token);
export const useIsAuthenticated = () => useAppStore((state) => state.isAuthenticated);
// Actions are all exported together, because they won't trigger re-renders when called.
export const useAppActions = () => useAppStore((state) => state.actions);
export default useAppStore;
