import { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  Textarea,
  InputRightElement,
  Button,
  InputGroup,
  IconButton,
  RadioGroup, Radio,
  VStack, HStack, Text,
  Tabs, TabList, Tab, TabPanels, TabPanel,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { AsyncSelect, Select } from 'chakra-react-select';
import { useController } from 'react-hook-form';


export const FormInput = ({ register, name, label, errors, helperText, width, type, ...rest }) => {
  const [showEdit, setShowEdit] = useState(false);
  
  const handleClick = () => setShowEdit(!showEdit)
  
  return (<FormControl isInvalid={errors[name]} id={name} width={width}>

  <InputGroup style={{"display": "block"}}>
    {label && <FormLabel>{label}</FormLabel>}
    <div>

    <Input 
      {...rest} 
      {...register(name)} 
      type={!showEdit && type =='password' ? 'password' : type=='password' ? 'text' : type}
    variant='outline' />
     {type=='password'? 
      <InputRightElement width='4.5rem'>
        <IconButton h='1as.75rem' size='sm' style={{top: '75%'}} onClick={handleClick} icon={showEdit ? <ViewIcon/> : <ViewOffIcon/>}/>
      </InputRightElement>
      :''}
    </div>
    {!errors[name] && helperText && <FormHelperText minHeight='20px'>{helperText}</FormHelperText>}
    <FormErrorMessage>
        {errors?.message || errors?.value?.message || errors?._id?.message || errors[name]?.message}
      </FormErrorMessage>
   </InputGroup>

  </FormControl>)
};
export const FormTextarea = ({ register, name, label, errors, helperText, width, ...rest }) => (
  <FormControl isInvalid={errors[name]} id={name} width={width}>
    {label && <FormLabel>{label}</FormLabel>}
    <Textarea {...rest} {...register(name)} variant='outline' />
    {!errors[name] && helperText && <FormHelperText>{helperText}</FormHelperText>}
    <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
  </FormControl>
);

export const FormRadios = ({ options, selectedSubject, register, name, label, errors, helperText, width, control, rules, shouldUnregister, ...rest }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
  });
 
  const tabNames = options.levels.map(ol => ol.name);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (selectedSubject) {
      let ti = 1;
      options.levels[0].topics.forEach(topic => {
        topic.subjects.forEach(s => {
          if (s.Subject === selectedSubject) {
            ti = 0;
          }
        })
      });
      setTabIndex(ti);
    }
  }, [])


  const handleTabsChange = (index) => {
    setTabIndex(index)
  }

  return(
  <FormControl isInvalid={errors[name]} id={name} width={width}>
    {/* {label && <FormLabel>{label}</FormLabel>} */}
      <RadioGroup {...field} {...rest}>
        <Tabs index={tabIndex} onChange={handleTabsChange} variant="enclosed">
          <TabList>
            {tabNames.map(tab => (
              <Tab key={`level-select-${tab}`}>{tab}</Tab>
            ))}
          </TabList>

          <TabPanels>
            {/* <TabPanel>
              <p>one!</p>
            </TabPanel>
            <TabPanel>
              <p>two!</p>
            </TabPanel> */}
             {options.levels.map(level => (
            <span key={`school-level-${level.name}`}> 
              <HStack gap={8} alignItems ='normal'>
              {level.topics.map((topic, index) => (
                <TabPanel key={`topic-select-${topic.name}`}>
                <VStack  align='stretch' w={172}>
                  <Text fontWeight='bold'>{topic.name !== 'na' ? topic.name : ''}</Text>
                  {topic.subjects.map(subject => (
                    <span key={`subject-select-${subject.Subject}`}>
                    {subject.Subject && (
                    <Radio value={subject.Subject}><span style={{fontSize: 11}}>{subject.Subject}</span></Radio>
                    )}
                    </span>
                  ))
                  }
                </VStack>
                </TabPanel>
              ))}
              </HStack>
              </span>
          ))}
          </TabPanels>
        </Tabs>


      </RadioGroup>
      {/* <FormErrorMessage>
        {error?.message || error?.value?.message || error?._id?.message}
      </FormErrorMessage>
      {!error && helperText && <FormHelperText>{helperText}</FormHelperText>} */}
  </FormControl>
  )
};
/**
 * be sure to pass the Parent form an empty array as the defaultValues prop
 */
export const FormSelect = ({
  name,
  label,
  control,
  options,
  rules,
  shouldUnregister,
  filter = null,
  width,
  helperText,
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
  });
  const filterOptions = (options, filter) => {
    if (!filter || !Array.isArray(options)) return options;
    return filter(options, field.value);
  };
  return (
    <FormControl isInvalid={!!error} id={name} width={width}>
      {label && <FormLabel>{label}</FormLabel>}
      <Select {...field} {...rest} overflowY='scroll' options={filterOptions(options, filter)} />
      <FormErrorMessage>
        {error?.message || error?.value?.message || error?._id?.message}
      </FormErrorMessage>
      {!error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
export const FormAsyncSelect = ({
  name,
  label,
  control,
  options,
  rules,
  shouldUnregister,
  getOptions,
  width,
  helperText,
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
  });
  return (
    <FormControl isInvalid={!!error} id={name} width={width}>
      {label && <FormLabel>{label}</FormLabel>}
      <AsyncSelect
        {...field}
        {...rest}
        overflowY='scroll'
        cacheOptions
        defaultOptions
        loadOptions={getOptions}
        placeHolder='select...'
        isClearable
        hideSelectedOptions={false}
        loadingMessage={() => 'Looking for options'}
        noOptionsMessage={() => 'No options found. Type to search or try something else'}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option}
      />
      {!error && helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{error?.message || error?.value?.message}</FormErrorMessage>
    </FormControl>
  );
};
