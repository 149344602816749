import { ROLES } from '../../constants';
// export const ROLES = [
// { label: 'Teacher Candidate', value: 'teacher' }, // 0
// { label: 'Mentor', value: 'mentor' }, // 1
// { label: 'Coach', value: 'coach' }, // 2
// { label: 'Course Instructor', value: 'instructor' }, // 3
// { label: 'Practicing Teacher', value: 'practicing-teacher' }, // 4
// { label: 'School Mentor', value: 'school-mentor' }, // 5
// { label: 'School Coach', value: 'school-coach' }, // 6
// { label: 'School Administrator', value: 'administrator' }, // 7
// { label: 'Admin', value: 'admin' }, // 8
// { label: 'Researcher', value: 'researcher' }, // 9
// { label: 'UVA Stakeholder', value: 'stakeholder' }, // 10
// ];

// eslint-disable-next-line import/prefer-default-export
export const roleSelectHelper = (role) => {
  if (role.length === 0) return ROLES;
  if (role[0].value === 'teacher') return [ROLES[0]];
  if (role[0].value === 'mentor') return [ROLES[1], ROLES[3]];
  if (role[0].value === 'coach') return [ROLES[2], ROLES[3]];
  if (role[0].value === 'instructor') return [ROLES[3], ROLES[1], ROLES[2]];
  if (role[0].value === 'practicing-teacher') return [ROLES[4], ROLES[5], ROLES[6]];
  if (role[0].value === 'school-mentor') return [ROLES[5], ROLES[4], ROLES[6], ROLES[7]];
  if (role[0].value === 'school-coach') return [ROLES[6], ROLES[4], ROLES[5], ROLES[7]];
  if (role[0].value === 'administrator') return [ROLES[7], ROLES[4], ROLES[5], ROLES[6]];
  if (role[0].value === 'admin') return [ROLES[8]];
  if (role[0].value === 'researcher') return [ROLES[9]];
  if (role[0].value === 'stakeholder') return [ROLES[10], ROLES[3]];
  return ROLES;
};
