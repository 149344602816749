import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdateUser } from '../userHooks';
import { FormInput, FormSelect } from '../../../components/forms/FormElements';
import { EditUserSchema } from '../../../schemas/users.ts';
import { ROLES } from '../../../constants';
import { roleSelectHelper } from '../helpers';
import { useGetOrganizationsByType } from '../../AdminOrganizations/components/orgHooks';
import Form from '../../../components/forms/Form';

import trackEvent from '../../../utlis/trackEvents';

const CreateUserDrawer = ({ isOpen, users, onClose, editUser, openConfirmation }) => {
  const [defaultValues, setDefaultValues] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [needsSchool, setNeedsSchool] = useState(true);
  const { data: organizations } = useGetOrganizationsByType({
    orgType: ['district', 'institute', 'school'],
  });

  useEffect(() => {
    let newOrgs = []
    let newRoles = []
    let newInstitute = null;
    let newDistrict = null;
    if(organizations && editUser) {
      newOrgs = editUser.organizations.filter((el) =>
        organizations?.some((org) => (org._id === el._id && org.type === 'school')),
      );
      newInstitute = editUser.organizations.find((el) => el.type === 'institute');
      newDistrict = editUser.organizations.find((el) => el.type === 'district');
    }
    if (editUser && editUser?.role) {
      newRoles = editUser?.role.map((el) => ROLES.find((role) => role?.value === el));
    }
    setDefaultValues({
      _id: editUser?._id,
      firstName: editUser?.firstName,
      lastName: editUser?.lastName,
      email: editUser?.email,
      role: newRoles,
      organizations: newOrgs,
      institute: newInstitute,
      district: newDistrict,
    });
  },[organizations, editUser])

  const schoolFilter = (options, field) => {
    const userOrgs = editUser.organizations.filter((el) => el.type === 'district' || el.type === 'institute');
    const parentOrgIDs = userOrgs.map((el) => el._id);
    const schools = options.filter(
      (el) =>
        el.type === 'school' && (parentOrgIDs.includes(el?.parent) || parentOrgIDs.includes(el?.parent)),
    );
    //make sure none of the field values are in the schools array since they are already selected
    if (field) {
      const selectedSchools = field.map((el) => el._id);
      return schools.filter((el) => !selectedSchools.includes(el._id));
    }
    schools.unshift({ _id: '', name: '', type: 'school' });
    return schools;
  };
  const roleFilter = (options, value) => {
    // console.log(options, value)
    if (!value || value.length === 0 ) return ROLES;
    return roleSelectHelper(value);
  }

  const handleClose = () => {
    onClose();
  };
  const handleDelete = () => {
    openConfirmation()
  };
  const updateUser = useUpdateUser(onClose, setErrorMessage);
  const handleEditUser = (data) => {
    const updateData = {...data};
    trackEvent({
      page: 'AdminUsers',
      params: '',
      event: 'Edit User',
      me: users,
      description: 'Edit User',
      details: `edited userId: ${updateData._id}`,
      value: 0,
    });

    if (updateData.institute) updateData.organizations.push(updateData.institute);
    if (updateData.district) updateData.organizations.push(updateData.district);
    delete updateData.institute;
    delete updateData.district;
    updateUser.mutate(data);
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      size='md'
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit User</DrawerHeader>
        <DrawerBody>
          {defaultValues && (
          <VStack px='4' gap='3'>
            <Form
              id='update-user'
              onSubmit={handleEditUser}
              resolver={zodResolver(EditUserSchema)}
              display='flex'
              flexDirection='column'
              gap={4}
              defaultValues={defaultValues}
              w='100%'
            >
              <FormInput
                name='firstName'
                label='First Name'
              />
              <FormInput
                name='lastName'
                label='Last Name'
              />
              <FormInput
                name='email'
                label='Email'
                type='email'
              />
              <Box color='red.500'>{errorMessage}</Box>
              <FormSelect
                name='role'
                label='Roles'
                options={ROLES}
                filter={roleFilter}
                isMulti
              />
              <FormSelect
                name='institute'
                label='Teacher Preparation Institution'
                isDisabled
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option}
                key='institute'
              />
              <FormSelect
                name='district'
                label='District'
                isDisabled
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option}
                key='district'
                />
              <FormSelect
                name='organizations'
                label='School'
                isClearable
                options={organizations}
                filter={schoolFilter}
                key='school'
                isMulti
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option}
              />
            </Form>
          </VStack>)}
        </DrawerBody>
        <DrawerFooter>
          <HStack>
            <Button variant='outline' mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='uva' type='submit' form='update-user'>
              Update User
            </Button>
            <Button colorScheme='red' variant='solid' ml={3} onClick={handleDelete}>
                Delete User
              </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateUserDrawer;
