import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogBody,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Collapse,
  Flex,
  HStack,
  Heading,
  IconButton,
  Text,
  Textarea,
  VStack,
  useColorModeValue,
  useDisclosure,
  Spinner,
  Center,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { AddIcon, ChatIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import DeleteCommentConfirmation from './DeleteCommentConfirmation';
import ReplyInput from './ReplyInput';
import { useEditComment } from './commentHooks';
import trackEvent from '../../utlis/trackEvents';
import { useUser } from '../../store/useAppStore';

dayjs.extend(relativeTime);
// IF CHILDREN IS AN ARRAY, ITS A PARENT
// IF CHILDREN IS NULL, ITS A CHILD

const Comment = ({
  _id,
  content,
  created,
  owner,
  isChild,
  handleReplySubmit,
  handleReplyChange,
  replyValue,
  parentId,
  userId,
  lessonId,
  lessonName,
  tableDisplay=false
}) => {
  const [showReply, setShowReply] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editedComment, setEditedComment] = useState(content);
  const isMine = owner._id === userId;
  const { mutate: editCommentMutate, isLoading } = useEditComment(lessonId);
  const navigate = useNavigate();
  const showAvatar = false;
  const user = useUser();

  const toggleReply = () => {
    if (tableDisplay) {
      navigate(`/lesson/${lessonId}`);
      return
    }
    setShowReply((prev) => !prev);
  };
  const cancelEdit = () => {
    setShowEdit(false);
    setEditedComment(content);
  };
  const handleSaveEdit = () => {
    trackEvent({
      page: 'Lesson',
      params: '',
      event: 'Edit Comment',
      me: user,
      description: `Lesson ${lessonId} Comment`,
      details: `commentId: ${_id}`,
      value: 0,
    });
    editCommentMutate({
      commentId: _id,
      content: editedComment,
    });
    setShowEdit(false);
  };
  const renderActions = () => {
    if (tableDisplay) return (
      <Button
              variant='ghost'
              colorScheme='primary'
              leftIcon={<ChatIcon />}
              onClick={toggleReply}
            >
              Reply
            </Button>
    )
    if (isMine) {
      return (
        <ButtonGroup size='sm' isAttached>
          <DeleteCommentConfirmation commentId={_id} lessonId={lessonId} />
          {isMine && (
            <IconButton
              variant='ghost'
              colorScheme='secondary'
              icon={<EditIcon />}
              onClick={() => setShowEdit(true)}
              disabled={showEdit}
            />
          )}
          {/* Edit
          </Button> */}
          {!isChild && (
            <Button
              variant='ghost'
              colorScheme='primary'
              leftIcon={<ChatIcon />}
              onClick={toggleReply}
            >
              Reply
            </Button>
          )}
        </ButtonGroup>
      );
    }
    if (!isMine && !isChild) {
      return (
        <Button
          variant='ghost'
          size='sm'
          colorScheme='primary'
          leftIcon={<ChatIcon />}
          onClick={toggleReply}
        >
          Reply
        </Button>
      );
    }
    return null;
  };

  return (
    <VStack w='full'>
      <Card
        width={isChild ? 'calc(100% - 2rem)' : 'full'}
        p='0'
        alignSelf={isChild ? 'flex-end' : 'flex-start'}
        bgColor={useColorModeValue(
          isChild ? 'secondary.100' : 'secondary.200',
          isChild ? 'secondary.800' : 'secondary.950',
        )}
      >
        <CardHeader pb='1' pt='2'>
          <Flex spacing='4'>
            <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
              {showAvatar && (
              <Avatar name={`${owner.firstName} ${owner.lastName}`} src='' bg='uvaTeal' size='sm' />
              )}
              <Box>
                <VStack alignItems='flex-start' gap={1}>
                  <Heading size='xs'>{`${owner.firstName} ${owner.lastName}`}</Heading>
                </VStack>
              </Box>
            </Flex>
            {renderActions()}
          </Flex>
          {lessonName && <Text fontSize='xs'>Lesson: {lessonName}</Text>}
          <Text fontSize='xs'>{dayjs(created).fromNow()}</Text>
        </CardHeader>
        <CardBody py='1'>
          {/* EDIT COMMENT TEXT AREA */}
          {isLoading ? (
            <Center>
              <Spinner size='sm' />
            </Center>
          ) : (
            <>
              <Collapse in={showEdit} animateOpacity>
                <Textarea
                  fontSize='xs'
                  rows={3}
                  value={editedComment}
                  onChange={(e) => setEditedComment(e.target.value)}
                />
              </Collapse>
              <Collapse in={!showEdit} animateOpacity>
                <Text fontSize='sm'>{content}</Text>
              </Collapse>
            </>
          )}
        </CardBody>
        <CardFooter justifyContent='flex-end' pt='0' pb='1'>
          <Collapse in={showEdit} animateOpacity>
            <ButtonGroup size='xs' w='full' justifyContent='flex-end'>
              <Button onClick={cancelEdit}>Cancel</Button>
              <Button variant='secondary' onClick={handleSaveEdit}>
                Save Changes
              </Button>
            </ButtonGroup>
          </Collapse>
        </CardFooter>
      </Card>
      <ReplyInput
        handleSubmit={() =>{setShowReply(false); handleReplySubmit(parentId);}}
        handleChange={handleReplyChange}
        value={replyValue}
        showReply={showReply}
      />
    </VStack>
  );
};
Comment.propTypes = {
  content: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  isChild: PropTypes.bool,
};
Comment.defaultProps = {
  isChild: false,
};
export default Comment;
