import { useState, useEffect, Fragment } from 'react';
import { Text, HStack, VStack, Grid, GridItem, Box, Button,
        UnorderedList, ListItem, Center } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useUser } from '../../store/useAppStore';
import { useNavigate } from 'react-router-dom';
import CommentDisplay from '../../components/Comments/CommentDisplay';
// import { CommentContainer } from '../../components';
import { useListTeacherLessons, useReprocessLesson } from '../Lesson/components/lessonHooks';
import SharedLessonNotification from '../Shared/components/SharedLessonNotification';
import { getTH } from '../../utlis/utils';
import moment from 'moment';
import { SmallGraph } from '../../components/SmallGraph';
import Pipeline from '../Pipeline';
import MyClasses from '../MyClasses';
import { ROLES } from '../../constants';
import trackEvent from '../../utlis/trackEvents';
import { useParams } from 'react-router-dom';
import { ACTIVITY_TYPES } from '../../constants';


const Dashboard = () => {
  const user = useUser();
  const navigate = useNavigate();
  const role = user.role[0];
  const isTeacher = role.includes('teacher');
  let group = 'teacher';
  if (!isTeacher) {
    ROLES.forEach(r => {
      if (r.value === role) {
        group = r.group;
      }
    })
  }

  const [filterBy, setFilterBy] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [modelVersion, setModelVersion] = useState('');
  const { id: reprocessId } = useParams();
  const { data: teacherLessons, error: teacherError, refetch: teacherFetch } = useListTeacherLessons({filterBy: '', filterQuery: '', teacherId: user._id});
 
  const [hasLessons, setHasLessons] = useState(false);
  const [featuredLesson, setFeaturedLesson] = useState(false);

  const activityOptions = Object.keys(ACTIVITY_TYPES).map((el, index) => ({
    label: ACTIVITY_TYPES[el],
    cleanLabel: ACTIVITY_TYPES[el],
    value: el,
    initialIndex: index,
  }));

  useEffect(() => {
    if (teacherLessons && teacherLessons.length) {
      setHasLessons(true);
      const processed = teacherLessons.filter(l => l.status === 'processed');
      if (processed.length > 0) {
        setFeaturedLesson(processed[0]);
      }
      teacherLessons.forEach(tl => {
        if (tl.status === 'processed') {
          // setting the activities to feature.. .top 4 activities
          const acts = tl.lessonActivities;
          let hasActivities = false;
          let top4 = ['a1', 'a2', 'a3', 'a24'];
          if (acts) {
            const tops = Object.keys(acts)
              .sort((a, b) => acts[b].numMoments - acts[a].numMoments)
              .slice(0, 4);
            // top4 = tops;

            top4.forEach(t => {
              if (acts[t].numMoments > 0) {
                hasActivities = true;
              }
            })
          }
        tl.hasActivities = hasActivities;
        // set the initial filter
        tl.top4Activities = top4; // .map((el) => activityOptions.find((item) => item.value === el));
        debugger;
        }
      })
    }
  }, [teacherLessons])


  const renderTopRightMessage = () => (
    <Box mb={2}>
      <Text fontWeight='800' fontSize='md'>Optimize your experience by:</Text>
      <UnorderedList  pl={4} fontSize='sm'>
        {group !== 'support'
        ?
        <>
        <ListItem>Uploading classroom videos and receive lesson analytics</ListItem>
        <ListItem>Analyzing trends across your own lessons</ListItem>
        <ListItem>Comparing and discussing your own lessons in relation to other teachers’ lessons</ListItem>
        </>
        :
        <>
        <ListItem>Analyzing trends across individual teachers’ lessons</ListItem>
        <ListItem>Comparing multiple teachers’ lessons</ListItem>
        <ListItem>Discussing lesson analytics with teachers </ListItem>
        </>     
        }   
      </UnorderedList>

      <Text fontWeight='800' fontSize='md' mt={2}>Lesson analytics include data related to:</Text>
      <UnorderedList pl={4} fontSize='sm'>
        <ListItem>Classroom activity structures</ListItem>
        <ListItem>Student-to-student talk comparison</ListItem>
        <ListItem>Hand raising moments</ListItem>
        <ListItem>Teacher movement</ListItem>
        <ListItem>Teacher support</ListItem>
      </UnorderedList>
      {/* <Text fontWeight='400' fontSize='xl'>HERE YOU CAN</Text>
      <Text className='mb-4 text-sm'>
      Once your Lesson video has been uploaded, be sure to review the analysis and answer the Reflections questions and private notes that only you can see. You can also share your lesson with anyone in your address book.
       </Text>
      <Text className='mb-4 text-sm'>
        <a href="/comparisons/" className="text-blue-600 underline hover:underline hover:text-blue-900">Comparing lessons
        </a> is a great way to look at data from a lesson compared to another. You can compare your lessons, or one of your Lessons to a Lessons that has been shared with you.
      </Text> */}
    </Box>
  )

  const recentlyProcessed = () => {
    if (!featuredLesson) {
      return null;
    }

    console.log('featuredLesson', featuredLesson);
    const { name, goal, summary } = featuredLesson.lessonDetails;
    return (
    <HStack mb={12} mt={4} gap={12}>
      <Box width="80%">
        <Text fontSize='xl'> <CheckCircleIcon color='green' /> Your video for <b>{name}</b> has been processed</Text>
        {goal && <Text fontSize='md' mt={4}><b>Lesson Goal:</b>{goal}</Text>}
        {summary && <Text fontSize='md' mt={4}><b>Lesson Summary:</b>{summary}</Text>}
      </Box>
      <Box>
        <VStack verticalAlign="center">
          <a href={`lesson/${featuredLesson._id}/reflections`}><Button variant='uva' mb={4} size='sm'>Add Reflections</Button></a>
          <a href={`lesson/${featuredLesson._id}`}><Button variant='uva' size='sm'>View Analysis</Button></a>
        </VStack>
      </Box>
    </HStack>
  )
  }

  const recentLessons = () => (
    <VStack align="left" ml={group === 'support' ? 6 : 0}>
      <HStack mb={4}>
        {group === 'support'
        ?
        <Text fontSize='xl' as='b' mr={32}>Recently Shared Lessons</Text>
        :
        <Text fontSize='xl' as='b' mr={32}>RECENT LESSONS</Text>
  }
    
        {hasLessons && <a href={group === 'support' ? '/shared' : '/my-lessons'} className="text-blue-600 underline hover:text-blue-900 hover:underline">View All</a>}
      </HStack>
      {hasLessons
        ?
        <>
          {teacherLessons.map((lesson, index) => (
            <Fragment key={`lesson-${lesson.lessonDetails.name}-${Math.random()}`} >
              {index < 4 && (
                <VStack align="left" mb={8}>
                  <HStack>
                    <Box minWidth={300}>
                      {lesson.status === 'processed'
                        ?
                        <span>
                          {lesson.hasActivities
                            ?
                            <SmallGraph lesson={lesson} activities={lesson.top4Activities} />
                            :
                            <Text>No Activities Found for this Lesson</Text>
                          }
                        </span>
                        :
                        <Center>
                          <Text>Processing Lesson Video</Text>
                        </Center>
                      }
                    </Box>
                    <Box>
                      {group === 'support' && (
                        <div>
                        <Text as='b' >Shared by: {lesson.teacher}</Text>
                        </div>
                      )
                      }
                      <Text as='b' >{lesson.period ? `${getTH(lesson.period)} period, ` : ''}{getTH(lesson.grade)} grade, {lesson.subject}: {lesson.lessonDetails.name}</Text>
                      <br />
                      <Text as='i' mt={4} fontSize='sm'>Lesson Date: {moment(lesson.lessonDate).format('M/D/YYYY')}</Text>
                      {lesson.lessonDetails.summary && <Text mt={4}><b>Lesson Summary:</b> {lesson.lessonDetails.summary}</Text>}
                      <Box>
                        {lesson.status === 'processed' ?
                          <>
                            <a href={`lesson/${lesson._id}`} className="mr-12 text-blue-600 underline hover:text-blue-900 hover:underline">Run Comparison</a>
                            <a href={`lesson/${lesson._id}`} className="text-blue-600 underline hover:text-blue-900 hover:underline">View Analysis</a>
                          </>
                          :
                          <Text as='i' fontSize='sm'>Video Upload Status: {lesson.status}</Text>
                        }
                      </Box>
                    </Box>
                  </HStack>
                  <hr className='mt-4' />
                </VStack>
              )}
            </Fragment>
          ))}
        </>
        :
      <>
          <Text fontSize='sm' as='b' mb={4}>You have not uploaded any lesson videos yet.
            <Button ml={24} variant='uva' size='xs' onClick={() => {navigate('/lesson/create');}}>Upload Lesson Video</Button>
          </Text>
          <Text>
          Welcome to the University of Virginia's Artificial Intelligence for Advancing Instruction (AIAI) Project. The AIAI team aims to support the advancement of your instruction by providing automated analytics about your teaching via this data dashboard. The process of reviewing and reflecting on instructional videos is now more efficient.
           </Text>
        </>
      }
    </VStack>
  )

  const renderAdminActions = () => (
    <VStack align='stretch' width='full'>
      <Text>Management Actions:</Text>
      <Button size='md' variant='secondary' onClick={() => {navigate('/comparisons');}}>
        Comparisons
      </Button>
      <Button size='md' variant='secondary' onClick={() => {navigate('/organizations');}}>
        Manage Organizations
      </Button>
      <Button size='md' mb='12' variant='secondary'onClick={() => {navigate('/users');}}>
        Manage Users
      </Button>
    </VStack>
  )


  return (
    <Box>
      <PageTitle title={`${isTeacher || user.firstName ? `${user && user.firstName ? `Hello, ${user.firstName}` : 'Welcome' }` : 'Dashboard'}`} />
      <HStack p={6}>
        <Grid
          templateRows='repeat(1, 1fr)'
          templateColumns='repeat(8, 1fr)'
          gap={4}
          width="full"
          mt={-16}
        >
          <GridItem colSpan={6} pr={12}>
            <Box mt={8}>
              <SharedLessonNotification />
            </Box>
            {isTeacher
              ?
              <>
                {recentlyProcessed()}
                {recentLessons()}
              </>
              :
              <>
              <Pipeline asPanel limit={group === 'support' ? 4 : -1}/>
              {group === 'support' && (
                <>
                {recentLessons()}
                <MyClasses asPanel />
                </>
              )}
              </>
            }
          </GridItem>

          {/* RIGHT COLUMN */}
          <GridItem colSpan={2} minW="300px">
            <VStack>
              {(isTeacher || group === 'support') && renderTopRightMessage()}
              {!isTeacher && group !== 'support' && role === 'admin' && renderAdminActions()}
              <Box width="100%">
                <CommentDisplay />
              </Box>
            </VStack>
          </GridItem>


        </Grid>

      </HStack>
    </Box>

  );
}
export default Dashboard;

