import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN, MIXPANEL_DEBUG, STAGE } from '../constants';
import { array } from 'prop-types';

export default function trackEvent(
  payload,
) {
  const action = payload.event || 'unknown';
  const me = payload.me || {};
  const userId = me && me._id ? me._id : 'Not signed in';
  const name = me && me.firstName ? `${me.firstName} ${me.lastName}` : '';
  const lessonId = payload.lessonId || '';
  let roles = me && me.role ? me.role : 'visitor';
  if (Array.isArray(roles)) {
    roles = roles.sort().join(', ');
  }

  // mixpanel init
  // Enabling the debug mode flag is useful during implementation,
  // but it's recommended you remove it for production
  const mpt = MIXPANEL_TOKEN;
  mixpanel.init(mpt, { 
    opt_out_tracking_by_default: false, 
    debug: MIXPANEL_DEBUG,
  });
  const optOut = mixpanel.has_opted_out_tracking();

  const environment = STAGE;
  // const pageWithId = process.env.NODE_ENV.startsWith('prod')
  //   ? `${page}-${userId}`
  //   : `${process.env.NODE_ENV.toUpperCase()}-${page}-${userId}`
  // eslint-disable-next-line no-undef
  // const gaValue = action !== 'Sign In' || optOut === false ? value : 9999;
  // const gaAction = description ? `${action}-${description} ${details}` : action;
  // ga('send', 'event', pageWithId, gaAction, description.trim(), gaValue);
  // //send a gtag event for google analytics with the same data as the ga event
  // gtag('event', action, { environment: domain, realm_page: page, user: userId, description, event_detail: details, value: gaValue });

  // mixpanel event

// mixpanel.opt_in_tracking();
  if (me && payload.event === 'Sign In') {
    mixpanel.identify(userId)
  }

  /* other NSF type event
           'action': objArgs.action || 'initialView',
            'subAction': 'none',
            'userAuthenticated': properties.isAuthenticated,
            'userIsCoach': properties.isCoach && true,
            'userIsResearcher': properties.isHATResearcher && true,
            'userId': sessionStorage.getItem('userId'),
            'userDistrict': sessionStorage.getItem('userDistrict'),      
            'tutorId': sessionStorage.getItem('tutorId') || 'none',
            'tutorName': sessionStorage.getItem('tutorName') || 'none'
  */

            try {
  const response = mixpanel.track(action, {
    environment,
    page: payload.page,
    pageParams: payload.params || '',
    lessonId,
    userId,
    name,
    role: roles,
    description: payload.description || '',
    details: payload.details || '',
    value: payload.value || 0,
  }
  );
  console.log('mixpanel.track response', response);
}
  catch (err) {
    const e = err;
    console.log('mixpanel.track error', e);
    debugger;
  }
}
