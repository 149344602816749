/* eslint-disable import/prefer-default-export */
import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../../queries/api';
import { useAppActions } from '../../store/useAppStore';


export const useCreateUser = (onClose, setErrorMessage) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      await api.post('/admin/users/create', data);
    },
    onError: (err) => {
      console.log('CREATE Suer ERROR ', err);
      setErrorMessage(err.response.data.msg);
    },
    onSuccess: () => {
      toast({
        title: 'User Created',
        description: 'An email has been sent with their sign in information',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      onClose();
      // invalidate user queries to trigger refetch
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useUpdateUser = (onClose, setErrorMessage) => {
  const { setUserData } = useAppActions();
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      await api.post('/admin/users/update', data);
    },
    onError: (err) => {
      console.log('UPDATE Suer ERROR ', err);
      setErrorMessage(err.response.data.errorMessage);
    },
    onSuccess: async () => {
      toast({
        title: 'User Updated',
        description: 'User has been updated',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      const user = await api.get('/users/me');
      setUserData(user.data.data);
      onClose();
      // invalidate user queries to trigger refetch
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
}

export const useFilterUsers = ({ filter }) =>
  useQuery({
    queryKey: ['users', { filter }],
    queryFn: async () => {
      const { data } = await api.post(`/users/filter`, { filter });
      return data;
    },
  });

export const useDeleteUser = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userId) => {
      await api.delete(`/users/delete/${userId}`);
    },
    onError: (err) => {
      console.log('DELETE USER ERROR ', err);
    },
    onSuccess: () => {
      toast({
        title: 'User Deleted',
        description: 'User has been deleted',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      // invalidate user queries to trigger refetch
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
}