import { useState, useEffect } from 'react';
import { Box, Stack, HStack, Select, Button, ScaleFade, Input, VStack, useColorMode, Text , Center} from '@chakra-ui/react';
import { useListSharedLessons } from '../Lesson/components/lessonHooks';
import { useUser } from '../../store/useAppStore';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';
import { DataTable } from '../../components/Tables';
import SharedLessonNotification from './components/SharedLessonNotification';

const capitalizeFirstLetter = (string) => string?.charAt(0).toUpperCase() + string?.slice(1) || '';

const headCells = [
  // note: ID is the name used for sorting
  {
    id: 'lessonDetails',
    numeric: false,
    disablePadding: true,
    label: 'Lesson',
  },
  {
    id: 'lessonDate',
    numeric: false,
    disablePadding: false,
    label: 'Lesson Date',
  },
  {
    id: 'teacherData',
    numeric: false,
    disablePadding: false,
    label: 'Teacher'
  },
  {
    id: 'classDetails',
    numeric: false,
    disablePadding: false,
    searchable: false,
    label: 'Class',
  },
  {
    id: 'shareStatus',
    numeric: false,
    disablePadding: false,
    searchable: false,
    label: 'New',
  },
  // {
  //   id: 'subject',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Subject',
  // },
  // {
  //   id: 'period',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Period',
  // }
];
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import PageTitle from '../../components/PageTitle/PageTitle';

const Shared = () => {
  const [filterBy, setFilterBy] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [tableRows, setTableRows] = useState([]);
  const user = useUser();
  const { data: lessons, error, refetch, isLoading } = useListSharedLessons({ userId: user._id });
  const {colorMode} = useColorMode();

  useEffect(() => {
    setTableRows(lessons || []);
  },[lessons])
  const handleChangeFilterBy = (value) => {
    setFilterBy(value);
  };
  const handleChangeFilterQuery = (value) => {
    setFilterQuery(value);
  };
  const lookForEnter = (e) => {
    if (e.key === 'Enter') {
      const tableData = [...lessons]
      setTableRows(tableData.filter((row) => {
        switch (filterBy) {
          case 'lessonDetails':
            return row.lessonDetails.name.toLowerCase().includes(filterQuery.toLowerCase());
          case 'sharedWithNames':
            return row.sharedWithNames?.filter((shared) =>  shared?.includes(filterQuery) || false )?.length > 0;
          case 'lessonDate':
            return moment(row.created).format('MM/DD/YYYY').includes(filterQuery);
          case 'teacherData':
            return (row.teacherData.firstName.toLowerCase() + ' ' + row.teacherData.lastName.toLowerCase()).includes(filterQuery.toLowerCase());
          default:
            return row[filterBy]?.includes(filterQuery.toLowerCase());
        }
      }
      ))
    }
  };
  const getFilterDisplayName = (filterBy) => {
    const filter = headCells.find((h) => h.id === filterBy);
    return filter ? filter.label : '';
  };

  const columnHelper = createColumnHelper();
  const columns = headCells.map((el) => {
    if (el.id === 'created') {
      return columnHelper.accessor(el.id, {
        cell: (info) => (!info.getValue() ? '' : moment(info.getValue()).format('MM/DD/YYYY')),
        header: el.label,
      });
    }
    if (el.id === 'teacherData') {
      return columnHelper.accessor(el.id, {
        cell: (info) => {
          const teacher = info.getValue();
          const firstName = teacher?.firstName || '';
          const lastName = teacher?.lastName || '';
          return `${firstName} ${lastName}`;
        },
        header: el.label,
      });
    }
    if (el.id === 'lessonDetails') {
      return columnHelper.accessor(el.id, {
        cell: (info) => {
          const lesson = info.getValue();
          const name = lesson?.name || '';
          const lessonDate = lesson?.lessonDate || '';
          const term = lesson?.term || '';
          // console.log(lesson)
          return `${name}`;
        },
        header: el.label,
      });
    }
    if (el.id === 'classDetails') {
      return columnHelper.accessor(el.id, {
        cell: (info) => {
          const [classDetails] = info.getValue();
          const name = classDetails?.name || '';
          const subject = classDetails?.subject || '';
          const grade = classDetails?.grade || '';
          const period = classDetails?.period || '';
          const term = classDetails?.term || '';
          const display = `${name}, grade ${grade}, ${period ? `period ${period}, ` : ''} subject: ${subject}`
          return `${display}`;
        },
        header: el.label,
      });
    }
    if (el.id === 'shareStatus') {
      return columnHelper.accessor(el.id, {
        cell: (info) => {
          const isNew = info.getValue();
          const display = isNew === 'new' ? 'New' : ''
          return `${display}`;
        },
        header: el.label,
      });
    }
    return columnHelper.accessor(el.id, {
      cell: (info) => capitalizeFirstLetter(info.getValue()) || null,
      header: el.label,
    });
  });

  const renderSharedLessons = () => (
      <>
      <Stack
      direction='row'
      className='mt-4 mb-4 whitespace-nowrap'
      justifyContent='space-between'
    >
       <HStack direction='row' flexWrap='wrap' gap='2' w={'full'}>
        <div className='w-44 pl-0'>
          <Select
            id='filter-by'
            fontSize='sm'
            value={filterBy}
            onChange={e => handleChangeFilterBy(e.target.value)}
            size='sm'
            // style={{border: 'none', background: '#222327', borderRadius: 5}}
            className='darkSelect'
          >
            <option value='' key='nothing-selected'>Search by...</option>
            {headCells
              .filter(h => h.searchable !== false)
              .map(h => (
                <option value={h.id} key={h.id}>
                  {h.label}
                </option>
              ))}
          </Select>
        </div>

        <ScaleFade initialScale={0.9} in={filterBy}>
          <Input
            //  variant="flushed"
            placeholder={`${getFilterDisplayName(filterBy)}`}
            variant='filled'
            style={{background: colorMode === 'dark' ? '#222327' : '', borderRadius: 5, border: '2px'}}
            fontSize='sm'
            minWidth='240px'
            size='sm'
            id='filter-query'
            value={filterQuery}
            onChange={e => handleChangeFilterQuery(e.target.value)}
            onKeyDown={lookForEnter}
            onBlur={() => refetch()}
          />
        </ScaleFade>
      </HStack>
    </Stack>
    <DataTable columns={columns} data={tableRows} />
  </>
    )
  
  return (
    <Box> 
       <PageTitle title="Shared Lessons" />
       <Stack p={6}>
        <SharedLessonNotification lessons={lessons} />
        {isLoading && ( <Text>Loading shared lessons...</Text>)}
        {!isLoading && (
            <span>
            {tableRows.length > 0
              ? <>{renderSharedLessons()}</>
              : <Center><Text>Currently, you have no shared lessons</Text></Center>
            }
            </span>
        )}
      </Stack>
    </Box>
  );
};

export default Shared;
