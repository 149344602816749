import { defineStyleConfig } from '@chakra-ui/react';

const Textarea = defineStyleConfig({
  defaultProps: {
    focusBorderColor: 'primary.400',
  },
  baseStyle: (props) => ({
    borderColor: props.colorMode === 'dark' ? 'secondary.300' : 'secondary.800',
  }),
});
export default Textarea;
