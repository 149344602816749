import {
  Button,
  Center,
  VStack,
  useToast,
  useColorModeValue,
  Heading,
  Text
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VisitorPage from '../../components/VisitorPage/VisitorPage';
import api from '../../queries/api';
import { FormInput } from '../../components/forms/FormElements';
import Form from '../../components/forms/Form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ResetSchema } from '../../schemas/users.ts';
import { useSearchParams } from 'react-router-dom';

const Reset = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState('');


  const HandleReset = async (data) => {
    data.token = token;
    setIsLoading(true);
    if(data.password !== data.confirm) {
      setErrors('Passwords do not match');
      setIsLoading(false);
      return;
    }

    const response = await api.post('/users/auth/reset/password', data);
    if (response) {
      toast({
        title: 'Success!',
        description: 'Password has been reset!',
        status: 'success',
        duration: 7000,
        isClosable: true,
      });
      navigate('/signin');
    } else {
      toast({
        title: 'Something went wrong.',
        description: `There was an error resetting your password. Please try again.`,
        status: 'error',
        duration: 7000,
        isClosable: true,
      });
    }
    setIsLoading(false);

    // navigate('/');
  };

  return (
    <VisitorPage>
      <Center h='full' w='full'>
      <VStack
          w='lg'
          bg={useColorModeValue('whiteAlpha.800', 'blackAlpha.100')}
          borderRadius='lg'
          gap='3'
          shadow='md'
          pb='2rem'
        >
          <Heading
            as='h2'
            size='lg'
            pt='4'
            color={useColorModeValue('secondary.900', 'secondary.100')}
          >
            Reset Password
          </Heading>
          <Text p='2' fontSize='sm' color={useColorModeValue('secondary.700', 'secondary.200')}>Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.</Text>
          <Form onSubmit={HandleReset} resolver={zodResolver(ResetSchema)} id='reset'>              
          <FormInput
              name='password'
              label='Password'
              type='password'
              w='md'
            />
            {errors && <Text color='red.500'>{errors}</Text>}
            <FormInput name='confirm' label='Confirm Password' type='password' />
          </Form>
          <Button type='submit' value='submit' form='reset' variant='uva' isLoading={isLoading}>
            Reset Password
          </Button>
        </VStack>
      </Center>
    </VisitorPage>
  );
};
export default Reset;
