/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import api from '../../../queries/api';
import { useUser } from '../../../store/useAppStore';
import trackEvent from '../../../utlis/trackEvents';
import { useNavigate } from 'react-router-dom';
/* 
NOTE:
Query functions either need to throw an error or reject a promise in order for the onError callback to fire.
 */
const listLessons = async ({ filterBy, filterQuery }) => {
  const response = await api.post('/lessons/list', { filterBy, filterQuery });
  return response.data.lessons;
};

const listLessonsPipeline = async ({ filterBy, filterQuery }) => {
  const response = await api.post('/lessons/pipeline', { filterBy, filterQuery });
  return response.data.lessons;
};

const listTeacherLessons = async ({queryKey}) => {
  const [, { teacherId }] = queryKey;
  const response = await api.post(`/lessons/teacher/list/${teacherId}`, { filterBy: '', filterQuery: '' });
  return response.data.lessons;
};

const listSharedLessons = async ({queryKey}) => {
  const [, { filterBy, filterQuery, userId }] = queryKey;
  const response = await api.post(`/lessons/shared/list/${userId}`, { filterBy, filterQuery });
  return response.data.lessons;
};

const getLesson = async ({ queryKey }) => {
  const [, { lessonId, userId }] = queryKey;
  const response = await api.get(`/lessons/lessonInfo/${lessonId}/${userId}`);
  return response.data;
};
export const useGetLesson = (lessonId) => {
  const user = useUser();
  const toast = useToast();
  const query = useQuery({
    queryKey: ['lesson-by-id', { lessonId, userId: user._id }],
    queryFn: getLesson,
    enabled: !!lessonId,
    refetchOnWindowFocus: false,
    retry: false,
    onError: (err) => {
      console.log('GET LESSON ERROR ', err);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching this lesson. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    },
  });
  useEffect(() => {
    if (query.error) {
      console.log('GET LESSON ERROR ', query.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching this lesson. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [query.error]);
  return query;
};

export const useListLessons = (filterBy, filterQuery) => {
  const user = useUser();
  const toast = useToast();
  const query = useQuery({
    queryKey: ['lesson-list', { filterBy, filterQuery, userRole: user.role }],
    queryFn: listLessons,
    refetchOnWindowFocus: false,
    retry: false,
  });
  useEffect(() => {
    if (query.error) {
      console.log('GET LESSON ERROR ', query.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching this lesson. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [query.error]);
  return query;
};

export const useListLessonsPipeline = () => {
  const user = useUser();
  const toast = useToast();

  const query = useQuery({
    queryKey: ['lesson-list', { filterBy: '', filterQuery: '', userRole: user.role }],
    queryFn: listLessonsPipeline,
    refetchOnWindowFocus: false,
    retry: false,
  });
  useEffect(() => {
    if (query.error) {
      console.log('GET LESSON ERROR ', query.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching this lesson. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [query.error]);
  return query;
};

export const useListTeacherLessons = () => {
  const user = useUser();
  const toast = useToast();
  const query = useQuery({
    queryKey: ['lesson-list', { userRole: user.role, teacherId: user._id }],
    queryFn: listTeacherLessons,
    refetchOnWindowFocus: false,
    retry: false,
  });
  useEffect(() => {
    if (query.error) {
      console.log('GET LESSON ERROR ', query.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching your lessons. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [query.error]);
  return query;
};

export const useUpdateShare = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: async (params) => {
      if (params?.lessonId) {
        await api.post(`lessons/share-update`, params);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lesson-share', {}] });
    },
    onError: (err) => {
      console.log('SHARE UPDATE ERROR ', err);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error updating this shared lesson. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    },
  });
};

export const useListSharedLessons = () => {
  const user = useUser();
  const toast = useToast();
  const query = useQuery({
    queryKey: ['lesson-list', { filterBy: '', filterQuery: '', userRole: user.role, userId: user._id }],
    queryFn: listSharedLessons,
    refetchOnWindowFocus: false,
    retry: false,
  });
  useEffect(() => {
    if (query.error) {
      console.log('GET LESSON ERROR ', query.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching lessons shared with you. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [query.error]);
  return query;
};

export const useEditReflection = (lessonId) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const user = useUser();
  const mutation = useMutation({
    mutationFn: async (editedReflection) => {
      await api.post('/reflections/edit', editedReflection);
    },
  });
  useEffect(() => {
    if (mutation.data) {
      queryClient.invalidateQueries({ queryKey: ['lesson-by-id', { lessonId, userId: user._id }] });
    }
  }, [mutation.data]);
  useEffect(() => {
    if (mutation.error) {
      console.log('EDIT COMMENT ERROR ', mutation.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching this lesson. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [mutation.error]);
  return mutation;
};

export const useShareLesson = (lessonId) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const user = useUser();
  const editSharedUsers = useMutation({
    mutationFn: async ({selectedUserIds, permissionLevel, refetch}) => {
      console.log(refetch)
      await api.post('/lessons/share', {selectedUserIds, permissionLevel, lessonId});
      refetch();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lesson-shares', { lessonId, userId: user._id }] });
      toast({
        title: 'Success',
        description: 'This lesson has been shared.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });

    },
    onError: (err) => {
      console.log('EDIT COMMENT ERROR ', err);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error sharing this lesson. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  });

  return {editSharedUsers};
};

export const useGetLessonShares = (lessonId) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const getLessonShares = useQuery({
    queryKey: ['lesson-shares', { lessonId }],
    queryFn: async () => {
      const response = await api.get(`/lessons/share/${lessonId}`);
      return response.data;
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
  useEffect(() => {
    if (getLessonShares.error) {
      console.log('GET LESSON ERROR ', getLessonShares.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching this lesson. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [getLessonShares.error]);
  return getLessonShares;
}


export const useUpdateLesson = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: async (newLessonData) => {
      const updateResponse = await api.post('/lessons/update', newLessonData);
    },
    onSuccess: async () => {
      toast({
        title: 'Success',
        description: 'Lesson updated successfully.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      queryClient.invalidateQueries('lessons');
    },
    onError: (err) => {
      toast({
        title: 'Something went wrong.',
        description: 'There was an error updating this lesson. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    },
    
  });
  return mutation;
}
  export const useReprocessLesson = () => {
    const queryClient = useQueryClient();
    const toast = useToast();
    const user = useUser();
    const navigate = useNavigate();
    const reprocessLesson = useMutation({
      mutationFn: async (modelVersion) => {
        const lessonId = window.location.search.split('=')[1];
        trackEvent({
          page: 'Pipeline',
          params: '',
          event: 'Reprocess Video',
          me: user,
          description: 'Reprocess Video',
          details: `lessonId: ${lessonId}`,
          value: 0,
        });
        await api.post(`/lessons/reprocess/${lessonId}`, { modelVersion });
        navigate('/')
      },
      onSuccess: () => {
        toast({
          title: 'Success',
          description: 'Lesson reprocess request sent.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
        queryClient.invalidateQueries('lesson-list');
      },
      onError: (err) => {
        console.log('REPROCESS LESSON ERROR ', err);
        toast({
          title: 'Something went wrong.',
          description:
            'There was an error requesting reprocessing of this lesson. Please try again.',
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      },
    });
    return { reprocessLesson };
  };

  export const useDeleteLesson = () => { 
    const queryClient = useQueryClient();
    const toast = useToast();
    const user = useUser();
    const deleteLesson = useMutation({
      mutationFn: async (lessonId) => {
        await api.post(`/lessons/delete/${lessonId}`);
      },
      onSuccess: () => {
        toast({
          title: 'Success',
          description: 'Lesson deleted successfully.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
        queryClient.invalidateQueries('lesson-list');
      },
      onError: (err) => {
        console.log('DELETE LESSON ERROR ', err);
        toast({
          title: 'Something went wrong.',
          description: 'There was an error deleting this lesson. Please try again.',
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      },
    });
    return deleteLesson;
  }
