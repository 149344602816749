/* eslint-disable jsx-a11y/media-has-caption */
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
// eslint-disable-next-line camelcase
// eslint-disable-next-line camelcase
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Collapse,
  Divider,
  FormControl,
  HStack,
  Heading,
  Skeleton,
  Text,
  Center,
  VStack,
  useToast,
  Stack,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { CheckIcon, EditIcon } from '@chakra-ui/icons';
import { components } from 'chakra-react-select';
import Select from "react-select";
import moment from 'moment';

import { useParams } from 'react-router-dom';
import trackEvent from '../../utlis/trackEvents';

import { CommentContainer } from '../../components';
import { ACTIVITY_TYPES, ACTIVITY_COLORS } from '../../constants';
import { useUser } from '../../store/useAppStore';
import { useGetLesson, useUpdateShare, useUpdateLesson } from './components/lessonHooks';
import { Reflection, ShareLessonPanel } from './components';
import PageTitle from '../../components/PageTitle/PageTitle';
import UpdateLesson from './components/updateLesson';
import SelectActivities from './components/ SelectActivites';
import FontAwesome6 from '../../components/FontAwesome6/FontAwesome6';


let activityOptions = Object.keys(ACTIVITY_TYPES).map((el, index) => ({
  label: ACTIVITY_TYPES[el],
  cleanLabel: ACTIVITY_TYPES[el],
  value: el,
  initialIndex: index,
}));

const initialFilters = [
  activityOptions[0],
  activityOptions[1],
  activityOptions[2],
  activityOptions[23],
];

const Lesson = () => {
  const { lessonId, reflections } = useParams();
  // WE NEED TO DEFINE IF THE LESSON IS MINE SO WE CAN PASS TO COMPONENTS
  const toast = useToast();
  const user = useUser();
  const videoRef = useRef();

  const colorMap = {};
  const { data, isLoading, refetch, isError } = useGetLesson(lessonId);
  const updateShare = useUpdateShare();
  const updateLesson = useUpdateLesson();

  const [activityFilter, setActivityFilter] = useState([]);
  const [openSharePanel, setOpenSharePanel] = useState(false);
  const [classInfo, setClassInfo] = useState('');
  const [nonDefault, setNonDefault] = useState([]);

  const [defaultPanelIndex, setDefaultPanelIndex] = useState(reflections ? 1 : 0);
  // const filteredActivityOptions = activityOptions?.filter((el) =>
  //   data?.lesson?.categories?.find((item) => item === el.value),
  // );

  const filteredActivityOptions = activityOptions;


  const lessonName = data?.lesson?.name ? `${data.lesson.name} (${moment(data.lesson.lessonDate).format('M/d/YYYY')})` : '';
  const isMine = data?.lesson?.teacherId === user._id;
  const role = user.role[0];
  const isAdmin = role === 'admin' || role.includes('stakeholder') || role.includes('researcher');

  const sharePermissions = isMine ? 'avr' : data?.lesson?.sharePermissions;
  console.log(data?.lesson?.videoUrl)

  useEffect(() => {
    if (reflections) {
      setTimeout(() => {
      const myDiv = document.getElementById("reflections");
        window.scroll({
          top: myDiv.offsetTop + 1000,
          left: 0,    
          behavior: 'smooth'
        });
      }, 500);
    }

    // in case this was shared, mark viewed:
    updateShare.mutate(
      { lessonId, status: 'viewed' },
      {
        // onSuccess: () => {
        //   refetchSharedLessons();
        // },
        // onError: (err) => { alert(err) }
      },
    );
  }, [])

  useLayoutEffect(() => {
    am5.addLicense("AM5C450941845");
    
    // Create root element
    if(!data?.lesson?.lessonActivityData?.data) return;
    const root = am5.Root.new('chartdiv');

    // Set the time format
    root.dateFormatter.setAll({
      dateFormat: 'mm:ss',
      dateFields: ['valueX', 'openValueX'],
    });
    // set the theme
    // eslint-disable-next-line camelcase
    root.setThemes([am5themes_Animated.new(root)]);
    // create the chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: root.verticalLayout,
      }),
    );

    // Make an object with all the colors so we can map them to the activity types, and they don't change with filters
    const colors = chart.get('colors');
    // Object.values(ACTIVITY_TYPES).forEach((el, index) => {
    //   colorMap[el] =  am5.color('#00ff00');  colors.getIndex(index);
    //   debugger;
    // });

    Object.keys(ACTIVITY_COLORS).forEach((key, index) => {
      colorMap[ACTIVITY_TYPES[key]] = am5.color(ACTIVITY_COLORS[key].bg);
    })

    const yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 10,
    });

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'category',
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {}),
        minHeight: 50,
      }),
    );
    yAxis.get('renderer').labels.template.setAll({
      fontSize: 12,
      visible: false,
      // paddingTop: 0,
      // paddingBottom:10,
      // minGridDistance: 10,
    });

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'second', count: 1 },
        gridIntervals: [{ timeUnit: 'minute', count: 5 }],
        renderer: am5xy.AxisRendererX.new(root, { strokeOpacity: 0.1 }),
        markUnitChange: false,
        min: new Date(2023, 0, 1, 0, 0, 0).getTime(),
      }),
    );
    xAxis.get('renderer').labels.template.setAll({
     // rotation: -40,
      centerY: am5.p50,
      // centerX: am5.p100,
      fontSize: 10,
    });
    xAxis.get("dateFormats")["minute"] = "m";
    xAxis.children.push(am5.Label.new(root, {
      text: 'Minutes',
      textAlign: 'left',
      // x: am5.p50,
      fontWeight: '400',
      fontSize: 12,
    }));

    // Add series
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis,
        yAxis,
        openValueXField: 'start',
        valueXField: 'end',
        categoryYField: 'category',
        sequencedInterpolation: true,
      }),
    );

    series.columns.template.events.on('click', (event) => {
      const difTime = moment(event.target._dataItem.dataContext.start).diff(moment(new Date(2023, 0, 1, 0, 0, 0)), 'seconds')
      videoRef.current.currentTime = difTime;
    });


    series.columns.template.setAll({
      templateField: 'columnSettings',
      strokeOpacity: 0,
      tooltipText: '{category}:\n[bold]{openValueX}[/] - [bold]{valueX}[/]',
      minWidth: 10
    });



    const { currentTime } = videoRef.current; // Example: get the current time from a video element
    const rangeDataItem = xAxis.makeDataItem({
      value: new Date(2023, 0, 1, 0, 0, currentTime).getTime(),
      endValue: new Date(2023, 0, 1, 0, 0, currentTime).getTime() + 3000 // Adjust the duration as needed
    });

    series.createAxisRange(rangeDataItem);
    const videoElement = videoRef?.current;
    videoElement.addEventListener('timeupdate', () => {
      const { currentTime } = videoRef.current;
      rangeDataItem.setAll({
        value: new Date(2023, 0, 1, 0, 0, currentTime).getTime(),
        endValue: new Date(2023, 0, 1, 0, 0, currentTime).getTime() + 3000 // Adjust the duration as needed
      });
    });

    rangeDataItem.get("axisFill").setAll({
      fill: am5.color('#000'),
      fillOpacity: 1 ,
      visible: true
    });
    // ALL THE DATA STUFF HAPPENS HERE
    if (data?.lesson?.lessonActivityData?.data && data?.lesson?.categories) {
      const dataSet = data.lesson.lessonActivityData.data;
      const dataArray = [];
      console.log('dataSet', dataSet)
      data.lesson.categories.forEach((el) =>
        dataSet[el].moments.forEach((item) =>
          dataArray.push({
            category: ACTIVITY_TYPES[el],
            start: new Date(2023, 0, 1, 0, 0, item.s).getTime(),
            end:   new Date(2023, 0, 1, 0, 0, item.e).getTime(),
            // length: item.l,
            columnSettings: {
              fill: colorMap[ACTIVITY_TYPES[el]],
            },
          }),
        ),
      );
      console.log(dataArray)
      const finalCats = Object.values(ACTIVITY_TYPES)
        ?.filter((el) => activityFilter.find((item) => item.cleanLabel === el))
        .map((el) => ({ category: el, color: colorMap[el] }));

      yAxis.data.setAll(finalCats);

      // Sort the category alphabetically
      yAxis.dataItems.sort((a, b) => b.get('category').localeCompare(a.get('category')));
      const filteredData = dataArray?.filter((el) =>
        activityFilter.find((item) => item.cleanLabel === el.category),
      );
      series.data.setAll(filteredData);

      const legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
          nameField: 'category',
          fillField: 'color',
          strokeField: 'color',
        }),
      );

      legend.itemContainers.template.events.on("click", (event) => {
        // Do whatever you want
        // eslint-disable-next-line no-underscore-dangle
        const { category } = event.target._dataItem.dataContext
        // eslint-disable-next-line no-use-before-define
        handleLegendDelete(category);

      });

      // Manually set the legend withe alphabetical categories
      legend.data.setAll(finalCats.sort((a, b) => a.category.localeCompare(b.category)));
      series.appear();
      chart.appear(1000, 100);
    }
    return () => {
      root.dispose();
    };
  }, [data?.lesson, activityFilter]);

  const isNum = (val) => {
    return /^\d+$/.test(val);
  }

  useEffect(() => {
    // set the classInfo here
    if (data?.lesson?.classData) {
      const { classData } = data.lesson;
      const ci = `${
        !isMine && !isAdmin
          ? `Shared by ${data.lesson.teacherData.firstName} ${data.lesson.teacherData.lastName}: `
          : ''
      }${classData.name} | Grade ${classData.grade}, ${
        classData.period ? `Period ${classData.period}, ` : ''
      } ${classData.subject}`;
      setClassInfo(ci);
      // find the 4 objects with the highest numMoments in the data set
      if (data?.lesson?.lessonActivityData?.data && data?.lesson?.categories) {

        const dataSet = data.lesson.lessonActivityData.data;
        //edit activityOptions to include the numMoments in parenthases after the label
        activityOptions = activityOptions.map((el) => {
          if (!isNum(el.label.slice(-2,-1))){
            const {numMoments} = dataSet[el.value];
            return {
              ...el,
              label: `${el.label} (${numMoments})`,
            };
          }
          return {...el}
        });


        const default4Data = initialFilters.map((el) => ({...dataSet[el.value], value: el.value}))
        const useDefault = !default4Data.every((el) => el.numMoments === 0);
        const all4 = Object.keys(dataSet)
        .sort((a, b) => dataSet[b].numMoments - dataSet[a].numMoments)
        .slice(0, 4);
        debugger;
        if (useDefault) {
          const default4 = default4Data.filter(dm => dm.numMoments !== 0).map(dd => dd.value)
          const initialFilter = default4.map((el) => activityOptions.find((item) => item.value === el));
          setActivityFilter(initialFilter)
          setNonDefault(initialFilter)
        }
        else {
        const top4 = Object.keys(dataSet)
          .sort((a, b) => dataSet[b].numMoments - dataSet[a].numMoments)
          .slice(0, 4);
        // set the initial filter
        const initialFilter = top4.map((el) => activityOptions.find((item) => item.value === el));
        setActivityFilter(initialFilter)
        setNonDefault(initialFilter)
        }
      }
    }
  }, [data]);

  const handleFilter = (data, type) => {
    if (type.action === 'deselect-option') {
      const av = type.option.value;
      const nf = activityFilter.filter(activity => activity.value !== av);
      debugger;
      if (nf.length === 0) {
        if (nonDefault.length > 0) {
          setActivityFilter([...nonDefault]);
        } else {
          setActivityFilter([...initialFilters]);
        }
      } else {
        setActivityFilter(nf);
      }
      trackEvent({
        page: 'Lesson',
        params: `${lessonId}`,
        event: 'Activity Removed',
        me: user,
        lessonId,
        description: lessonName,
        details: type.option.cleanLabel,
        value: 0,
      })
      return;
    }
    if (type.action === 'clear') {
      setActivityFilter([...initialFilters]);
      return;
    }
    if (activityFilter.length >= 8) {
      toast({
        title: 'Too many filters selected',
        description: 'Please select 8 or less filters',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    } 
      // track this event
      const selections = data.map(d => d.cleanLabel);
      trackEvent({
        page: 'Lesson',
        params: `${lessonId}`,
        event: 'Activity Selection',
        me: user,
        lessonId,
        description: lessonName,
        details: selections.join(', '),
        value: 0,
      })
    
    setActivityFilter(data);
  };

  const updateFromSelectionModal = (selections) => {
    const newFilter = selections.map((el) => activityOptions.find((item) => item.value === el));
    setActivityFilter(newFilter);
  }

  const handleLegendDelete = (category) => {
    let initialIndex = -1;
    let value = '';
    activityFilter.forEach((activity, index) => {
      if(activity.cleanLabel === category ) {
        initialIndex = activity.initialIndex;
        value = activity.value;
      }
    });
    const newList = activityFilter.filter(af => af.cleanLabel !== category);
    const event = {
      action: "deselect-option",
      name: undefined,
      option: {
        initialIndex,
        label: category,
        value,
      }
    }
    handleFilter(newList, event)
  }
  // const metrics = data?.lesson?.metrics?.filter((el) =>
  //   activityFilter.find((item) => item.label === ACTIVITY_TYPES[el.name]),
  // );

  const metrics = data?.lesson?.metrics?.filter((el) =>
   activityFilter.find((item) => item.cleanLabel === ACTIVITY_TYPES[el.name]),
);

console.log('metrics', data?.lesson?.metrics)
console.log('filter', activityFilter)

const graphHeights = [
  0, 
  17, 18, 19, 22, 
  24, 29, 32, 36
]
  // const calcGraphHeight = () => `${activityFilter.length > 3 ? `${activityFilter.length * 5.5}vh` : '18vh'}`;
  const calcGraphHeight = () => `${graphHeights[activityFilter.length]}vh`;

  console.log(calcGraphHeight());

  const renderMetrics = () => (
    <Box>
      <Text fontSize='sm' mb={1}>
        {/* <StarIcon mr={2} color='#557ab6' size='xs'/> */}
        LESSON SUMMARY METRICS
      </Text>
      <Flex style={{flexWrap: 'wrap'}}>
        {metrics?.map((metric) => (
          <Box key={`metric-${metric.name}`} mr={4} mb={2} padding={2} minH='70px' minW='170px' maxW='250px' borderRadius='lg' background={ACTIVITY_COLORS[metric.name].bg} color={ACTIVITY_COLORS[metric.name].text}>
            <Stack direction={'row'} spacing={1}>
              {/* <StarIcon mr={2} color='#557ab6' size='xs' align={'top'}/> */}
              <FontAwesome6 icon={ACTIVITY_COLORS[metric.name].icon} />
              <Text ml={2} fontSize='xs' fontWeight={'bold'}>{ACTIVITY_TYPES[metric.name]}</Text>
            </Stack>
            <Box>

              <Box style={{ textAlign: 'center' }}>
                <Text fontSize='xs' >{metric.count} Moments {ACTIVITY_COLORS[metric.name].isWide && metric.length !== 0 ? ` ${metric.length.toFixed(2)} Minutes` : ''}</Text>
                {!ACTIVITY_COLORS[metric.name].isWide && metric.length !== 0 && (<Text fontSize='xs' >{metric.length.toFixed(2)} Minutes</Text>)}
              </Box>
            </Box>
          </Box>
          // {ACTIVITY_TYPES[metric.name]} Moments: {metric.count} - {metric.length} mins

        ))}
      </Flex>
     </Box>
  );

  const MyLessonBreadCrumbs = [{
    label: 'My Lessons',
    link: '/my-lessons'
  }, {
    label: lessonName,
    link: null,   // means not a link, as it's last
  }]

  const SharedLessonBreadCrumbs = [{
    label: 'Shared Lessons',
    link: '/shared'
  }, {
    label: lessonName,
    link: null,   // means not a link, as it's last
  }]

  const AdminBreadCrumbs =   [{
    label: 'Lessons',
    link: '/list-lessons'
  }, {
    label: lessonName,
    link: null,   // means not a link, as it's last
  }]

  // eslint-disable-next-line no-nested-ternary
  const breadCrumbs = isMine ? MyLessonBreadCrumbs : isAdmin ? AdminBreadCrumbs : SharedLessonBreadCrumbs;

  return (
    <>
      <PageTitle 
        title=''
        breadCrumbs={isLoading ? [] : breadCrumbs}
        >
          <HStack>
          <a href={`/comparisons?lesson=${lessonId}`}>
            <Button variant='uva-outline' size='sm'>
              Run Comparison
            </Button>
            </a>
            {isMine && (
            <VStack mt='2'>
              <Button variant='uva' size='sm' onClick={() => setOpenSharePanel(!openSharePanel)}>
                Share Lesson
              </Button>
              <Box position='relative'>
                <Collapse
                  in={openSharePanel}
                  animateOpacity
                  style={{ position: 'absolute', zIndex: 10, right: '-58px' }}
                >
                  <ShareLessonPanel lessonId={lessonId} handleClose={() => {setOpenSharePanel(false)}} />
                </Collapse>
              </Box>
            </VStack>
            )}
          </HStack>
      </PageTitle>
    <Box mt='-16px' mb='4px' ml={'65px'}>
    <Text fontSize='xs'>{classInfo}</Text>
    </Box>
    <Box mx='16'>

      {/* START OF LEFT COLUMN */}
      {/* <FormControl id='acitvity-filter' maxW='500px'>
            <Select
              placeholder="Select up to 8 Activities to Show"
              value={activityFilter}
              controlShouldRenderValue={false}
              isMulti
              name="ACTIVITIES"
              options={filteredActivityOptions}
              onChange={handleFilter}
              classNamePrefix="select"
              hideSelectedOptions={false}
              isClearable={false}
              components={{
                // eslint-disable-next-line react/no-unstable-nested-components
                Option: ({ children, ...props }) => (
                  <components.Option {...props}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "20px"
                        }}
                      >
                        {props.isSelected
                          ?
                          <div>
                            <CheckIcon />
                          </div>
                          :
                          <div >
                             &nbsp;
                          </div>
                        }
                      </div>
                      {children}
                    </div>
                  </components.Option>
                )
              }}
            />
          </FormControl> */}
      <SelectActivities 
        defaultActivities={nonDefault.length > 0 ? nonDefault : initialFilters}
        activitiesCounts={activityOptions}
        currentActivities={activityFilter}
        updateActivities={updateFromSelectionModal}
      />
      <div style={{background: '#f3f1f1', minHeight: 220, borderRadius: 8,  height: `${calcGraphHeight()}` }}>
      {/* <Skeleton isLoaded={!isLoading} width='100%' height={calcGraphHeight()} my={4} mb={8}>
        <div id='chartdiv' style={{ width: '100%', minHeight: '100px', height: `${calcGraphHeight()}` }}> */}
        <Skeleton isLoaded={!isLoading} width='100%' height='120px' my={4} mb={8}>
        <div id='chartdiv' style={{ width: '100%', minHeight: '100px', height: `${calcGraphHeight()}` }}>
          </div>
          <div style={{fontSize: 12, fontStyle: 'italic'}}><Center>Click on an activity name to remove from the graph</Center></div>
      </Skeleton>
      </div>
      <Divider />
      <HStack alignItems='flex-start' gap={4} className='mt-8'>
            {isLoading ? (
              <Skeleton height='400px' width='50%' isLoaded={false} />
            ) : (
              <VStack align='stretch'>
              <Box width={400} >
                <Text fontSize='sm' mb={1} >
                  {/* <StarIcon mr={2} color='#557ab6' size='xs'/> */}
                  LESSON VIDEO
                </Text>
                <video controls type="video/mp4" src={data?.lesson?.videoUrl} style={{width: '100%'}} ref={videoRef}/>
                {!isAdmin && !isMine && sharePermissions && !sharePermissions.includes('v') &&
                <Box mt='-200px' width={400} height={200} background='#893ccd7a' color='#ddd' position='absolute' zIndex={1000}
                ><Center><Text pt={50}>Video Not Available for Viewing</Text></Center></Box>
                }
                <Text style={{ textAlign: 'center' }} fontSize='10px' mt={1} >
                  {data?.lesson?.lessonActivityData?.data?.stats?.vidLength
                    ? `${Math.round(data.lesson.lessonActivityData.data.stats.vidLength / 60)} minute lesson`
                    : null}
                </Text>
              </Box>
              {/* <Text fontSize='sm'>{classInfo}</Text> */}
              </VStack>
            )}

            {renderMetrics()}

        </HStack>
        <HStack alignItems='flex-start' gap={16} className='mt-8'>
        
            <Box style={{width: '100%'}} mb={32}>
              <Accordion defaultIndex={[0, defaultPanelIndex]} allowMultiple>
                <AccordionItem
                  bg='#f3f1f1'
                  style={{borderBottom: '1px solid #ccd', borderTopRightRadius: 5, borderTopLeftRadius: 5}}
                >
                  <h2>
                    <AccordionButton>
                      <AccordionIcon />
                    <Box as='span' flex='1' textAlign='left'>
                      Lesson Summary & Goals
                      {isMine && (
                      <div style={{float: 'right'}}>
                      {/* <IconButton
                        size="sm"
                        onClick={() => { alert('edit!') }}
                        aria-label="Edit Lesson Details"
                        icon={<EditIcon w={5} h={5} />}
                      /> */}
                      <UpdateLesson currentLesson={data.lesson} refetch={refetch}/>
                      </div>
                      )}
                    </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Box mb={4}>
                      <Heading as='h4' size='xs'>
                        Summary:
                      </Heading>
                      <Text>{data?.lesson?.summary}</Text>
                    </Box>
                    <Box>
                      <Heading as='h4' size='xs'>
                        Goal:
                      </Heading>
                      <Text>{data?.lesson?.goal}</Text>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
                {(isMine || (sharePermissions && sharePermissions.includes('r'))) && 
                    (
                <AccordionItem
                    bg='#f3f1f1'
                >

                  <div  id='reflections'>
                  <h2>
                    <AccordionButton>
                      <AccordionIcon />
                      <Box as='span' flex='1' textAlign='left'>
                        Reflections
                      </Box>
                    </AccordionButton>
                  </h2>
                  </div>
                    <AccordionPanel pb={4}>
                      <Box mb={4}>
                        {data?.lesson?.reflections?.map((item) => (
                          <Reflection data={item} key={item?._id} lessonId={lessonId} />
                        ))}
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
                    )}
              </Accordion>
            </Box>


        <VStack>
          {/* <FormControl id='acitvity-filter' maxW='500px'>
            <FormLabel>Activities to Show</FormLabel>
            <Select
              options={filteredActivityOptions}
              isClearable
              isMulti
              onChange={handleFilter}
              value={activityFilter}
            />
          </FormControl> */}
          <CommentContainer
            comments={data?.comments}
            isLoading={isLoading}
            lessonId={lessonId}
            userId={user?._id}
          />
        </VStack>
      </HStack>
    </Box>
    </>
  );
};
Lesson.propTypes = {};
export default Lesson;
