import { useState, useEffect } from 'react';
import {
  Drawer,
  DrawerCloseButton,
  DrawerOverlay,
  DrawerBody,
  DrawerFooter,
  DrawerContent,
  DrawerHeader,
  Button,
} from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import Form from '../../../components/forms/Form';
import { FormInput, FormSelect } from '../../../components/forms/FormElements';
import { US_STATES } from '../../../utlis/US_STATES';
import InstituteForm from './InstituteForm';
import DistrictForm from './DistrictForm';
import SchoolForm from './SchoolForm';

const CreateOrgDrawer = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const { createType, onClose, isEdit } =  props;
  const type = createType; //  || searchParams.get('type');
  const lowerType = type ? type.toLowerCase() : '';
  // const [isOpen, setIsOpen] = useState(type);
  const capitalizedType = type ? `${type?.charAt(0).toUpperCase()}${type?.slice(1)}` : '';
  const handleClose = () => {
    setSearchParams({});
    onClose();
  };
  useEffect(() => {
    setIsOpen(!!type)
  },[type])

  return (
    <Drawer isOpen={(type && !isEdit)} onClose={handleClose} closeOnOverlayClick={false} closeOnEsc={false}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerHeader>Create {capitalizedType === 'Institute' ? 'Teacher Preperation Institution' : capitalizedType}</DrawerHeader>
          <DrawerBody>
            {type === 'Institute' && <InstituteForm handleClose={ handleClose}/>}
            {type === 'District' && <DistrictForm handleClose={handleClose}/>}
            {type === 'School' && <SchoolForm handleClose={handleClose}/>}
          </DrawerBody>
          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='uva' type='submit' form={`create-${lowerType}`}>
              Create {capitalizedType === 'Institute' ? 'Institution' : capitalizedType}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
export default CreateOrgDrawer;
