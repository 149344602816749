import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Stack,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Select,
} from '@chakra-ui/react';

import { AddIcon } from '@chakra-ui/icons';
import { createColumnHelper } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';
import { LinkComp } from '../../components';
import { CreateOrgDrawer } from './components';
import { useFilterOrganizations, useDeleteOrganization } from './components/orgHooks';
import { DataTable } from '../../components/Tables';
import { useUser } from '../../store/useAppStore';
import PageTitle from '../../components/PageTitle/PageTitle';
import EditOrgDrawer from './components/EditOrgDrawer';
import { set } from 'react-hook-form';

const capitalizeFirstLetter = (string) => string?.charAt(0).toUpperCase() + string?.slice(1) || '';



const createTable = (navigate, isAdmin) => {
  console.log(isAdmin)
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('name', {
      cell: (data) => data.getValue(),
      header: 'Name',
    }),
    columnHelper.accessor('type', {
      cell: (data) => capitalizeFirstLetter(data.getValue()),
      header: 'Type',
    }),
  ];

  return columns;
};

const AdminOrganizations = () => {
  const user = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tableData, setTableData] = useState([]);
  const [filterType, setFilterType] = useState('');
  const { data: organizations } = useFilterOrganizations({});
  const { mutate: deleteOrg } = useDeleteOrganization();
  const [createType, setCreateType] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [confimrationModal, setConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const columns = createTable(navigate, user.role.includes('admin'));

  const handleDelete = async () => {
    const orgId = searchParams.get('id');
    deleteOrg(orgId)
    // console.log('delete', orgId);
    setConfirmationModal(false);
    setOpenEdit(false);
  };

  useEffect(() => {
    if(organizations){
      if (filterType === '') {
        setTableData(organizations.map((org) => { 
  
          return {
            ...org,
            type: org.type == 'institute' ? 'institution' : org.type
          }
        }
        ));
      } else {
        setTableData(organizations.filter((org) => org.type === filterType).map((org) => {
          return {
            ...org,
            type: org.type == 'institute' ? 'institution' : org.type
          }
        }
        ));
      }
    }
  },[organizations])

  const filterData = (type) => {
    setFilterType(type);
    if (type === '') {
      setTableData(organizations.map((org) => { 

        return {
          ...org,
          type: org.type == 'institute' ? 'institution' : org.type
        }
      }
      ));
    } else {
      setTableData(organizations.filter((org) => org.type === type).map((org) => {
        return {
          ...org,
          type: org.type == 'institute' ? 'institution' : org.type
        }
      }
      ));
    }
  };


  useEffect(() => {
    if (searchParams.get('id')) {
      setOrgName(organizations.find((org) => org._id === searchParams.get('id'))?.name);
    }
  },[confimrationModal, searchParams, organizations])

  return (
    <>
      <PageTitle title='Manage Organizations'>
        <HStack>
        {/* <Text>Create: </Text>  */}
        <Button variant='secondary' size='xs' leftIcon={<AddIcon />} onClick={() => setCreateType('Institute')} >
          {' '}
          Insitiution
        </Button>
        <Button variant='secondary' size='xs' leftIcon={<AddIcon />} onClick={() => setCreateType('District')}>
          {' '}
          District
        </Button>
        <Button variant='secondary' size='xs' leftIcon={<AddIcon />} onClick={() => setCreateType('School')}>
          {' '}
          School
        </Button>
        <Button variant='secondary' size='xs' leftIcon={<AddIcon />} onClick={() => navigate('/organizations/class')}>
          {' '}
          Class
        </Button>
        </HStack>
        </PageTitle>
        <Text fontSize='xs' pl={6} pb={6}>Click the organization name to edit or delete. Click the column header to change the sort.</Text>
      <Select 
        placeholder='Filter By...' 
        onChange={(e) => filterData(e.target.value)} 
        size='sm'
        w='10%'
        fontSize='sm'
        alignSelf='flex-start'
        pl={6}
        >
        <option value=''>All</option>
        <option value='institute'>Institution</option>
        <option value='district'>District</option>
        <option value='school'>School</option>
        <option value='class'>Class</option>
      </Select>
      <DataTable columns={columns} data={tableData || []} customPageSize={25} defaultSort='name' setOpenEdit={setOpenEdit}/>
      <CreateOrgDrawer createType={createType} onClose={() => setCreateType(false)} isEdit={openEdit}/>
      <EditOrgDrawer isOpen={openEdit} onClose={() => setOpenEdit(false)} orgs={organizations} openConfirmation={() => setConfirmationModal(true)}/>
      <Modal isOpen={confimrationModal} onClose={() => setConfirmationModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Text pt={6}>Are you sure you want to delete {orgName}? This cannot be undone. This could impact who has access to Lessons.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={() => handleDelete()}>
              Delete
            </Button>
            <Button variant='ghost' onClick={() => setConfirmationModal(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

AdminOrganizations.propTypes = {};
export default AdminOrganizations;
