import { useUser } from '../../store/useAppStore';

const TEACHER_NAV_ITEMS = [
  {
    label: 'Dashboard',
    href: '/',
  },
  {
    label: 'My Lessons',
    href: '/my-lessons',
  },
  {
    label: 'My Classes',
    href: '/my-classes',
  },
  {
    label: 'Comparisons',
    href: '/comparisons',
  },
  {
    label: 'Shared with Me',
    href: '/shared',
  },
];
const SUPPORT_NAV_ITEMS = [
  {
    label: 'Dashboard',
    href: '/',
  },
    {
    label: 'Video Pipeline',
    href: '/pipeline',
  },
  {
    label: 'Shared with Me',
    href: '/shared',
  },
  {
    label: 'Comparisons',
    href: '/comparisons',
  },
];
const RESEARCHER_NAV_ITEMS = [
  {
    label: 'Dashboard',
    href: '/',
  },
  {
    label: 'Shared with Me',
    href: '/shared',
  },
  {
    label: 'Comparisons',
    href: '/comparisons',
  },
  {
    label: 'Lessons',
    href: '/list-lessons',
  },
  // {
  //   label: 'Video Pipeline',
  //   href: '/pipeline',
  // },
];
const ADMIN_NAV_ITEMS = [
  {
    label: 'Dashboard',
    href: '/',
  },
  {
    label: 'Comparisons',
    href: '/comparisons',
  },
  // {
  //   label: 'Video Pipeline',
  //   href: '/pipeline',
  // },
  {
    label: 'Lessons',
    href: '/list-lessons',
  },
  {
    label: 'Manage Users',
    subLabel: 'Add/edit users',
    href: '/users',
  },
  {
    label: 'Manage Organizations',
    subLabel: 'Add/edit classes, schools, etc',
    href: '/organizations',
  },
  // {
  //   label: 'Admin Tools',
  //   children: [
  //     {
  //       label: 'Manage Users',
  //       subLabel: 'Add/edit users',
  //       href: '/users',
  //     },
  //     {
  //       label: 'Manage Organizations',
  //       subLabel: 'Add/edit classes, schools, etc',
  //       href: '/organizations',
  //     },
];

/**
 * Returns an array of navigation items based on the user's role.
 * @returns {Array} An array of navigation items.
 */
// eslint-disable-next-line import/prefer-default-export
export const useNavItems = () => {
  const user = useUser();
  if (!user) return [];
  const roles = user.role; // ARRAY
  const isTeacher = roles.includes('teacher') || roles.includes('practicing-teacher');
  const isAdmin =
    roles.includes('admin') || roles.includes('developer') || roles.includes('stakeholder');
  const isResearcher = roles.includes('researcher');
  if (isTeacher) return TEACHER_NAV_ITEMS;
  if (isAdmin) return ADMIN_NAV_ITEMS;
  if (isResearcher) return RESEARCHER_NAV_ITEMS;
  return SUPPORT_NAV_ITEMS;
};
