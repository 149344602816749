import { useState, useEffect } from "react";
import moment from "moment";
import {
  Stack,
  HStack,
  Button,
  Select,
  ScaleFade,
  Input,
  VStack,
  useColorMode
} from "@chakra-ui/react";
import { AddIcon } from '@chakra-ui/icons';

import { useListTeacherLessons } from "../Lesson/components/lessonHooks";
import { useGetOrganizationsByType } from "../AdminOrganizations/components/orgHooks";
import { DataTable } from "../../components/Tables";
import { 
  createColumnHelper 
} from "@tanstack/react-table";
import { useUser } from "../../store/useAppStore";
import { useNavigate } from "react-router";
import PageTitle from "../../components/PageTitle/PageTitle";


const headCells = [
  // note: sorting not allowed on these rows
  // these are the lesson subRows for a class
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Lesson Name',
  },
  {
    id: 'lessonDate',
    numeric: false,
    disablePadding: false,
    label: 'Lesson Date',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'goal',
    numeric: false,
    disablePadding: false,
    searchable: false,
    label: 'Goal',
  },
  {
    id: 'summary', 
    numeric: false,
    disablePadding: false,
    searchable: false,
    label: 'Summary',
  },
];

const MyClassesLessons = (props) => {
  const { asPanel, subdata } = props;
  const [tableRows, setTableRows] = useState([]);  
  const {colorMode} = useColorMode();

  const user = useUser();
  const navigate = useNavigate();
  const viewLessonUrl = `/organizations/class${asPanel ? '?from=dashboard' : ''}`

  useEffect(() => {
    if (subdata) {
      setTableRows(subdata);
    }
  },[subdata])


  const columnHelper = createColumnHelper();
  const columns = headCells.map((el) => {
    if (el.link) {
      return columnHelper.accessor(el.id, {
        cell: (info) => (
          <>
          <VStack alignItems='flex-start'>
            <HStack alignItems='flex-start' gap={8}>
                <a onClick={() => redirectToLesson(info.getValue())} className="text-blue-600 underline hover:underline hover:text-blue-900">
                  View Analysis
                </a>
              </HStack>
            </VStack>
            <VStack alignItems='flex-start' spacing={'0px'}>
              <HStack gap={14}>
                <a onClick={() => redirectToLesson(info.getValue())} className="text-blue-600 underline hover:underline hover:text-blue-900">
                  Share Lesson
                </a>
                <a onClick={() => alert('Not implemented yet')} className="text-blue-600 underline hover:underline hover:text-blue-900">
                  Re-upload Video
                </a>
              </HStack>
          </VStack>
          </>
        ),
        header: el.label,
        disableNavigation: el.disableNavigation,
        enableSorting: el.enableSorting,

        size:'auto',
      });
    }
    // if (el.id === 'name') {
    //   return columnHelper.accessor(el.id, {
    //     cell: (info) => info.getValue(),
    //     header: el.label,
    // });
    // }
    return columnHelper.accessor(el.id, {
      cell: (info) => info.getValue() || null,
      header: el.label,

      size:'auto',
    });
  });
  return (
      <DataTable columns={columns} data={tableRows} defaultSort='lessonDate'  isSubRow subTitle='LESSONS' />

  );
};

export default MyClassesLessons;
