import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogOverlay,
    IconButton,
    useDisclosure,
    AlertDialogContent,
    AlertDialogHeader,
    Button,
  } from '@chakra-ui/react';
  import PropTypes from 'prop-types';
  import { useRef, useState } from 'react';
  import { useDeleteLesson } from '../../Lesson/components/lessonHooks';
  import trackEvent from '../../../utlis/trackEvents';
  import { useUser } from '../../../store/useAppStore';
  
  export const DeleteLessonConfirmation = ({ lessonId }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const deleteLesson = useDeleteLesson();
    console.log(deleteLesson)
    const user = useUser();
    const handleDelete = () => {
      trackEvent({
        page: 'Lesson',
        params: '',
        event: 'Delete Lesson',
        me: user,
        description: `Lesson ${lessonId}`,
        value: 0,
      });
      deleteLesson.mutate(lessonId);
      onClose();
    };

    return (
        <>
        <Button size='xs' colorScheme='red' onClick={() => onOpen()}> Delete Lesson </Button>
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Delete Lesson
              </AlertDialogHeader>
              <AlertDialogBody>Are you sure? This can&apos;t be undone.</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='red' onClick={handleDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        </>
    );
  };
  DeleteLessonConfirmation.propTypes = {
    lessonId: PropTypes.string.isRequired,
  };
  export default DeleteLessonConfirmation;
  