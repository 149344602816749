import { useState, useMemo, useEffect } from "react";
import moment from "moment";
import {
  Stack,
  HStack,
  Text,
  Select,
  ScaleFade,
  Input,
  VStack,
  useColorMode,
  Box,
  Button,
} from "@chakra-ui/react";
import { useListTeacherLessons } from "../Lesson/components/lessonHooks";
import CommentDisplay from "../../components/Comments/CommentDisplay";
import { DataTable } from "../../components/Tables";
import { createColumnHelper } from "@tanstack/react-table";
import { useUser } from "../../store/useAppStore";
import { useNavigate } from "react-router";
import PageTitle from "../../components/PageTitle/PageTitle";
import trackEvent from "../../utlis/trackEvents";
import DeleteLessonConfirmation  from "./components/DeleteLessonConfirmation";

const capitalizeFirstLetter = (string) => string?.charAt(0).toUpperCase() + string?.slice(1) || '';

const headCells = [
  // note: ID is the name used for sorting
  {
    id: 'lessonDetails',
    numeric: false,
    disablePadding: true,
    label: 'Lesson',
  },
  {
    id: 'lessonDate',
    numeric: false,
    disablePadding: false,
    label: 'Lesson Date',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    searchable: false,
    label: 'Status',
  },
  {
    id: 'subject',
    numeric: false,
    disablePadding: false,
    label: 'Subject',
  },
  {
    id: 'period',
    numeric: false,
    disablePadding: false,
    label: 'Period',
  },
  {
    id: '_id',
    numeric: false,
    disablePadding: false,
    label: 'Take Action',
    searchable: false,
    link: 'lesson',
    disableNavigation: true,
    enableSorting: false,
  },
  {
    id: 'sharedWithNames',
    numeric: false,
    disablePadding: false,
    label: 'Shared With',
  },
];

const MyLessons = (props) => {
  const [tableRows, setTableRows] = useState([]);
  const { isTeacher } = props;
  const [filterBy, setFilterBy] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteLessonId, setDeleteLessonId] = useState('');
  const {colorMode} = useColorMode();
  const user = useUser();
  const navigate = useNavigate();

  const redirectToLesson = (id) => {
    trackEvent({
      page: 'MyLessons',
      params: '',
      event: 'View Analysis',
      me: user,
      description: 'View Analysis',
      details: `lessonId: ${id}`,
      value: 0,
    });
    navigate(`/lesson/${id}`);
  };

  const redirectToUpload = (id) => {
    trackEvent({
      page: 'MyLessons',
      params: '',
      event: 'Re-upload Video',
      me: user,
      description: 'Re-upload Video',
      details: `lessonId: ${id}`,
      value: 0,
    });
    navigate(`/lesson/create?id=${id}`);
  };

  useEffect(() => {
    if (!isTeacher && headCells[0].id !== 'teacher') {

      headCells.unshift(
        {
          id: 'teacher',
          numeric: false,
          disablePadding: true,
          label: 'Teacher',
        },
      )
    }
  }, [])

  const { data } = useListTeacherLessons();
  

  const handleChangeFilterBy = (value) => {
    setFilterBy(value);
  };

  const lookForEnter = (e) => {
    if (e.key === 'Enter') {
      const tableData = [...data]
      // console.log(tableData)
      console.log('lesson filter: ', filterBy, filterQuery)
      setTableRows(tableData.filter((row) => {
       //  console.log(row, filterBy, filterQuery)
        switch (filterBy) {
          case 'teacher': 
            const name = row.teacher ? row.teacher.toLowerCase() : '-';
            return name.includes(filterQuery.toLowerCase());
            break;
          case 'lessonDetails':
            const lname = row.lessonDetails.name ? row.lessonDetails.name.toLowerCase() : '--';
            return lname.includes(filterQuery.toLowerCase());
          case 'sharedWithNames':
            return row.sharedWithNames?.filter((shared) =>  shared?.toLowerCase().includes(filterQuery.toLowerCase()) || false )?.length > 0;
          case 'lessonDate':
            return moment(row.created).format('MM/DD/YYYY').includes(filterQuery);
          default:
            return row[filterBy]?.includes(filterQuery.toLowerCase());
        }
      }
      ))
    }
  };
  const getFilterDisplayName = (filterBy) => {
    const filter = headCells.find((h) => h.id === filterBy);
    return filter ? filter.label : '';
  };


  useEffect(() => {
    setTableRows(data || [])
  }, [data]);
  
  const handleChangeFilterQuery = (value) => {
    setFilterQuery(value);
  };


  const columnHelper = createColumnHelper();
  const columns = headCells.map((el) => {
    if (el.link) {
      return columnHelper.accessor(el.id, {
        cell: (info) => (
          <>
             <VStack alignItems='flex-start' spacing={'0px'}>
                <Button size='xs' w='115px' mb={2} variant={'secondary'} onClick={() => redirectToLesson(info.getValue())}>
                  View Analysis
                </Button>
                <Button size='xs' variant='secondary' mb={2} onClick={() => redirectToUpload(info.getValue())}>
                 Re-upload Video
                </Button>
                <DeleteLessonConfirmation lessonId={info.getValue()}/>
            </VStack>
          </>
        ),
        header: el.label,
        disableNavigation: el.disableNavigation,
        enableSorting: el.enableSorting,
      });
    }
    if (el.id === 'lessonDate') {
      return columnHelper.accessor(el.id, {
        cell: (info) => (!info.getValue() ? '' : moment(info.getValue()).format('M/D/YYYY')),
        header: el.label,
        sortDescFirst: true,
       // sortingFn: 'datetime'
       sortingFn: (rowA, rowB) => {
        // const emailA = rowA.original.teacherData[0].email;
        // const emailB = rowB.original.teacherData[0].email;
        // return emailA?.localeCompare(emailB);
        const dateA = rowA.original.created; 
        const dateB = rowB.original.created; 
        return dateA?.localeCompare(dateB);

     }
      });
    }
    if (el.id === 'teacherData') {
      return columnHelper.accessor(el.id, {
        cell: (info) => {
          const teacher = info.getValue();
          // const firstName = teacher?.firstName || '';
          // const lastName = teacher?.lastName || '';
          return teacher?.email || '';
        },
        header: el.label,
      });
    }
    if (el.id === 'lessonDetails') {
      return columnHelper.accessor(el.id, {
        cell: (info) => {
          const lesson =info.getValue();
          const name = lesson?.name || '';
          const lessonDate = lesson?.lessonDate || '';
          const term = lesson?.term || '';
          // console.log(lesson)
          return `${name}`;
        },
        header: el.label,
        sortDescFirst: false,
        sortingFn: (rowA, rowB) => {
          const lessonA = rowA.original.lessonDetails.name;
          const lessonB = rowB.original.lessonDetails.name;
          return lessonA?.localeCompare(lessonB);
      }
      });
    }
    if (el.id === 'sharedWithNames') {
      return columnHelper.accessor(el.id, {
        cell: (info) => {
          const sharedWith = info.getValue();
          //for each sharedWith, print on a new line
          return (
            <VStack alignItems='flex-start'>
              {sharedWith.map((sw) => (
                <Text key={sw}>{sw}</Text>
              ))}
            </VStack>
          );
        },
        header: el.label,
      });
    }
    if (el.id === 'subject'){
      return columnHelper.accessor(el.id, {
        cell: (info) => capitalizeFirstLetter(info.getValue()) || null,
        header: el.label,
        sortUndefined: false,
      });
    }
    return columnHelper.accessor(el.id, {
      cell: (info) => capitalizeFirstLetter(info.getValue()) || null,
      header: el.label,
    });
  });
  return (
    <>
      <PageTitle title={isTeacher ? 'My Lessons' : 'Lessons'} />
      <div className='mt-0 pl-6 pt-2'>
        <Stack
          direction='row'
          // className='-mt-8 mb-4 whitespace-nowrap'
          // justifyContent='space-between'
          pl={6}
        >
          <HStack direction='row' flexWrap='wrap' gap='2' w={'full'}>
            <div className='w-44 pl-0'>
              <Select
                id='filter-by'
                fontSize='sm'
                value={filterBy}
                onChange={(e) => handleChangeFilterBy(e.target.value)}
                size='sm'
                // style={{border: 'none', background: '#222327', borderRadius: 5}}
                className='darkSelect'
              >
                <option value='' key='nothing-selected'>
                  Search by...
                </option>
                {headCells
                  .filter((h) => h.searchable !== false)
                  .map((h) => (
                    <option value={h.id} key={h.id}>
                      {h.label}
                    </option>
                  ))}
              </Select>
            </div>

            <ScaleFade initialScale={0.9} in={filterBy}>
              <Input
                //  variant="flushed"
                placeholder={`${getFilterDisplayName(filterBy)}`}
                variant='filled'
                style={{
                  background: colorMode === 'dark' ? '#222327' : '',
                  borderRadius: 5,
                  border: '2px',
                }}
                fontSize='sm'
                minWidth='240px'
                size='sm'
                id='filter-query'
                value={filterQuery}
                onChange={(e) => handleChangeFilterQuery(e.target.value)}
                onKeyDown={lookForEnter}
              />
            </ScaleFade>
          </HStack>
        </Stack>
        <HStack alignItems='flex-start'>
          <Box w={isTeacher ? '70%' : 'full'}>
             <DataTable columns={columns} data={tableRows} />
          </Box>
          {isTeacher && (
            <Box minWidth={350} maxW={450}>
              <CommentDisplay />
            </Box>
          )}
        </HStack>
      </div>
    </>
    
  );
};

export default MyLessons;
