import { z } from 'zod';

const specialCharacters = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '+', '=', '{', '}', '[', ']', '|', '\\', ':', ';', '"', "'", '<', '>', ',', '.', '?', '/']

export const CreateLessonSchema = z.object({
  name: z.string()
  .min(4, 'Please include a lesson name')
  .refine(
     s => {
       console.log('Validating name:', s); // Debugging output
       return!s.includes('/') && !specialCharacters.some(char => s.includes(char));
     },
     "The only special characters allowed are '-' and '_'"
   ),
  lessonDate: z.string()
  .min(1, ' Please select a date'),
  summary: z
    .string().min(3, 'Please include a summary')
    .refine(
      (val) => {
        const arr = val.split(' ');
        return arr.length < 300;
      },
      {
        message: 'Summary must be less than 300 words',
      },
    ).optional(),
  goal: z
    .string()
    .min(3, 'Please include a gaol')
    .refine(
      (val) => {
        const arr = val.split(' ');
        return arr.length < 300;
      },
      {
        message: 'Goal must be less than 300 words',
      },
    ).optional(),
  videoKey: z.string().min(1, ' Please upload a video before submitting'),
  class: z.object({
    value: z.string().min(1, 'Please select a class'),
  }).transform((data) => data.value),
  teacher: z.object({
    value: z.string().optional(),
  }).transform((data) => data.value).optional(),
});
