import React, { useState, useMemo } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  IconButton,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  TableContainer,
  FormHelperText,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  TriangleDownIcon,
  TriangleUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  //   ColumnDef,
  //   SortingState,
  getSortedRowModel,
  getPaginationRowModel,

  getExpandedRowModel,
} from "@tanstack/react-table";
import { useNavigate, useSearchParams } from "react-router-dom";

const DataTable = ({ data=[], columns, customPageSize, hidePagination, noRedirect=false, defaultSort=null, setOpenEdit, onClickAction, SubTable, isSubRow, subTitle }) => {
  const {colorMode} = useColorMode();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sorting, setSorting] = useState([{ id: defaultSort || "lessonDetails", desc: false }]);
  const isAdminOrg = useMemo(() => window.location.pathname.includes('organizations'),[]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: customPageSize || 10,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    columns,
    data,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination,
    },
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
    getRowCanExpand: getExpandedRowModel(),
    // sortingFns: dateSort,
  });

  const navigate = useNavigate();

  const redirectClick = (rowData, index) => {

    if (noRedirect) return;

    const ch = columns[index];
    if (ch.disableNavigation) {
      return;
    }

    if (onClickAction) {
      onClickAction(rowData);
      return;
    }

    if (isAdminOrg && rowData.type === 'class') navigate(`/organizations/edit?id=${rowData._id}`);
    else if (isAdminOrg) {
      const capitalizedType = rowData.type.charAt(0).toUpperCase() + rowData.type.slice(1);
      setOpenEdit(true)
      setSearchParams({id: rowData._id, type: capitalizedType})
    }
    else navigate(`/lesson/${rowData._id}`)
  }

  const dumpRow = (row, index) => {
    // if (index === 0 && row.original.name) {
    //   console.log('row', row);
    //   console.log('can expand', row.canExpand);
    //   console.log('expanded', row.getIsExpanded());
    // }
  }

  const renderTable = () => (
    <TableContainer>
      {!isSubRow && (
        <Text fontSize='xs' ml={1} mb={2}>To sort, click on a column header.</Text>
      )}
    <Table colorScheme={"whiteAlpha"}>
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id} style={{borderBottom: '1px solid green'}}>
            {headerGroup.headers.map((header) => {
              const {meta} = header.column.columnDef;
              return (
                <Th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  isNumeric={meta?.isNumeric}
                  padding="5px"
                  color={colorMode === 'dark' ? "gray.300" : "black"}
                  style={{borderBottom: '1px solid gray', maxWidth: 50, fontSize: 14, fontWeight: 500}}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}

                  <chakra.span pl="2">
                    {header.column.getIsSorted() ? (
                      header.column.getIsSorted() === "desc" ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row, index) => (
          <>
          {/* {dumpRow(row, index)} */}
          <Tr
            key={row.original._id}
            h="51px"
            _hover={{ backgroundColor: colorMode === 'dark' ? "#3e4047" : "#eeeef4", cursor: "pointer" }}
          >
            {row.getVisibleCells().map((cell, index) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const {meta} = cell.column.columnDef;
              return (
                <Td
                  key={cell.id}
                  isNumeric={meta?.isNumeric}
                  padding="5px"
                  fontSize={12}
                  fontWeight={cell.column.id === "videoName" ? "bold" : "normal"}
                  onClick={() => redirectClick(row.original, index)}
                  style={{borderBottom: '1px solid #ddd', maxWidth: 50, whiteSpace: 'normal', verticalAlign: 'top', fontSize: 14}}
                  
                >
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  )}
                </Td>
              );
            })}
          </Tr>
            {row.getIsExpanded() && (
              <SubTable subdata={row.original.subRows} />
            )}
          </>
        ))}
      </Tbody>
    </Table>
  </TableContainer>
  )

  if (isSubRow) {
    return (
      <Box
      // p='24px'
      // pl='24px'
      pt="4px"
      // m={['0px', '24px']}
      // mt={['0px', '12px']}
      // borderWidth='1px'
      // borderRadius='lg'
      // overflow='hidden'
      // className='border-zinc-600'
      // borderColor= '#ccccd8'
      backgroundColor='#efeeee'
      border={'1px solid #8d8da688'}
      borderRadius={4}
    >
      {subTitle && <Text mt={2} mb={2} ml={1} fontSize='sm' fontWeight={'bold'}>{subTitle}</Text>}
         {renderTable()}
    </Box>
    )
  }

  return (
    <Box
      p='24px'
      m={['0px', '24px']}
      mt={['0px', '12px']}
      borderWidth='1px'
      borderRadius='lg'
      overflow='hidden'
      className='border-zinc-600'
      borderColor= '#ccccd8'
    >
      {renderTable()}
      {/* THIS NEEDS TO BE MADE RESPONSIVE FOR SMALLER SCREENS WRAP LINES */}
      {!hidePagination && data.length > pageSize && (
      <div className="flex items-end justify-center my-4 gap-4 ">
        <Stack direction="row">
          <IconButton
            colorScheme={colorMode === 'dark' ? "whiteAlpha" : 'blackAlpha'}
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            aria-label="First Page"
            size="sm"
            icon={<ArrowLeftIcon />}
          />
          <IconButton
            colorScheme={colorMode === 'dark' ? "whiteAlpha" : 'blackAlpha'}
            onClick={() => table.previousPage()}
            aria-label="Previous Page"
            size="sm"
            icon={<ChevronLeftIcon />}
          />
          <div className="flex items-center gap-1">
            <Text as="div" className="whitespace-nowrap">
              Page&nbsp;
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </Text>
          </div>
          <IconButton
            colorScheme={colorMode === 'dark' ? "whiteAlpha" : 'blackAlpha'}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            aria-label="Next Page"
            size="sm"
            icon={<ChevronRightIcon />}
          />
          <IconButton
            colorScheme={colorMode === 'dark' ? "whiteAlpha" : 'blackAlpha'}
            size="sm"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
            aria-label="Last Page"
            icon={<ArrowRightIcon />}
          />
        </Stack>

        {/* <FormControl width="auto">
          <FormLabel fontSize="sm">Go to page:</FormLabel>
          <Input
            type="number"
            size="sm"
            width="auto"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
        </FormControl> */}
        <FormControl width="auto">
          {/* <FormLabel fontSize="sm">Rows per Page</FormLabel> */}
          <Select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            colorScheme={colorMode === 'dark' ? "whiteAlpha" : 'blackAlpha'}
            size="sm"
            width="auto"
            className='darkSelect'
          >
            {[10, 25, 50, 100, 200].map((pageSize) => (
              <chakra.option key={pageSize} value={pageSize}>
                {pageSize} rows per page
              </chakra.option>
            ))}
          </Select>
        </FormControl>
      </div>
      )}
    </Box>
  );
};

export default DataTable;