import React, { useState, useEffect } from 'react';

import { Text, HStack, Box, Select, Button, IconButton,
  Modal, ModalBody, ModalContent, ModalOverlay, ModalHeader, ModalFooter, ModalCloseButton, Input,
  useColorModeValue, Tooltip, Textarea, VStack,
} from '@chakra-ui/react';

import { EditIcon } from '@chakra-ui/icons';
import { useUpdateLesson } from './lessonHooks';

function UpdateLesson(props) {
  const { currentLesson, refetch } = props;

  const [updateOpen, setUpdateOpen] = useState(false);
  const [lessonValid, setLessonValid] = useState(true);
  const [errorMessageSummary, setErrorMessageSummary] = useState('');
  const [errorMessageGoal, setErrorMessageGoal] = useState('');
  const [goal, setGoal] = useState('');
  const [summary, setSummary] = useState('');

  const updateLesson = useUpdateLesson();


  useEffect(() => {
    setErrorMessageSummary('');
    setErrorMessageGoal('');
    if (updateOpen === true) {
      const c = currentLesson;
      setGoal(currentLesson.goal);
      setSummary(currentLesson.summary);
      setLessonValid(true);
    }
  }, [updateOpen])

  useEffect(() => {
    const valid = summary.length > 2 && goal.length > 2 && summary.length < 300 && goal.length < 300;
    setLessonValid(valid);
    if (!valid) {
      if (summary.length < 2 || summary.length > 299) {
        setErrorMessageSummary('The summary is required and must be between 3 and 300 characters.');
      }
      if (goal.length < 2 || goal.length > 299) {
        setErrorMessageGoal('A goal is required and must be between 3 and 300 characters.');
      }
    } else {
      setErrorMessageSummary('');
      setErrorMessageGoal('');
    }
  }, [summary, goal])

  const handleChange = event => {
    const newValue = event.target.value;
    const id = event.target.id;
    if (id === 'summary') {
      setSummary(newValue);
    } else {
      setGoal(newValue);
    }
  }

  const doUpdate = () => {
    const updateData = {
      _id: currentLesson._id,
      goal,
      summary,
    }
    updateLesson.mutate(
      { updateData  },
      { onSuccess: () => { 
        refetch()
      }, 
        onError: (err) => {alert(err)} },
    );
    setUpdateOpen(false);
  }
 
  return (
    <>
         <Tooltip label='Update This Lesson'>
        {/* <Button
          // variant="ghost"
          size='md'
         // color={useColorModeValue('primary.900', 'primary.900')}
          onClick={() => setUpdateOpen(true)}
          ml={2}
          p={0}
          >
          <EditIcon className="h-6 w-6" color={useColorModeValue('secondary.200', 'secondary.400')} />
        </Button> */}
        <IconButton
                        size="xs"
                        onClick={() => setUpdateOpen(true)}
                        aria-label="Edit Lesson Details"
                        icon={<EditIcon w={5} h={5} />}
                      />
        </Tooltip>

      <Modal isOpen={updateOpen} size='xl'>
        <ModalOverlay />
        <ModalContent m={48}>
          <ModalHeader>
            <Text>Update this Lesson</Text>
          </ModalHeader>
          <ModalCloseButton onClick={() => setUpdateOpen(false)} />
          <ModalBody>
            <VStack >
              <Box w='95%'>
                <Text mb='8px'>Summary:</Text>
                <Textarea
                  id="summary"
                  value={summary}
                  onChange={handleChange}
                  placeholder='Enter the summary for this lesson'
                />
                {errorMessageSummary && (
                  <Text as='i' fontSize='xs' color='#ff0000' ml={3}>{errorMessageSummary}</Text>
                )}
              </Box>
              <Box w='95%' mt='6' mb='6'>
                <Text mb='8px'>Goal:</Text>
                <Textarea
                  id='goal'
                  value={goal}
                  onChange={handleChange}
                  placeholder='Enter the goal for this lesson'
                />
                {errorMessageGoal && (
                  <Text as='i' fontSize='xs' color='#ff0000' ml={3}>{errorMessageGoal}</Text>
                )}
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={() => setUpdateOpen(false)}>
              Cancel
            </Button>
            <Button
              variant='secondary'
              isDisabled={!lessonValid}
              onClick={doUpdate}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  );
}

export default UpdateLesson;