import { create } from 'zustand';
import { persist } from 'zustand/middleware';

let store = (set) => ({
    contactList: [],
    contactBookOpen: false,
    actions: {
      addToList: (user, currentList) => set(() => ({contactList: [...currentList, user]})),
      removeFromList: (user, currentList) => {
        console.log(user)
        return set(() => ({ contactList: currentList.filter((contact) => contact._id !== user) }))
      },
      setContactList: (list) => set(() => ({ contactList: list })),
      toggleContactBook: () => set((state) => ({ contactBookOpen: !state.contactBookOpen })),
    },
});
store = persist(store, {
  name: 'contact-list',
  partialize: ({ actions, ...rest }) => rest,
});
const useContactStore = create(store);

export default useContactStore;

export const useContactBookOpen = () => useContactStore((state) => state.contactBookOpen);
export const useContactList = () => useContactStore((state) => state.contactList);
export const useContactActions = () => useContactStore((state) => state.actions);
