import React from "react";

const FontAwesome6 = (props) => {
  const { icon, color='inherit', size='inherit' } = props;
  return (
    // eslint-disable-next-line react/jsx-no-comment-textnodes
    <span style={{fontSize: `${size}`, color: `${color}`}}>
    <i className={`fa-solid ${icon}`} />
  </span>
  );
};

export default FontAwesome6;
