import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const LinkComp = ({ to, label, ...rest }) => (
  <Link as={RouterLink} to={to} {...rest}>
    {label}
  </Link>
);
LinkComp.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
export default LinkComp;
