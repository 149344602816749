// eslint-disable-next-line import/no-extraneous-dependencies
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import VisitorLayout from '../layouts/VisitorLayout';
import AuthLayout from '../layouts/AuthLayout';
import { useIsAuthenticated, useAppActions, useUser } from '../store/useAppStore';
import { PrivateRoute } from '../components';
import SignIn from './SignIn';
import VisitorHome from './VisitorHome';
import About from './About';
import SignUp from './SignUp/SignUp';
import RecoverPW from './RecoverPW';
import Reset from './Reset';
import Lesson from './Lesson';
import AdminUsers from './AdminUsers';
import CreateOrganization from './CreateClass';
import EditClass from './EditClass';
import AdminOrganizations from './AdminOrganizations';
import CreateLesson from './CreateLesson';
import Account from './Account';
import Dashboard from './Dashboard';
import Comparisons from './Comparisons';
import Pipeline from './Pipeline';
import MyLessons from './MyLessons';
import Shared from './Shared';
import RestrictByRole from '../components/RestrictByRole';
import Restricted from './Restricted';
import MyClasses from './MyClasses';
import NotFound from './NotFound';
import { useEffect, useState } from 'react';
import trackEvent from '../utlis/trackEvents';

const Routes = () => {
  const isAuthenticated = useIsAuthenticated();
  const { signOut } = useAppActions();
  const user = useUser();
  const [currentPath, setCurrentPath] = useState({});

  useEffect(() => {
    const userLastLogin = user?.lastLogin;
    if (userLastLogin) {
      const lastLoginDateUTCDate = new Date (new Date(userLastLogin).toUTCString());
      const currentDateUTCDate = new Date(new Date().toUTCString());
      // the following gets the difference in hours
      const timeDifference = Math.abs(currentDateUTCDate - lastLoginDateUTCDate) / 36e5;
      if (timeDifference > 8){
        signOut();
      }
    }
  },[user, signOut])

  useEffect(() => {
    const l = window.location;
    if (l.pathname !== currentPath.pathname) {
      setCurrentPath(l);
    }
  }, [window.location]);

  useEffect(() => {
    const l = window.location;
    if (l.pathname !== currentPath.pathname) {
      // new page with new status
      setCurrentPath(l);
    } else {
      // same page, new authentication status
      trackEvent({
        page: l.pathname,
        params: l.search ? l.search.replace('?', '') : '',
        event: 'PageLoad',
        me: user,
      })
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if( 'pathname' in currentPath) {
    trackEvent({
      page: currentPath.pathname,
      params: currentPath.search ? currentPath.search.replace('?', '') : '',
      event: 'PageLoad',
      me: user,
    })
  }
  }, [currentPath.pathname])

  // Routes that auth doesn't matter
  const publicRoutes = [
    {
      path: '/',
      element: <VisitorLayout />,
      children: [{ path: '/about', element: <About /> }],
    },
  ];
  // Protected routes
  const authenticatedRoutes = [
    {
      path: '/',
      element: <PrivateRoute isAuthenticated={isAuthenticated} />,
      children: [
        {
          path: '/',
          element: <AuthLayout />,
          children: [
            {
              index: true,
              element: <Dashboard />,
            },
            {
              path: 'account',
              element: <Account />,
            },
            {
              path: 'comparisons',
              element: <Comparisons />,
            },
            {
              path: 'comparisons/:action',
              element: <Comparisons />,
            },
            {
              path: 'lesson/:lessonId',
              element: <Lesson />,
            },
            {
              path: 'lesson/:lessonId/:reflections',
              element: <Lesson />,
            },
            {
              path: 'lesson/create',
              element: <CreateLesson />,
            },
            {
              path: 'my-lessons',
              element: <MyLessons isTeacher />,
            },
            {
              path: 'list-lessons',
              element: <MyLessons />,
            },
            {
              path: 'my-classes',
              element: <MyClasses />,
            },
            {
              path: 'organizations',
              element: <RestrictByRole allowedRoles={['admin', 'developer', 'stakeholder']} />,
              children: [
                {
                  index: true,
                  element: <AdminOrganizations />,
                },
              ],
            },
            // {
            //   path: 'organizations/class',
            //   element: <RestrictByRole allowedRoles={['admin', 'developer', 'stakeholder']} />,
            //   children: [
            //     {
            //       index: true,
            //       element: <CreateOrganization />,
            //     },
            //   ],
            // },
            {
              path: 'organizations/class',
              element: <CreateOrganization />,
            },
            {
              path: 'organizations/edit',
              element: <RestrictByRole allowedRoles={['admin', 'developer', 'stakeholder']} />,
              children: [
                {
                  index: true,
                  element: <EditClass/>,
                },
              ],
            },
            {
              path: 'pipeline',
              element: (
                <RestrictByRole
                  allowedRoles={['admin', 'developer', 'stakeholder', 'researcher', 'support']}
                />
              ),
              children: [
                {
                  index: true,
                  element: <Pipeline />,
                },
              ],
            },
            {
              path: 'restricted',
              element: <Restricted />,
            },
            {
              path: 'shared',
              element: <Shared />,
            },
            {
              path: 'users',
              element: <RestrictByRole allowedRoles={['admin', 'developer', 'stakeholder']} />,
              children: [
                {
                  index: true,
                  element: <AdminUsers />,
                },
              ],
            },
            // THIS SHOULD TURN INTO A SIMPLE COMPONENT THAT SIGNS YOU OUR AND REDIRECTS TO HOME
            {
              path: 'logout',
              element: <div>Logout</div>,
            },
            {
              path: '/*',
              element: <NotFound />,
            },
          ],
        },
      ],
    },
  ];
  // Only visible if unauthenticated
  const nonAuthenticatedRoutes = [
    {
      path: '/',
      element: <VisitorLayout />,
      children: [
        {
          index: true,
          element: <VisitorHome />,
        },
        {
          path: '/signin',
          element: <SignIn />,
        },
        {
          path: '/signup',
          element: <SignUp />,
        },
        {
          path: '/recover',
          element: <RecoverPW />,
        },
        {
          path: '/reset',
          element: <Reset />,
        },
        {
          path: '/*',
          element: <NotFound />,
        },
      ],
    },
  ];

  const router = createBrowserRouter([
    ...publicRoutes,
    ...(!isAuthenticated ? nonAuthenticatedRoutes : []),
    ...authenticatedRoutes,
  ]);
  if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
  }
  return <RouterProvider router={router} />;
};
export default Routes;
