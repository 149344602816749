import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Button, Text, HStack, VStack, Wrap, WrapItem, Box, Select, Switch,
  Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverTrigger, PopoverContent, PopoverHeader,
  Modal, ModalBody, ModalContent, ModalOverlay, ModalHeader, ModalFooter, ModalCloseButton, Divider,
  IconButton, useColorModeValue, Tooltip
} from '@chakra-ui/react';
import moment from 'moment';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useUser } from '../../store/useAppStore';
import { useListCompares, useGetCompares, useGetCompareSaves } from './compareHooks';
import { ACTIVITY_TYPES, MAX_COMPARISONS } from '../../constants';

import CompareSelect from './CompareSelect';
import CompareSave from './CompareSave';
import { SmallGraph } from '../../components/SmallGraph';
import ActivityCompareGraph from '../../components/ActivityCompareGraph/ActivityCompareGraph';
import { getParameterByName } from '../../utlis/utils';

/*
  roles: [
  'administrator',
  'admin',
  'school-coach',
  'instructor',
  'coach',
  'candidate',
  'mentor',
  'practicing-teacher',
  'teacher-candidate',
  'teacher',
  'researcher'
]
*/

function Comparisons() {
  const user = useUser();
  const { action } = useParams();
  const roles = (Array.isArray(user.role)) ? user.role : [user.role];
  const joinedRoles = roles.join(', ');
  const isTeacher = joinedRoles.search('teacher') >= 0;
  const isAdmin = (joinedRoles.search('admin') >= 0) || roles.includes('researcher');

  const max = MAX_COMPARISONS;
  const [compareList, setCompareList] = useState([]);
  const [compareListToShow, setCompareListToShow] = useState([]);
  const [updates, setUpdates] = useState(0);
  const [graphs, setGraphs] = useState(0);
  const { data, error, refetch } = useListCompares(); // valid list of lessons to compare to one another
  const [comparableCount, setComparableCount] = useState(0);
  const [graphType, setGraphType] = useState('summary');
  const [activity, setActivity] = useState('a24');
  const [openSelectDialog, setOpenSelectDialog] = useState(false);
  const [openOpenSaved, setOpenOpenSaved] = useState(false);
  const [selectionValid, setSelectionValid] = useState(false);
  const [selectionStatuses, setSelectionStatuses] = useState([]);
  const { data: compareData, error: compareDataError, refetch: compareDataRefetch, isLoading } = useGetCompares(compareListToShow);
  const { data: comparesSaved, error: comparesSavedErrors, refetch: comparesSavedRefecth } = useGetCompareSaves();
  const [hasSaves, setHasSaves] = useState(false);
  const [savedName, setSavedName] = useState('');
  const [startingLesson, setStartingLesson] = useState('');

  if (compareData) {
    console.log('compare data', compareData)
  }

  const setSelection = (index, lessonId, action = 'add') => {
    const newList = compareList;
    const newStatus = selectionStatuses;
    if (action === 'add') {
      newList[index] = lessonId;
      newStatus[index] = 'complete';
    } else if (action === 'delete') {
      newList.splice(index, 1);
      newStatus.splice(index, 1);
    } else {
      // must be an incomplete row
      newStatus[index] = 'incomplete';
    }

    setSelectionStatuses(newStatus);
    let valid = true;
    if (newStatus.length < 2) {
      valid = false;
    } else {
      newStatus.forEach(s => {
        if (s !== 'complete') {
          valid = false;
        }
      })
    }
    setSelectionValid(valid);

    if (action === 'add' || action === 'delete') {
      setCompareList(newList);
      setUpdates(updates + 1)
    }

  }

  const updateCompareListToShow = () => {
    setOpenSelectDialog(false);
    const newList = compareList.map(i => (i));
    
    setCompareListToShow(newList);
  }

  const cancelSelectDialog = () => {
    const newList = compareListToShow.map(i => (i));
    setCompareList(newList);
    setOpenSelectDialog(false);
  }

  const onShowComparisons = () => {
    compareDataRefetch();
  }

  useEffect(() => {
    const lid = getParameterByName('lesson');
    if (lid) {
    setStartingLesson(lid);
    setOpenSelectDialog(true);
    setSelection(0, lid, 'add');
    }

  },[])

  useEffect(() => {
    if (data && data.lessons) {
      let count = 0;
      data.lessons.forEach(teacher => {
        if (teacher.lessons) {
          count += teacher.lessons.length;
        }
      })
      setComparableCount(count);
      debugger;
    }
  }, [data])

  useEffect(() => {
    if (action === 'OpenSaved') {
      setOpenOpenSaved(true);
    }
  }, [action])


  useEffect(() => {
    const cd = compareData;
    setUpdates(updates + 1)
    if (compareData)
      setGraphs(compareData.length)
  },
    [compareData]
  )


  const onChangeActivity = (event) => {
    setActivity(event.target.value)
  }

  const onChangeView = (event) => {
    const t = event.target.checked;
    setGraphType(t ? 'activity' : 'summary');
  }

  const openSavedComparison = (compare) => {
    setOpenOpenSaved(false);
    const newList = compare.lessons.map(i => (i));
    setCompareList([]);
    setCompareListToShow([]);
    setUpdates(updates + 1)

    setGraphType(compare.view);
    setCompareList(newList);
    setCompareListToShow(newList);
    setUpdates(updates + 1)
  }

  useEffect(() => {
    if (comparesSaved && comparesSaved.response  && comparesSaved.response.length > 0) {
      setHasSaves(true);
      const recent = comparesSaved.response[0];
      openSavedComparison(recent);
    }
  },
    [comparesSaved]
  )


  useEffect(() => {
    setSavedName('');
    if (hasSaves && compareListToShow && compareListToShow.length > 0) {
      comparesSaved.response.forEach(save => {
        let match = true;
        if (save.lessons && (save.lessons.length === compareListToShow.length)) {
          save.lessons.forEach(lesson => {
            if (!compareListToShow.includes(lesson)) {
              match = false;
            }
          })
          if (match) {
            setSavedName(save.name);
          }
        }
      })
    }
  }, [compareListToShow, comparesSaved, hasSaves]);

  const renderLessonInfo = (lessonId) => {
    let name;
    let date;
    let classInfo = 'Class information not available';
    let teacherName = '';
    let teacherId;
    compareData.forEach(l => {
      if (l._id === lessonId) {
        name = `${l.name}`;
        date = moment(l.lessonDate).format('M/D/YYYY');
        teacherName = l.teacherName;
        teacherId = l.teacherId;
        debugger;
        if (l.classroom) {
        classInfo = `${l.classroom.subject}, grade ${l.classroom.grade} ${l.classroom.period ? `, period ${l.classroom.period}` : ''}`;
        }
      }
    })
    return (
      <VStack w='100%' align='stretch' spacing={0}>
        <Text fontSize='md'>{name}</Text>
        {user._id !== teacherId && (<Text ml={2} fontSize='xs' as='i'>{teacherName}</Text>)}
        <Text ml={2} fontSize='xs' as='i'>Date: {date}</Text>
        <Text ml={2} fontSize='xs' as='i'>{classInfo}</Text>
      </VStack>
    )
  }

  const renderCompareListColumn = () => (
    <div style={{ display: 'inline-block', margin: 12, marginTop: 0, padding: 12, paddingRight: 24, borderRight: '1px solid #aaa', width: 270, minHeight: '80vh' }}>
      <Text fontWeight='semibold'>Comparing: </Text>
      <HStack mt={4} mb={4}>
        <Text color={graphType === 'summary' ? '#e57200' : 'gray'}>Activity Types</Text>
        <Switch
          isChecked={graphType === 'activity'}
          colorScheme='gray'
          size='sm'
          onChange={onChangeView}
        />
        <Text color={graphType === 'activity' ? '#e57200' : 'gray'}>By Activity</Text>
      </HStack>
      <VStack>
        {compareListToShow.map(lessonId => (
          <>{renderLessonInfo(lessonId)}</>
        ))

        }
      </VStack>

      <Divider mt={6} />       
      <Button
            size='sm'
            colorScheme='orange'
            onClick={() => setOpenSelectDialog(true)}
            mt={6}
          >
            Compare Lessons
          </Button>
      {hasSaves && (
        <Button
          variant="ghost"
          mt={8}
          size='xs'
          onClick={() => { setOpenOpenSaved(true) }}>
 <BookmarkIcon className="h-4 w-4 mr-2" color={useColorModeValue('primary.200', 'primary.400')} />
          View Saved Comparisons
        </Button>
      )}
    </div>
  )

  const renderSmallGraphTitle = (lesson, index) => {
    const name = `${lesson.name}`;
    const date = moment(lesson.lessonDate).format('MM/DD/YYYY');
    const classInfo = lesson.classroom
      ? `${lesson.classroom.subject}, grade ${lesson.classroom.grade} ${lesson.classroom.period ? `, period ${lesson.classroom.period}` : ''}`
      : 'Class information not available';

    return (
      <HStack spacing={4}>
        <Text textAlign="center" mb={4}>{lesson.name}</Text>
        {/* <Button mr={5} onClick={() => setInfoOpen(index)}>
          Info
        </Button> */}
        <Popover
          returnFocusOnClose={false}
          placement='right'
          closeOnBlur={false}
        >
          <PopoverTrigger>
            <Button size='xs' mt={-3}>Details</Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader fontWeight='semibold'>{name}</PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <VStack witdh='100%' align='stretch' spacing={0}>
                <Text as='i' fontSize='xs'>Lesson Date: {date}</Text>
                <Text as='i' fontSize='xs'>{classInfo}</Text>
                <Text mt={2}><b>GOAL:</b> {lesson.goal}</Text>
                <Text mt={2}><b>LESSON SUMMARY:</b> {lesson.summary}</Text>
              </VStack>
            </PopoverBody>
            {/* <PopoverFooter display='flex' justifyContent='flex-end'>
                Footer
            </PopoverFooter> */}
          </PopoverContent>
        </Popover>
      </HStack>
    )
  }

  if (isLoading) {
    return (<div>Loading...</div>)
  }

  return (
    <div>
      <PageTitle title='Comparisons'>
        {hasSaves && (
          <Tooltip label='View Saved Comparisons'>
        <Button
          // as={IconButton}
          bg='none'
          color={useColorModeValue('primary.200', 'primary.400')}
          size="md"
          onClick={() => { setOpenOpenSaved(true) }}
          p={0}
        >
          <BookmarkIcon className="h-6 w-6" color={useColorModeValue('primary.200', 'primary.400')} />
          <Text ml={2} color={useColorModeValue('gray.600', 'gray.400')}>View Saved Comparisons</Text>
        </Button>
        </Tooltip>
        )}

      </PageTitle>
      <VStack mt="-20px" p={6} align='stretch'>
        {isAdmin || (comparableCount > 1)
          ?
          <Text>
            Get ready for a journey of knowledge! Once you’ve uploaded more than one lesson, or if someone has shared their lesson with you, you can compare your lesson analysis with up to four other lessons. It’s a continuous cycle of learning and growth. Let’s dive in and explore the data from the automated analyses.
            <Button
              size='xs'
              colorScheme='orange'
              ml={8}
              mr={2}
              onClick={() => setOpenSelectDialog(true)}
            >
              Compare Lessons
            </Button>
            {/* {hasSaves && (

              <Button
                bg='none'
                color={useColorModeValue('primary.200', 'primary.400')}
                size="lg"
                onClick={() => { setOpenOpenSaved(true) }}
              >
                <BookmarkIcon className="h-6 w-6" color={useColorModeValue('primary.200', 'primary.400')} />
              </Button>
            )}  */}
          </Text>
          :
          <Text>
            Get ready for a journey of knowledge! Once you’ve uploaded more than one lesson, or if someone has shared their lesson with you, you can compare your lesson analysis with up to four other lessons. It’s a continuous cycle of learning and growth. Make sure to revisit to view lesson analysis comparisons when you have access to more than one lesson.
          </Text>
        }
        <Box m={12}>
          <hr />
        </Box>
        {!openSelectDialog && compareListToShow.length > 1 && graphs > 1 && compareData && (
          <Box>
            {renderCompareListColumn()}
            <div style={{ display: 'inline-block', width: 'calc(100% - 400px)', verticalAlign: 'top', paddingTop: 2 }}>
              {graphType === 'activity'
                ?
                <>
                  <HStack ml={12}>
                    <Text fontSize='lg' fontWeight="bold" w="220px">Moments Comparison for</Text>
                    <Select
                      onChange={onChangeActivity}
                      value={activity}
                      w={400}
                    >
                      {Object.keys(ACTIVITY_TYPES).map((act) => (
                        <option key={`activity-select-${act}-${Math.random()}`} value={act}><b>{ACTIVITY_TYPES[act]}</b></option>
                      ))}
                    </Select>
                    {savedName
                      ? <Text as='i' fontWeight={400} ml={20}>Saved as {savedName}</Text>
                      : <CompareSave currentCompare={compareListToShow} currentView={graphType} />
                    }
                  </HStack>
                  <ActivityCompareGraph lessons={compareData} activity={activity} />
                </>
                :
                <VStack align='stretch' width='100%' ml={12}>
                  <Text fontSize='lg' fontWeight="bold" mb={8} mt={2}>
                    Activity Types Comparison
                    {savedName
                      ? <div style={{display: 'inline-block', fontWeight: 400, marginLeft: 250, fontStyle: 'italic'}}>Saved as {savedName}</div>
                      : <CompareSave currentCompare={compareListToShow} currentView={graphType} />
                    }
                    </Text>
                  <Wrap ml={12} >
                    {compareData.map((lesson, lindex) => (
                      <WrapItem key={`lesson-graph-${lesson.name}`} mr={8} mb={20}>
                        <Box width={320}>
                          {renderSmallGraphTitle(lesson, lindex)}
                          <SmallGraph lesson={lesson} activities={['a1', 'a2', 'a3', 'a24']} />
                        </Box>
                      </WrapItem>
                    ))}
                  </Wrap>
                </VStack>
              }
            </div>
          </Box>
        )

        }
      </VStack>
      <Modal isOpen={openSelectDialog} size='50%'>
        <ModalOverlay />
        <ModalContent ml={32} mr={32} mt={48}>
          <ModalHeader>
            <Text>Select up to {max} lessons to compare</Text>
          </ModalHeader>
          <ModalCloseButton onClick={() => setOpenSelectDialog(false)} />
          <ModalBody>
            <VStack spacing={8} width="100%" align='stretch'>
              {compareList.map((lessonId, index) => (
                <CompareSelect
                  key={`compare-select-${Math.random()}`}
                  setSelection={setSelection}
                  index={index}
                  max={max}
                  list={data && data.lessons ? data.lessons : []}
                  isTeacher
                  isAdmin
                  hasShares={data?.hasShares}
                  lessonId={lessonId}
                />
              ))
              }
              {compareList.length < (max) && (
                <CompareSelect
                  key={`compare-select-empty-${Math.random()}`}
                  setSelection={setSelection}
                  index={compareList.length}
                  max={max}
                  list={data && data.lessons ? data.lessons : []}
                  isTeacher
                  isAdmin
                  hasShares={data?.hasShares}
                  isNew
                />
              )
              }
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={cancelSelectDialog}>
              Cancel
            </Button>
            <Button
              variant='secondary'
              isDisabled={!selectionValid}
              onClick={updateCompareListToShow}
            >
              Compare
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

          {/* open a saved comparision */}
          <Modal isOpen={openOpenSaved} size='xl'>
        <ModalOverlay />
        <ModalContent m={48}>
          <ModalHeader>
            <Text>Open a Saved Comparison</Text>
          </ModalHeader>
          <ModalCloseButton onClick={() => setOpenOpenSaved(false)} />
          <ModalBody>
              {hasSaves
              ?
              <>
                {comparesSaved.response.map(cs => (
                  <div key={`saved compares-${cs.name}`}>
                    <Button 
                      variant="ghost"
                      onClick={() => openSavedComparison(cs)}
                      >
                       {cs.name}:  &nbsp;&nbsp;<i><span style={{fontWeight:400}}>Saved {moment(cs.date).format('MM/DD/YYYY')}, {cs.lessons.length} lessons</span></i>
                    </Button>
                    {/* <Button 
                      variant="ghost"
                    >Saved {moment(cs.date).format('MM/DD/YYYY')}, {cs.lessons.length} lessons</Button> */}
                    </div>
                ))}
              </>
              :
              <Text>There are no compares saved yet.</Text>
              }
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={() => setOpenOpenSaved(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default Comparisons;
