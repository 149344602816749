import react, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Text,
  Center,
  Button,
  VStack,
  Select,
  HStack,
  useColorModeValue,
  IconButton
} from '@chakra-ui/react';
import { CUIAutoComplete } from 'chakra-ui-autocomplete';
import { useContactList } from '../../../store/useContactStore';
import { useShareLesson, useGetLessonShares } from './lessonHooks';
import { CloseIcon } from '@chakra-ui/icons';
import { useUser } from '../../../store/useAppStore';
import trackEvent from '../../../utlis/trackEvents';

const ShareLessonPanel = ({ lessonId, handleClose }) => {
  const contactList = useContactList();
  const { data, isLoading, isError, refetch } = useGetLessonShares(lessonId);
  const { editSharedUsers } = useShareLesson(lessonId);
  const user = useUser();
  const [selectedItems, setSelectedItems] = useState([]);
  const [shared, setShared] = useState(false);
  const [permissionLevel, setPermissionLevel] = useState('a'); // 'a', 'avr', 'av', 'ar'
  const pickerItems = useMemo( () => 
    contactList.filter((contact) => contact._id !== user._id).map((contact) => ({
      value: contact._id,
      label: `${contact.firstName} ${contact.lastName} (${contact.email})`,
    })),[contactList, user._id]
  );

  useEffect(() => {
    if (data?.shares?.length > 0) {
      const sharedUsers = []
      data.shares.forEach((share) => {
        if (share.permissionLevel === permissionLevel) {
          sharedUsers.push({value: share.userId,
            label: `${share.userData?.firstName} ${share.userData?.lastName} (${share.userData.email})`})
        }
      })
      setSelectedItems(sharedUsers);
    }
  }, [data, permissionLevel]);

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      setSelectedItems(selectedItems);
    }
  };

  const handleRefetch = () => {
    refetch();
  };

  const handleShare = () => {
    const selectedUserIds = selectedItems?.map((user) => user.value) || [];
    trackEvent({
      page: 'Lesson',
      params: '',
      event: 'Share Lesson',
      me: user,
      description: 'Share Lesson',
      details: `shared lessonId: ${lessonId} with userIds: ${selectedUserIds}`,
      value: 0,
    })
    editSharedUsers.mutate({selectedUserIds, permissionLevel, refetch: handleRefetch});
    setShared(true);
  };

  const optionFilterFunction = (options, inputValue) => {
    const lowerInputValue = inputValue.toLowerCase();
    return options.filter(option => option.label.toLowerCase().includes(lowerInputValue));
  };


  return (
    <Box w='600px' backgroundColor={useColorModeValue('gray.100', 'gray.800')} rounded='md' border='1px'>
     
      <VStack>
      
        <HStack w='90%' marginTop='2%' justifyContent='space-between'>
        <Text>Share Lesson</Text>
          <VStack>
            
            <Select onChange={(e) => {setPermissionLevel(e.target.value)}}>
              <option value='a'>Analysis Only</option>
              <option value='av'>Analysis and Video</option>
              <option value='ar'>Analysis and Reflections</option>
              <option value='avr'>Analysis, Video, and Reflections</option>
            </Select>
          </VStack>
          <IconButton icon={<CloseIcon />} onClick={() => {handleClose()}} />
        </HStack>
        <Box w='80%'>
          <CUIAutoComplete
            placeholder='Search contacts'
            items={pickerItems}
            selectedItems={selectedItems}
            onSelectedItemsChange={(changes) => handleSelectedItemsChange(changes.selectedItems)}
            disableCreateItem
            listItemStyleProps={{ color: 'black' }}
            listStyleProps={{fontSize: '16px', fontWeight: 'normal'}}
            optionFilterFunc={optionFilterFunction}
          />
        </Box>
        <Box w='100%'>
          <Button variant='uva' float='right' w='100px' mb='10px' mr='20px' onClick={() => {handleShare()}}>
            Share
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};

export default ShareLessonPanel;
