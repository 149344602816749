import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

const defaultProps = {
  focusBorderColor: 'primary.400',
};
const variants = {
  outline: (props) => ({
    field: {
      borderColor: props.colorMode === 'dark' ? 'secondary.300' : 'secondary.800',
    },
  }),
};
const Input = defineMultiStyleConfig({
  defaultProps,
  variants,
});

export default Input;
