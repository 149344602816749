import react, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Center,
  Button,
  VStack,
  Select,
  HStack,
  useColorModeValue
} from '@chakra-ui/react';
import { CUIAutoComplete } from 'chakra-ui-autocomplete';
import { useGetContactList } from './contactHook';
// import { useContactList } from '../../store/useContactStore';

const SupportTeam = ({ setValue, watch, savedTeam = null, isRequired, contactParentOrg }) => {
  // const contactList = useContactList();
  // console.log(contactList)
  const {data: contactList} = useGetContactList(contactParentOrg);
  const [selectedItems, setSelectedItems] = useState([]);
  const [shared, setShared] = useState(false);
  const [pickerItems, setPickerItems] = useState([]);
  useEffect(() => {
    if (contactList) {
      setPickerItems(contactList.map((contact) => ({
        value: contact._id,
        label: `${contact.firstName} ${contact.lastName} (${contact.email})`,
      })));
    }
  },[contactList])

  useEffect(() => {
    if (savedTeam) {
      setSelectedItems(savedTeam.map((contact) => ({
        value: contact._id,
        label: `${contact.firstName} ${contact.lastName} (${contact.email})`,
      })));
    }
  },[savedTeam])

  useEffect(() => {
    setValue('supportTeam', selectedItems);
  }, [setValue, selectedItems]);


  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      setSelectedItems(selectedItems);
    }
  };

  const handleShare = () => {
    const selectedUserIds = selectedItems?.map((user) => user.value) || [];
    setShared(true);
  };

  return (
    <Box>
     
      <VStack>
        <Text>Support Team {isRequired ? '*' : ''}</Text>
      {isRequired && <Text color='red'>*Required</Text>}
        <Box w='80%'>
          <CUIAutoComplete
            placeholder='Search contacts'
            items={pickerItems}
            selectedItems={selectedItems}
            onSelectedItemsChange={(changes) => handleSelectedItemsChange(changes.selectedItems)}
            disableCreateItem
            listItemStyleProps={{ color: 'black' }}
          />
        </Box>
      </VStack>
    </Box>
  );
};

export default SupportTeam;
