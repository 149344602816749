import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Collapse,
  IconButton,
  Text,
  Textarea,
  HStack,
  ButtonGroup,
  SkeletonText,
} from '@chakra-ui/react';
import { CheckCircleIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { useEditReflection } from './lessonHooks';
import { useUser } from '../../../store/useAppStore';
import trackEvent from '../../../utlis/trackEvents';

const Reflection = ({ data, lessonId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [reflection, setReflection] = useState(data?.response);
  const { mutate: editReflection, isLoading } = useEditReflection(lessonId);
  const user = useUser();
  const handleSave = () => {
    trackEvent({
      page: 'Lesson',
      params: '',
      event: 'Save Reflection',
      me: user,
      description: `Lesson ${lessonId} Reflection`,
      details: '',
      value: 0,
    })
    editReflection({
      reflectionId: data._id,
      response: reflection,
    });
    setIsEditing(false);
  };
  const handleCancel = () => {
    setIsEditing(false);
    setReflection(data?.response);
  };
  const isMine = true;

  if (!data) return null;
  // NEED LOADING STATE WITH SKELETON
  return (
    <AccordionItem key={data?._id}>
      <h2>
        <AccordionButton>
          <AccordionIcon />
          <Box as='span' flex='1' textAlign='left'>
            {data.isPrivate ? 'Private Note' : data.questionText}
          </Box>
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <HStack w='full'>
          {isEditing ? (
            <Textarea
              fontSize='xs'
              rows={6}
              w='full'
              value={reflection}
              onChange={(e) => setReflection(e.target.value)}
            />
          ) : (
            <SkeletonText noOfLines={6} isLoaded={data && !isLoading}>
              <Text fontSize='sm'>{reflection}</Text>
            </SkeletonText>
          )}

          {isEditing && (
            <ButtonGroup size='sm'>
              <IconButton
                aria-label='Close edits'
                colorScheme='red'
                icon={<CloseIcon />}
                onClick={handleCancel}
              />
              <IconButton
                aria-label='Save edits'
                colorScheme='green'
                icon={<CheckCircleIcon />}
                onClick={handleSave}
              />
            </ButtonGroup>
          )}
          {isMine && !isEditing && (
            <IconButton
              aria-label='Edit reflection'
              icon={<EditIcon />}
              size='sm'
              onClick={() => setIsEditing(true)}
            />
          )}
        </HStack>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Reflection;
