import { defineStyleConfig } from '@chakra-ui/react';

const Button = defineStyleConfig({
  variants: {
    uva: (props) => ({
      bg: 'primary.400',
      color: 'black',
      _hover: {
        color: 'white',
        bg: props.colorMode === 'dark' ? 'primary.500' : 'primary.500',
      },
      _disabled: {
        bg: props.colorMode === 'dark' ? 'primary.100' : 'primary.300',
        color: 'white',
        _hover: {
          bg: props.colorMode === 'dark' ? 'primary.200 !important' : 'primary.400 !important',
        }
      },

    }),
    'uva-outline': (props) => ({
      border: '2px solid',
      borderColor: 'primary.400',
      color: props.colorMode === 'dark' ? 'primary.400' : 'primary.600',
    }),
    secondary: (props) => ({
      bg: props.colorMode === 'dark' ? 'secondary.400' : 'secondary.800',
      color: props.colorMode === 'dark' ? 'black' : '#ffffff',
      transition: 'transform 0.15s ease-out, background 0.15s ease-out',
      _hover: {
        bg: props.colorMode === 'dark' ? 'secondary.700' : 'secondary.900',
      },
      _disabled: {
        bg: props.colorMode === 'dark' ? 'secondary.700' : 'secondary.900',
        _hover: {
          bg: props.colorMode === 'dark' ? 'secondary.700 !important' : 'secondary.900 !important',
        }
      },
    }),
    'secondary-outline': (props) => ({
      border: '2px solid',
      borderColor: props.colorMode === 'dark' ? 'secondary.400' : 'secondary.400',
      color: props.colorMode === 'dark' ? 'secondary.400' : 'secondary.400',
    }),
  },
  defaultProps: {
   //  colorScheme: "blue", // set the default color scheme to purple
 
  },
});
export default Button;
