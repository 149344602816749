import { extendTheme } from '@chakra-ui/react';

// Global style overrides
// import styles from './styles'

// Foundational style overrides
import config from './foundations/config';
import colors from './foundations/colors';
// import styles  from './foundations/styles'
// Component style overrides
import Button from './components/button';
import Input from './components/input';
import FormLabel from './components/formLabel';
import Textarea from './components/textArea';

const overrides = {
  // styles,
  // borders,
  colors,
  config,
  // Other foundational style overrides go here
  components: {
    Button,
    Input,
    FormLabel,
    Textarea,
    // Other components go here
  },
  Modal:{
    sizes:{
      xl:{
        h:"600px",
        w:'1500px',
       
      }
    }
  },
};
export default extendTheme(overrides);
