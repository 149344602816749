import { useEffect, useState } from 'react';
import moment from 'moment';
import { Stack, HStack, Button, Select, ScaleFade, Input, VStack, useColorMode, Text, Link, Center, Modal } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { useQueryClient } from '@tanstack/react-query';
import { useUser } from '../../store/useAppStore';
import { useListLessonsPipeline, useReprocessLesson } from '../Lesson/components/lessonHooks';
import { DataTable } from '../../components/Tables';
import PageTitle from '../../components/PageTitle/PageTitle';
import trackEvent from '../../utlis/trackEvents';
import { ROLES } from '../../constants';
import ReprocessConfirmation from '../../components/reprocessConfimation/ReprocessConfirmation';
import api from '../../queries/api';

const capitalizeFirstLetter = (string) => string?.charAt(0).toUpperCase() + string?.slice(1) || '';

const headCells = [
  // note: ID is the name used for sorting
  {
    id: 'lessonDetails',
    numeric: false,
    disablePadding: true,
    label: 'Lesson Details',

  },
  {
    id: 'teacherData',
    numeric: false,
    disablePadding: false,
    label: 'Teacher',
    size: 100,
    maxSize: 100,
  },
  {
    id: 'created',
    numeric: true,
    disablePadding: false,
    label: 'Uploaded/Updated',
    searchable: false,
  },
  {
    id: 'statusMessages',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: '_id',
    numeric: false,
    disablePadding: false,
    label: 'Take Action',
    link: 'lesson',
    searchable: false,
    disableNavigation: true,
    enableSorting: false,
  },

];
const Pipeline = ({asPanel, limit}) => {
  const [filterBy, setFilterBy] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [tableRows, setTableRows] = useState([]);
  const [reprocessConfirmation, setReprocessConfirmation] = useState(false);
  const [modelVersion, setModelVersion] = useState('');

  const {reprocessLesson} = useReprocessLesson();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const user = useUser();
  let group = 'teacher';
  ROLES.forEach(role => {
    if (role.value === user.role[0]) {
      group = role.group;
    }
  })
  const { data, isLoading } = useListLessonsPipeline();
  const {colorMode} = useColorMode();


  const handleReprocessLesson = () => {
    reprocessLesson.mutate(modelVersion);
    queryClient.invalidateQueries('lesson-list');
  }

  const handleChangeFilterBy = (value) => {
    setFilterBy(value);
  };
  const handleChangeFilterQuery = (value) => {
    setFilterQuery(value);
  };

  useEffect(() => {
    if (data) {
      if (asPanel && limit !== -1) {
        const limitedData = data.slice(0,limit);
        setTableRows(limitedData)
      } else {
        setTableRows(data);
      }
    }
  },[data])
  
  const lookForEnter = (e) => {
    if (e.key === 'Enter') {
      const tableData = [...data]
      setTableRows(tableData.filter((row) => {
        switch (filterBy) {
          case 'teacherData':
            const td = row.teacherData[0];
            const name = td ? `${td.firstName.toLowerCase()} ${td.lastName.toLowerCase()}` : 'zzzz';
            return name?.includes(filterQuery.toLowerCase());
            break;
          case 'lessonDetails':
            const ld = row.lessonDetails[0];
            const ldName = ld && ld.name ? row.lessonDetails[0]?.name.toLowerCase() : ' ';
            const lDate = moment(row.lessonDetails[0]?.lessonDate).format('M/D/YYY');
            return ldName?.includes(filterQuery.toLowerCase()) || lDate?.includes(filterQuery);
            break;
          case 'statusMessages':
            return row.statusMessages?.display?.toLowerCase()?.includes(filterQuery.toLowerCase());
            break;
          default:
            return row[filterBy]?.includes(filterQuery.toLowerCase());
        }
      }
      ))
    }
  };
  const getFilterDisplayName = (filterBy) => {
    const filter = headCells.find((h) => h.id === filterBy);
    return filter ? filter.label : '';
  };



  const handleClick = async (action, id) => {
    if (action === 'reprocess') {
      navigate('?id=' + id);
      setReprocessConfirmation(true);
    }
    if (action === 'download') {
      const fileurl = await api.get(`lessons/getMatrix/${id}`)
      //trigger a download from fileurl
      window.open(fileurl.data, '_blank');
      trackEvent({
        page: 'Pipeline',
        params: '',
        event: 'Download Matrix',
        me: user,
        description: 'Download Matrix',
        details: `lessonId: ${id}`,
        value: 0,
      });
    }
    
  };


  const columnHelper = createColumnHelper();
  const columns = headCells.filter(h => group !== 'support' || h.id !== '_id').map((el) => {
    if (el.id === 'created') {
       return columnHelper.accessor(el.id, {
         cell: (info) => {
           const cdate = !info.getValue() ? '' : moment(info.getValue()).format('M/D/YYYY h:mm a');
           const udate = info.row.original.statusMessages.updated ? moment(info.row.original.statusMessages.updated).format('M/D/YYYY h:mm a') : '';
           //  return teacher?.email || '';
           //  return `${cdate} / ${udate}`;
           return (
             <VStack alignItems='flex-start'>
               <Text>{cdate}</Text>
               <Text>{udate}</Text>
             </VStack>
           )
         },
        //  (!info.getValue() ? '' : moment(info.getValue()).format('M/D/YYYY')),
         header: el.label,
         //  sortType: 'datetime', // Corrected from sortingFn to sortType,
         sortingFn: (rowA, rowB) => {
          // const emailA = rowA.original.teacherData[0].email;
          // const emailB = rowB.original.teacherData[0].email;
          // return emailA?.localeCompare(emailB);
          const dateA = rowA.original.created; 
          const dateB = rowB.original.created; 
          return dateA?.localeCompare(dateB);

       }
         // sortDescFirst: true
       });
    }
    if (el.id === 'teacherData') {
       return columnHelper.accessor(el.id, {
         cell: (info) => {
           const [teacher] = info.getValue();
          //  return teacher?.email || '';
          return `${teacher?.firstName} ${teacher?.lastName}`
         },
         header: el.label,
         size: el.size,
         sortDescFirst: false,
         sortingFn: (rowA, rowB) => {
          // const emailA = rowA.original.teacherData[0].email;
          // const emailB = rowB.original.teacherData[0].email;
          // return emailA?.localeCompare(emailB);
          const teacherA = rowA.original.teacherData && rowA.original.teacherData[0] ? `${rowA.original.teacherData[0].firstName} ${rowA.original.teacherData[0].lastName}` : ' ';
          const teacherB = rowB.original.teacherData && rowB.original.teacherData[0] ? `${rowB.original.teacherData[0].firstName} ${rowB.original.teacherData[0].lastName}` : ' ';
          return teacherA?.localeCompare(teacherB);

       }
       });
    }
    if (el.id === 'lessonDetails') {
       return columnHelper.accessor(el.id, {
         cell: (info) => {
           const [lessonDetails] = info.getValue();
           const name = lessonDetails?.name || '';
           const date = lessonDetails?.lessonDate ? moment(lessonDetails.lessonDate).format('M/D/YYYY') :  '';
           const term = lessonDetails?.term || '';
           return (
             <VStack alignItems='flex-start'>
               <Text>{name}</Text>
               <Text>{date}</Text>
               <Text>{term ? `Term: ${term}` : null}</Text>
             </VStack>
           );
         },
         header: el.label,
         sortDescFirst: false,
         sortingFn: (rowA, rowB) => {
          const lessonA = rowA.original.lessonDetails[0].name;
          const lessonB = rowB.original.lessonDetails[0].name;
          return lessonA?.localeCompare(lessonB);
       }
       });
    }
    if (el.id === 'statusMessages') {
      return columnHelper.accessor(el.id, {
        cell: (info) => {
          const message = info.getValue();
          return (
          <VStack alignItems='flex-start'>
            <Text>{message.display || 'Unknown'}</Text>
            <Button size='xs' 
                  isDisabled={message.display !== 'Processing Complete'} 
                  variant='uva-outline' 
                  onClick={() => { navigate(`/lesson/${info.row.original._id}`)}}
              >
              View Analysis
            </Button>
          </VStack>
        )
      },
         header: el.label,
        //  sortType: 'text', // Corrected from sortingFn to sortType,
         sortUndefined: -1,
         sortingFn: (rowA, rowB) => {
          const lessonA = rowA.original.statusMessages.display;
          const lessonB = rowB.original.statusMessages.display;
          return lessonA?.localeCompare(lessonB);
       }
       });
    }
    if (el.link) {
      return columnHelper.accessor(el.id, {
        cell: (info) => {
          const { row } = info;
          const { statusMessages } = row.original;
          const isProcessed = statusMessages.status === 'nn-processing-completed';

          return(
          <VStack alignItems='flex-start'>
            <Button size='xs' variant='secondary' isDisabled={!isProcessed} onClick={() => {handleClick('reprocess', row.original._id)}}>
              Reprocess Video File
            </Button>
            <Button size='xs' variant='secondary' isDisabled={!isProcessed} onClick={() => {handleClick('download', row.original._id)}}>
              Download Matrix File
            </Button>
          </VStack>
        )},
        header: el.label,
        searchable: el.searchable,
        disableNavigation: el.disableNavigation,
        enableSorting: el.enableSorting,
      });
   }
    return columnHelper.accessor(el.id, {
       cell: (info) => capitalizeFirstLetter(info.getValue()) || null,
       header: el.label,
    });
   });
   
  return (
    <>
    <div>
      <PageTitle title='Video Pipeline'>
      {asPanel && limit !== -1 && (
       
                    <a href='/pipeline'>
                    <Button size='sm' variant='secondary'>
                      View Full Pipeline
                    </Button>
                    </a>

                )}
      </PageTitle>
      <Stack pl={6}>
        <Stack
          direction='row'
          // className='mt-4 mb-4 whitespace-nowrap'
          // justifyContent='space-between'
          pl={6}
        >
          {!asPanel || limit === -1 && data && data.length > 0 && (
          <HStack direction='row' flexWrap='wrap' gap='2' w={'full'}>
            <div className='w-44'>
              <Select
                id='filter-by'
                fontSize='sm'
                value={filterBy}
                onChange={e => handleChangeFilterBy(e.target.value)}
                size='sm'
                // style={{border: 'none', background: '#222327', borderRadius: 5}}
              >
                <option value='' key='nothing-selected'>Search by...</option>
                {headCells
                  .filter(h => h.searchable !== false)
                  .map(h => (
                    <option value={h.id} key={h.id}>
                      {h.label}
                    </option>
                  ))}
              </Select>
            </div>


            <ScaleFade initialScale={0.9} in={filterBy}>
              <Input
                //  variant="flushed"
                placeholder={`${getFilterDisplayName(filterBy)}`}
                variant='filled'
                style={{ background: colorMode === 'dark' ? '#222327' : '', borderRadius: 5 }}
                fontSize='sm'
                minWidth='240px'
                size='sm'
                id='filter-query'
                value={filterQuery}
                onChange={e => handleChangeFilterQuery(e.target.value)}
                onKeyDown={lookForEnter}
              />
            </ScaleFade>
          </HStack>
          )}
        </Stack>
        {data && data.length > 0
          ?
          <DataTable columns={columns} data={tableRows} noRedirect />    
          :
          <Text>{isLoading ? 'Loading...' : 'No Pipeline Data Available'}</Text>
        }
      </Stack>

    </div>
      <ReprocessConfirmation reprocessConfirmation={reprocessConfirmation} setReprocessConfirmation={setReprocessConfirmation} handleReprocessLesson={handleReprocessLesson} setModelVersion={setModelVersion}/>
    </>
  );
};

export default Pipeline;
