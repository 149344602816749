import { useToast } from '@chakra-ui/react';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../../queries/api';
import { useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export

/**
 * Hook for creating organizations
 */
export const useAddOrganization = (handleClose, setErrorMessage, isClass = false) => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const naviate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: async (organization) => {
      await api.post('/organizations/create', { organization });
    },
    onSuccess: () => {
      toast({
        title: 'Success',
        description: isClass ? 'Class created successfully.' : 'Organization created successfully.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ['organizations'] });
      handleClose();
    },
    onError: (err) => {
      console.log('CREATE ORG ERROR ', err);
      setErrorMessage(err.response.data.errorMessage);
    }
  });

  return mutation;
};

export const useDeleteOrganization = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: async (orgId) => {
      await api.delete(`/organizations/${orgId}`);
    },
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Organization deleted successfully.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ['organizations'] });
    },
    onError: (err) => {
      console.log('DELETE ORG ERROR ', err);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error deleting this organization. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  });
  return mutation;
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: async (organization) => {
      await api.post('/organizations/update', { organization });
    },
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Class updated successfully.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ['organizations'] });
      navigate('/organizations');
    },
    onError: (err) => {
      console.log('UPDATE ORG ERROR ', mutation.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error updating this organization. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  });
  return mutation;
}

export const useGetOrganizationsByType = ({ orgType }) =>
  useQuery({
    queryKey: ['organizations', { orgType }],
    queryFn: async () => {
      const { data } = await api.get(`/organizations/getByType/${orgType}`);
      return data;
    },
  });

export const useGetOrganizationById = (orgId) =>
  useQuery({
    queryKey: ['organizations', { orgId }],
    queryFn: async () => {
      const { data } = await api.post('/organizations/getOrgWithInfo', { filter: { _id: orgId }});
      return data[0];
    },
  });

export const useGetTeachers = () =>
  useQuery({
    queryKey: ['teachers', {}],
    queryFn: async () => {
      const { data } = await api.get(`/users/getTeachers`);
      return data;
    },
  });

export const useFilterOrganizations = ({ filter }) =>
  useQuery({
    queryKey: ['organizations', { filter }],
    queryFn: async () => {
      const { data } = await api.post(`/organizations/filter`, { filter });
      return data;
    },
  });

export const useGetUsersClasses = ({ userId }) =>
  useQuery({
    queryKey: ['organizations', { userId }],
    queryFn: async () => {
      const { data } = await api.get(`/organizations/getUsersClasses/${userId}`);
      return data;
    },
  });
  