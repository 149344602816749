import React, { useState, useEffect } from 'react';

import { Text, HStack, Box, VStack, Button, Checkbox, Link,
  Modal, ModalHeader, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter
 } from '@chakra-ui/react';
import { ACTIVITY_TYPES, ACTIVITY_COLORS, ACTIVITY_GROUPS } from '../../../constants';
import { TriangleDownIcon } from '@chakra-ui/icons';

function SelectActivities(props) {
  const { currentActivities, activitiesCounts, defaultActivities, updateActivities } = props;
  const [isOpen, setIsOpen ] = useState(false);
  const [ tempFilter, setTempFilter ] = useState(['a1']);
  const [ updatedFilter, setUpdatedFilter] = useState(0);
  
  const [activityList, setActivityList] = useState([]);


  // useEffect(() => {
  //   const uf = updatedFilter;
  // }, [tempFilter, tempFilter.length]);
  useEffect(() => {
    if (currentActivities && isOpen) {
      const ca = currentActivities.map(a => (a.value))
      setTempFilter(ca);
      setUpdatedFilter(updatedFilter + 1);
      debugger;
    }
  }, [currentActivities, isOpen])


  if (!isOpen) {
    return(      
       <Button 
        size='sm' 
        // variant='primary'
        onClick={() => {setIsOpen(true)}}
        >Select Activities to Graph <TriangleDownIcon size='xs' ml={2}/></Button>
      )
  }

  const resetDefaults = () => {
    const da = defaultActivities.map(a => (a.value));
    setTempFilter(da);
    setUpdatedFilter(updatedFilter + 1);
  }

  const updateAndClose = () => {
    updateActivities(tempFilter);
    setIsOpen(false);
  }

  const handleCheckBox = (event, activity) => {
    let f = tempFilter;
    debugger;
    const idx = f.indexOf(activity.activity)
    if (idx >= 0) {
      // remove it
      f.splice(idx, 1);
      if (f.length === 0 ) {
        alert('Resetting to default activites (the four most frequent)')
        f = defaultActivities.map(a => (a.value));
       
      }
    } else {
      if (f.length >= 8) {
        alert('Cannot have more than 8 activities for the graph at this time.');
        return;
      }
      f.push(activity.activity);
    }
    setTempFilter(f);
    setUpdatedFilter(updatedFilter+1);
  }

  const clearAll = () => {
    setTempFilter([]);
    setUpdatedFilter(updatedFilter+1);
  }

  const resetAll = () => {
      const f = defaultActivities.map(a => (a.value));
      setTempFilter(f);
      setUpdatedFilter(updatedFilter+1);
  }
  
  const checkChecked = (activity) => (tempFilter.includes(activity));

  const renderColumn = (column) => {
    const cNames = ACTIVITY_GROUPS.filter(ag => ag.column === column);
    const lists = [];
    cNames.forEach(name => {
      let act = 'a1';
      const activities = [];
      // eslint-disable-next-line array-callback-return
      Object.keys(ACTIVITY_COLORS).map(a => {
        const ac = ACTIVITY_COLORS[a];
        if(ac.type === name.name) {
          // find the count
          let label = ACTIVITY_TYPES[a]
          activitiesCounts.forEach(ac => {
            if (ac.value === a) {
              label = ac.label;
            }
          })
          activities.push({...ac, activity: a, display: label})
        }
      });
      lists[name.name] = activities;
    })

    return(
      <Box>
        {cNames.map(name => (
          <VStack  align='stretch' w={280} mb={8} minHeight={'158px'} key={`name.name.${Math.random()}`}>
             <HStack>
              {/* <Checkbox /> */}
            <Text><b>{name.name}</b></Text>
            </HStack>
            <div style={{height: 5, width: '80%', background: lists[name.name][0].bg, marginTop: -7}} />
            {lists[name.name].map(a => (
              <HStack  key={`a.display.${Math.random()}`}>
              <Checkbox 
                  isChecked={checkChecked(a.activity, tempFilter.length)}
                  onChange={(e) => handleCheckBox(e, a)}
                  colorScheme='primary'
                  />
              <Text
               onClick={(e) => handleCheckBox(e, a)}
              >{a.display}</Text>
              </HStack>
            ))}
          </VStack>
        ))}
      </Box>
    )
  }

  return( 
    <Modal isOpen={isOpen} onClose={() => {setIsOpen(false)}} size='xl'>
    <ModalOverlay />
      <ModalContent maxW="1100px">
        <ModalHeader>

          Select Up to 8 Activities
          <Button ml={12} colorScheme='darkgray' variant='link' onClick={clearAll}>
            Clear All
          </Button>
          <Button
            ml={4}
            colorScheme='darkgray'
            variant='link'
            onClick={resetAll}
          >
            Reset to Default Activities
          </Button>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody  pl={12}>
      <HStack gap={8} alignItems ='normal'>
          {renderColumn(0)}
          {renderColumn(1)}
          {renderColumn(2)}
        </HStack>
          </ModalBody>
      <ModalFooter>
        <Button  ml={32} mr={3} onClick={() => {setIsOpen(false)}}>
          Cancel
        </Button>
        <Button 
          colorScheme='primary' 
          mr={3} 
          onClick={updateAndClose}
          isDisabled={tempFilter.length === 0}
          >
          Update Activites
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
  )

 
}

export default SelectActivities;