import {
  Box,
  Button,
  HStack,
  Heading,
  Textarea,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { AddIcon, ChatIcon } from '@chakra-ui/icons';
import { useState, useEffect } from 'react';
import Comment from './Comment';
import { useUser } from '../../store/useAppStore';
import { useAddComment, useGetLessonComments } from './commentHooks';
import trackEvent from '../../utlis/trackEvents';

const defaultForm = {
  commentBody: '',
  mediaUrl: null,
};

const CommentContainer = ({ lessonId, userId }) => {
  const [form, setForm] = useState({ ...defaultForm });
  const [childForm, setChildForm] = useState({ ...defaultForm });
  const user = useUser();
  const addComment = useAddComment(lessonId);
  // const [isLoading, setIsLoading] = useState(false);
  const {
    data: comments = [''],
    isLoading,
    // isError,
    refetch
  } = useGetLessonComments(lessonId);

  useEffect(() => {
    const c = comments;
    // setIsLoading(false);
  }, [comments[0]]);

  const handleChange = (isChild) => (e) => {
    if (isChild) {
      setChildForm({ ...childForm, [e.target.name]: e.target.value });
      return;
    }
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleErrors = (err) => {
    console.log(err);
  };
  const handleSubmit = async (parentId) => {
    const newComment = {
      owner: user._id,
      content: parentId ? childForm.commentBody : form.commentBody,
      lessonId,
    };
    // If there is no content, don't submit
    if (newComment.content.length === 0 || newComment.content === '') {
      return;
    }
    if (parentId) {
      newComment.isChild = true;
      newComment.parentId = parentId;
    }
    trackEvent({
      page: 'Lesson',
      params: '',
      event: 'Add Comment',
      me: user,
      description: `Lesson ${lessonId} Comment`,
      details: `reply?: ${parentId ? 'true' : 'false'}`,
      value: 0,
    });
    // setIsLoading(true);
    addComment.mutate(
      { ...newComment },
      { onSuccess: () => { 
        setChildForm({ ...defaultForm }); 
        setForm({...defaultForm});
        refetch()
      }, 
        onError: (err) => handleErrors(err) },
    );
  };
  return (
    <VStack
      spacing='2'
      alignItems='flex-start'
      p='6'
      // w='full'
      minWidth={350}
      maxWidth={500}
      maxHeight='90vh'
      overflowY='auto'
      shadow='inner'
      mb={4}
      borderRadius='lg'
      // bg={useColorModeValue('secondary.50', 'gray.800')}
      bg={'#f3f1f1'}
    >
      <HStack spacing='4' w='full' justifyContent='space-between'>
        <Heading size='md'><ChatIcon mr={2}/>Comments</Heading>
      </HStack>
      <Box w='full'>
        <Textarea
          value={form.commentBody}
          bg={useColorModeValue('lightGray', 'secondary.800')}
          name='commentBody'
          onChange={handleChange(false)}
          fontSize='sm'
        />
        <HStack w='full' justifyContent='flex-end'>
          {/* <UploadModal /> */}
          <Button onClick={() => handleSubmit(null)} variant='uva' size='xs' mt={2} leftIcon={<AddIcon />}>
            Add Comment{' '}
          </Button>
        </HStack>
      </Box>
      {/* THIS CAN PROBABLY GET CLEANED UP */}
      {!isLoading && comments &&
        comments.length > 0 && comments[0] !== '' && 
        comments.map((comment) => {
          if (comment.children && comment.children.length > 0) {
            return (
              <>
                <Comment
                  {...comment}
                  key={comment._id}
                  handleReplyChange={handleChange(true)}
                  handleReplySubmit={handleSubmit}
                  replyValue={childForm.commentBody}
                  parentId={comment._id}
                  userId={userId}
                  lessonId={lessonId}
                />
                {comment.children.map((child) => (
                  <Comment {...child} key={child._id} isChild userId={userId} lessonId={lessonId} />
                ))}
              </>
            );
          }
          return (
            <Comment
              {...comment}
              key={comment._id}
              handleReplyChange={handleChange(true)}
              handleReplySubmit={handleSubmit}
              replyValue={childForm.commentBody}
              parentId={comment._id}
              userId={userId}
              lessonId={lessonId}
            />
          );
        })}
    </VStack>
  );
};
export default CommentContainer;
