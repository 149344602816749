import { useState, useEffect } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerFooter,
  DrawerContent,
  DrawerHeader,
  Button,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { useGetOrganizationById, useDeleteOrganization } from './orgHooks';
import { useSearchParams } from 'react-router-dom';
import InstituteForm from './InstituteForm';
import DistrictForm from './DistrictForm';
import SchoolForm from './SchoolForm';

const EditOrgDrawer = ({ isOpen, onClose, orgs, openConfirmation }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [orgId, setOrgId] = useState(null);
  const { data: orgData } = useGetOrganizationById(orgId);
  const { mutate: deleteOrg } = useDeleteOrganization();
  if (orgData?.state && typeof orgData.state === 'string') {
    orgData.state = {
      label: `${orgData.state.charAt(0).toUpperCase()}${orgData.state.slice(1)}`,
      value: orgData.state,
    };
  }
  if (orgData?.parent && typeof orgData.parent === 'string') {
    const parentOrg = orgs.find((org) => org._id === orgData.parent);
    orgData.parent = { name: parentOrg.name, _id: parentOrg._id };
  }
  const [type, setType] = useState(null);
  const lowerType = type ? type.toLowerCase() : '';
  // const [isOpen, setIsOpen] = useState(type);
  const capitalizedType = type ? `${type?.charAt(0).toUpperCase()}${type?.slice(1)}` : '';
  const handleClose = () => {
    setSearchParams({});
    onClose();
  };
  const handleDelete = () => {
    openConfirmation()
  };
  useEffect(() => {
    const id = searchParams.get('id');
    const type = searchParams.get('type');
    if (id) {
      setOrgId(id);
      setType(type);
    } else {
      onClose();
    }
  }, [searchParams]);

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false} closeOnEsc={false}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerHeader>Edit/Delete {capitalizedType}</DrawerHeader>
          <DrawerBody>
            {type === 'Institution' && orgData?._id && (
              <InstituteForm handleClose={handleClose} defaultEditValues={orgData} />
            )}
            {type === 'District' && orgData?._id && (
              <DistrictForm handleClose={handleClose} defaultEditValues={orgData} />
            )}
            {type === 'School' && orgData?._id && (
              <SchoolForm handleClose={handleClose} defaultEditValues={orgData} />
            )}
          </DrawerBody>
          <DrawerFooter>
            <VStack alignItems='flex-end'>
              <HStack>
                <Button variant='outline' mr={3} onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant='uva' type='submit' form={`create-${lowerType === 'institution' ? 'institute' : lowerType}`}>
                  Update {capitalizedType === 'Institute' ? 'Institution' : capitalizedType}
                </Button>
              </HStack>
              <Button colorScheme='red' variant='solid' ml={3} onClick={handleDelete}>
                Delete {capitalizedType === 'Institute' ? 'Institution' : capitalizedType}
              </Button>
            </VStack>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
export default EditOrgDrawer;
