/**
 * A component that renders a private route if the user is authenticated, otherwise it redirects to the sign in page. Deep links will be stored in global state and persisted across sign in.
 * @param {boolean} isAuthenticated - A boolean indicating if the user is authenticated.
 * @returns {JSX.Element} - The PrivateRoute component.
 */
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useRedirectStore } from '../store/useAppStore';

const PrivateRoute = ({ isAuthenticated }) => {
  const location = useLocation();
  const { redirectActions } = useRedirectStore();
  if (!isAuthenticated) {
    const from = location?.pathname;
    redirectActions.setRedirect(from);
    return <Navigate to='/signin' />;
  }
  return <Outlet />;
};

export default PrivateRoute;
