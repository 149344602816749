import React, { useState } from 'react';
import { Text, Stack, Switch, useToast, FormControl, FormLabel, VStack, Divider, HStack } from '@chakra-ui/react';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useUpdateUser } from '../AdminUsers/userHooks';
import { useUser } from '../../store/useAppStore';
// import ContactModal from '../../components/Contacts/ContactModal';

const Account = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const toast = useToast()
  const user = useUser()
  const onClose = () => {
    console.log('onClose')
  }
  const { mutate } = useUpdateUser(onClose, setErrorMessage)

  const roles = typeof user.role === 'string' ? user.role : user.role.join(', ');
  
  return (
    <div>
      <PageTitle title='My Account' />
      <Stack p={12} ml='12'>
        <VStack w='400px' align='stretch'>
          <Text fontSize='xs'>Name</Text>
          <Text>{user.firstName} {user.lastName}</Text>
          <Divider mb='6' borderColor={'#888'}/>
          <Text fontSize='xs'>Email</Text>
          <Text>{user.email}</Text>
          <Divider mb='6' borderColor={'#888'}/>
          <Text fontSize='xs'>Permission(s)</Text>
          <Text>{roles}</Text>
          <Divider mb='6' borderColor={'#888'}/>
        <FormControl>
        <Text fontSize='xs' mb='6'>Receive Email Notifications</Text>
        <HStack spacing='2' ml='4'>
        <Text fontSize='xs'>OFF</Text>
          <Switch
            id='emailNotifications'
            isChecked={user.emailNotifications}
            onChange={(e) => {
              mutate({ ...user, emailNotifications: e.target.checked})
            }}
          />
          <Text fontSize='xs'>ON</Text>
          </HStack>
        </FormControl>
        </VStack>
      </Stack>
    </div>
  )
}

export default Account;
