import { z } from 'zod';

export const SignUpSchema = z
  .object({
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    email: z.string().email('Invalid email').min(1, ' Email is required'),
    password: z
      .string()
      .min(8, 'Password must be at least 8 characters')
      .regex(
        new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'),
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
      ),
    confirm: z.string().min(1, 'Please confirm your password'),
    // registrationCode: z
    //   .string()
    //   .min(1, 'Please enter a registration code, only invited users can register'),
  })
  .refine((data) => data.password === data.confirm, {
    message: `Passwords don't match`,
    path: ['confirm'],
  });
export const SignInSchema = z.object({
  email: z.string().email('Invalid email').min(1, ' Email is required'),
  password: z
    .string()
    .min(8, 'Password must be at least 8 characters')
    .regex(
      new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'),
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
    ),
});

export const ResetSchema = z.object({
  confirm: z
  .string()
  .min(8, 'Password must be at least 8 characters')
  .regex(
    new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'),
    'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
  ),
  password: z
    .string()
    .min(8, 'Password must be at least 8 characters')
    .regex(
      new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'),
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
    ),
});

const selectSchema = z.object({
  label: z.string(),
  value: z.string(),
});

const schoolSchema = z.object({
  _id: z.string(),
  name: z.string(),
});

const selectValue = selectSchema.shape.value;
// THIS IS REMOVE ANY VALUES THAT AREN'T IN THE SCHEMA
// IN THIS CASE WE ONLY CARE ABOU THE ORG _IDS

const requiresSchool = (roles) => {
  console.log(roles.includes('teacher') ||
  roles.includes('practicing-teacher') ||
  roles.includes('school-mentor') ||
  roles.includes('school-coach') ||
  roles.includes('administrator'))
  return (
    roles.includes('teacher') ||
    roles.includes('practicing-teacher') ||
    roles.includes('school-mentor') ||
    roles.includes('school-coach') ||
    roles.includes('administrator')
  );
};
const requiresInstitute = (roles) => {
  return roles.includes('mentor') || roles.includes('coach') || roles.includes('course-instructor');
};
export const AddUserSchema = z
  .object({
    email: z.string().email('Invalid email').min(1, 'An email is required'),
    role: z
      .array(selectSchema)
      .nonempty({ message: 'Please select a role', too_small: 'Please select a role' })
      .transform((val) => val.map((v) => v.value)),
    district: z
      .object({
        _id: z.string().optional(),
      })
      .transform((val) => val._id),
    institute: z
      .object({
        _id: z.string().optional(),
      })
      .transform((val) => val._id),
    school: z
      .object({
        _id: z.string().optional(),
      })
      .transform((val) => val._id),
  })
  .superRefine((val, ctx) => {
    if (requiresSchool(val.role) && (!val.institute && !val.district)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['institute', 'district'],
        message: 'Please select a institute or a district',
      });
    }
    if (requiresSchool(val.role) && !val.school) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['school'],
        message: 'Please select a school',
      });
    }
    if (requiresInstitute(val.role) && (!val.institute && !val.district)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['institute', 'district'],
        message: 'Please select a institute or a district',
      });
    }
  });
  export const EditUserSchema = z
  .object({
    _id: z.string(),
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    email: z.string().email('Invalid email').min(1, 'An email is required'),
    role: z
      .array(selectSchema)
      .nonempty({ message: 'Please select a role', too_small: 'Please select a role' })
      .transform((val) => val.map((v) => v.value)),
    organizations: z
      .array(schoolSchema)
      .transform((val) => val.map((v) => v._id)).optional(),
    district: z.object({
      _id: z.string().optional(),
    }).transform((val) => val._id).optional(),
    institute: z.object({
      _id: z.string().optional(),
    }).transform((val) => val._id).optional(),
  }).superRefine((val, ctx) => {
    if (requiresSchool(val.role) && !val?.organizations?.length > 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['organizations'],
        message: 'Please select a school',
      });
    }
  });
// TEACHERS & school related roles NEED institute + school
// Admins, researchers, and stakeholders don't need an org
// mentor, coach, course instructor require institure
// district is optional for all, but will be needed if the school is attached to a district in order to preserve the hierarchy
