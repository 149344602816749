import { getStageByDomain, getAPIByDomain } from './utlis';

export const STAGE = getStageByDomain();
export const API_ENV = getAPIByDomain();

// LAMBDA URLs
// NOTE: Change LOCAL_URL to redirect to deployed endpoints during development
// ===> DO NOT Change REMOTE_LAMBDA as Terraform is passing in the value via .env <===
export const API_VERSION = 'api/v1';
export const LOCAL_URL = `http://localhost:8050/stage/${API_VERSION}`;
export const REMOTE_URL = `${import.meta.env.VITE_API_URL}/${API_VERSION}`;

// Determine which API URL to use.
export const USE_LOCAL = API_ENV === 'local';
export const API_URL = USE_LOCAL ? LOCAL_URL : REMOTE_URL;

console.log('ENV: ', API_ENV, 'USE LOCAL: ', USE_LOCAL, 'APIURL: ', API_URL);

export const ROLES = [
  { label: 'Teacher Candidate', value: 'teacher', group: 'teacher' }, // 0
  { label: 'Mentor', value: 'mentor', group: 'support' }, // 1
  { label: 'Coach', value: 'coach', group: 'support'  }, // 2
  { label: 'Course Instructor', value: 'instructor', group: 'support'  }, // 3
  { label: 'Practicing Teacher', value: 'practicing-teacher', group: 'teacher'  }, // 4
  { label: 'School Mentor', value: 'school-mentor', group: 'support'  }, // 5
  { label: 'School Coach', value: 'school-coach', group: 'support'  }, // 6
  { label: 'School Administrator', value: 'administrator', group: 'support'  }, // 7
  { label: 'Admin', value: 'admin', group: 'admin'  }, // 8
  { label: 'Researcher', value: 'researcher', group: 'admin'  }, // 9
  { label: 'UVA Stakeholder', value: 'stakeholder', group: 'admin'  }, // 10
];
// There is an additional role of 'developer' that is not included in the above list, and cannot be created from inside the app.
// Create an admin, and then edit the DB directly to add a developer role.

export const DEFAULT_SELECT_OBJ = {
  label: '',
  value: '',
};

export const ACTIVITY_TYPES = {
  a1: 'Whole Class Activity',
  a2: 'Individual Activity',
  a3: 'Small Group Activity',
  a4: 'Book-Using or Holding',
  a5: 'Instructional Tool-Using or Holding',
  a6: 'Student Writing',
  a7: 'Teacher Writing',
  a8: 'Raising Hand',
  a9: 'Presentation with Technology',
  a10: 'Individual Technology',
  a11: 'Worksheet-Using or Holding',
  a12: 'Notebook-Using or Holding',
  a13: 'Student(s) Carpet or Floor-Sitting',
  a14: 'Student(s) Desks-Sitting',
  a15: 'Student(s) Group Tables-Sitting',
  a16: 'Student(s) Standing or Walking',
  a17: 'Teacher Sitting',
  a18: 'Teacher Standing (T)',
  a19: 'Teacher Walking',
  a20: 'Teacher Supporting One Student',
  a21: 'Teacher Supporting Multiple with SS Interaction',
  a22: 'Teacher Supporting Multiple without SS Interaction',
  a23: 'On Task Student Talking with Student',
  a24: 'Transition',
};

export const ACTIVITY_COLORS = {
  a1: {bg: '#5f2692', text: 'white', icon: 'fa-people-roof', ic: 'white', type: 'Activity Type'},
  a2: {bg: '#893ccd', text: 'white', icon: 'fa-person', ic: 'white', type: 'Activity Type'},
  a3: {bg: '#b17dde', text: 'white', icon: 'fa-user-group', ic: '#333', type: 'Activity Type'},
  a4: {bg: '#005126', text: 'white', icon: 'fa-book-open', ic: 'white', type: 'Representing Content'},
  a5: {bg: '#66a36e', text: 'white', icon: 'fa-screwdriver-wrench', ic: 'white', isWide: true, type: 'Representing Content'},
  a6:  {bg: '#c9f2c7', text: '#333', icon: 'fa-file-pen', ic: '#333', type: 'Representing Content'},
  a7:  {bg: '#3a7e42', text: 'white', icon: 'fa-file-pen', ic: 'white', type: 'Representing Content'},
  a8: {bg: '#dbccbd', text: '#333', icon: 'fa-hand', ic: '#333', type: 'Discourse'},
  a9:  {bg: '#96be8c', text: '#333', icon: 'fa-file-powerpoint', ic: '#333', isWide: true, type: 'Representing Content'},
  a10: {bg: '#81c589', text: 'white', icon: 'fa-laptop', ic: 'white', type: 'Representing Content'}, // Individual Technology',
  a11: {bg: '#399e5a', text: 'white', icon: 'fa-file-lines', ic: 'white', type: 'Representing Content'}, // 'Worksheet-Using or Holding',
  a12: {bg: '#81c589', text: 'white', icon: 'fa-book-open', ic: 'white', type: 'Representing Content'},
  a13: {bg: '#263fba', text: 'white', icon: 'fa-rug', ic: 'white', isWide: true, type: 'Student Location'},
  a14: {bg: '#19297c', text: 'white', icon: 'fa-chair', ic: 'white', type: 'Student Location'},
  a15: {bg: '#4aa9fc', text: 'white', icon: 'fa-chair', ic: 'white', type: 'Student Location'},
  a16: {bg: '#b6dcfe', text: '#333', icon: 'fa-person-walking', ic: '#333', type: 'Student Location'},
  a17: {bg: '#ff8595', text: '#333', icon: 'fa-chair', ic: '#333', type: 'Teacher Location'},
  a18: {bg: '#ffadb8', text: '#333', icon: 'fa-person', ic: '#333', type: 'Teacher Location'},
  a19: {bg: '#ffd6da', text: '#333', icon: 'fa-person-walking', ic: '#333', type: 'Teacher Location'},
  a20:{bg: '#e7520d', text: 'white', icon: 'fa-people-arrows', ic: 'white', isWide: true, type: 'Teacher Supporting'},
  a21:{bg: '#f4783e', text: '#333', icon: 'fa-people-line', ic: '#333', isWide: true, type: 'Teacher Supporting'},
  a22: {bg: '#fabb9e', text: '#333', icon: 'fa-people-line', ic: '#333', isWide: true, type: 'Teacher Supporting'},
  a23: {bg: '#c6ad94', text: '#333', icon: 'fa-people-arrows', ic: 'white', isWide: true, type: 'Discourse'},
  a24: {bg: '#e0b0ff', text: '#333', icon: 'fa-shuffle', ic: '#333', type: 'Activity Type'}
};

export const ACTIVITY_GROUPS = [
  {name: "Activity Type", column: 0},
  {name: "Teacher Supporting", column: 0},
  {name: "Teacher Location", column: 1},
  {name: "Student Location", column: 1},
  {name: 'Discourse', column: 2},
  {name: 'Representing Content', column: 2}
]


export const SUBJECT_COLUMNS = {
  "levels": [
    {
      "name": "ELEMENTARY",
        "topics": [
          {
            "name": "All",
            "subjects": [
              {
                "Topic": "na",
                "Subject": "Art"
              },
              {
                "Topic": "na"
              },
              {
                "Topic": "na",
                "Subject": "ELA"
              },
              {
                "Topic": "na"
              },
              {
                "Topic": "na",
                "Subject": "Languages"
              },
              {
                "Topic": "na"
              },
              {
                "Topic": "na",
                "Subject": "Math"
              },
              {
                "Topic": "na"
              },
              {
                "Topic": "na",
                "Subject": "Music"
              },
              {
                "Topic": "na"
              },
              {
                "Topic": "na",
                "Subject": "Science"
              },
              {
                "Topic": "na"
              },
              {
                "Topic": "na",
                "Subject": "Social Studies/History"
              },
              {
                "Topic": "na"
              }
            ]
          }
        ]
      },
     {
       "name": "SECONDARY",
        "topics": [
          {
            "name": "Math",
            "subjects": [
              {
                "Topic": "Math",
                "Subject": "Algebra I"
              },
              {
                "Topic": "Math"
              },
              {
                "Topic": "Math",
                "Subject": "Algebra II Algebra, Functions, and Data Analysis"
              },
              {
                "Topic": "Math"
              },
              {
                "Topic": "Math",
                "Subject": "AP Calculus AB"
              },
              {
                "Topic": "Math"
              },
              {
                "Topic": "Math",
                "Subject": "AP Calculus BC"
              },
              {
                "Topic": "Math"
              },
              {
                "Topic": "Math",
                "Subject": "Data Science"
              },
              {
                "Topic": "Math"
              },
              {
                "Topic": "Math",
                "Subject": "Geometry"
              },
              {
                "Topic": "Math"
              },
              {
                "Topic": "Math",
                "Subject": "Mathematics 6"
              },
              {
                "Topic": "Math"
              },
              {
                "Topic": "Math",
                "Subject": "Mathematics 7"
              },
              {
                "Topic": "Math"
              },
              {
                "Topic": "Math",
                "Subject": "Mathematics 8"
              },
              {
                "Topic": "Math"
              },
              {
                "Topic": "Math",
                "Subject": "Pre-Calculus I (Dual Enrollment MTH )"
              },
              {
                "Topic": "Math"
              },
              {
                "Topic": "Math",
                "Subject": "Pre-Calculus/Mathematical Analysis"
              },
              {
                "Topic": "Math"
              },
              {
                "Topic": "Math",
                "Subject": "AP Statistics"
              },
              {
                "Topic": "Math"
              },
              {
                "Topic": "Math",
                "Subject": "Trigonometry"
              },
              {
                "Topic": "Math"
              },
              {
                "Topic": "Math"
              },
            ]
          },
          {
            "name": "Science",
            "subjects": [
              {
                "Topic": "Science",
                "Subject": "AP Biology"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "AP Environmental Science"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "AP Physics 1"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "AP Physics 2"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "Biology I"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "Biology II: Anatomy/Physiology"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "Biology II: Ecology"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "Chemistry I"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "Earth Science I"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "Earth Science II: Astronomy"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "Earth Science II: Oceanography"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "Environmental Science"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "General Environmental Science I"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "Life Science 7"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "Physical Science 8"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "Physics I"
              },
              {
                "Topic": "Science"
              },
              {
                "Topic": "Science",
                "Subject": "Science 6"
              },
            ]
          },
          {
            "name": "History/Social Studies",
            "subjects": [
              {
                "Topic": "History/Social Studies",
                "Subject": "African American History+A83:A113"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "AP Comparative Government & Politics"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "AP European History"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "AP Human Geography"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "AP Macroeconomics"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "AP Microeconomics"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "AP Psychology"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "AP U.S. Government & Politics"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "AP U.S. History"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "AP World History: Modern"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "Civics & Economics (Grade 8)"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "Economic Essentials: Theory & Applications (Dual Enrollment ECO 150)"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "Economics & Personal Finance"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "Psychology"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "U.S. Government & Politics (Dual Enrollment PLS 135)"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "U.S. History to 1865 (Grade 6)"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "U.S. History: 1865 to the Present (Grade 7)"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "United States History Since 1865 (Dual Enrollment HIS 122)"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "United States History to 1877 (Dual Enrollment HIS 121)"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "Virginia & U.S. Government"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "Virginia & U.S. History"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "World Geography"
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "World History & Geography to 1500 A.D."
              },
              {
                "Topic": "History/Social Studies"
              },
              {
                "Topic": "History/Social Studies",
                "Subject": "World History & Geography: 1500 A.D. to the Present"
              },
            ]
          },
          {
            "name": "English",
            "subjects": [
              {
                "Topic": "English",
                "Subject": "AP English Language & Composition"
              },
              {
                "Topic": "English"
              },
              {
                "Topic": "English",
                "Subject": "AP English Literature & Composition"
              },
              {
                "Topic": "English"
              },
              {
                "Topic": "English",
                "Subject": "College Composition I (Dual Enrollment ENG 111)"
              },
              {
                "Topic": "English"
              },
              {
                "Topic": "English",
                "Subject": "College Composition II (Dual Enrollment ENG 112)"
              },
              {
                "Topic": "English"
              },
              {
                "Topic": "English",
                "Subject": "Comparative Mythology (Dual Enrollment HUM 256)"
              },
              {
                "Topic": "English"
              },
              {
                "Topic": "English",
                "Subject": "Creative Writing"
              },
              {
                "Topic": "English"
              },
              {
                "Topic": "English",
                "Subject": "English 10"
              },
              {
                "Topic": "English"
              },
              {
                "Topic": "English",
                "Subject": "English 11"
              },
              {
                "Topic": "English"
              },
              {
                "Topic": "English",
                "Subject": "English 12"
              },
              {
                "Topic": "English"
              },
              {
                "Topic": "English",
                "Subject": "English 6"
              },
              {
                "Topic": "English"
              },
              {
                "Topic": "English",
                "Subject": "English 7"
              },
              {
                "Topic": "English"
              },
              {
                "Topic": "English",
                "Subject": "English 8"
              },
              {
                "Topic": "English"
              },
              {
                "Topic": "English",
                "Subject": "English 9"
              },
              {
                "Topic": "English"
              },
              {
                "Topic": "English",
                "Subject": "World Mythology"
              }
            ]
          }
        ]
      }
  ]
};

export const GRADES = [
  { label: 'Pre-K', value: 'prek' },
  { label: 'Kindergarten', value: 'k' },
  { label: '1st Grade', value: '1' },
  { label: '2nd Grade', value: '2' },
  { label: '3rd Grade', value: '3' },
  { label: '4th Grade', value: '4' },
  { label: '5th Grade', value: '5' },
  { label: '6th Grade', value: '6' },
  { label: '7th Grade', value: '7' },
  { label: '8th Grade', value: '8' },
  { label: '9th Grade', value: '9' },
  { label: '10th Grade', value: '10' },
  { label: '11th Grade', value: '11' },
  { label: '12th Grade', value: '12' },
];

const MIXPANEL_TOKENS =  {
  local: '3a20682b581ad7316ba0a4a6f4bdaae9', // staging
  stage: '3a20682b581ad7316ba0a4a6f4bdaae9',
  prod: '',
};

export const MIXPANEL_TOKEN = MIXPANEL_TOKENS[STAGE];
export const MIXPANEL_DEBUG = STAGE !== 'prod';

export const MAX_COMPARISONS = 5;