import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, HStack, Heading, VStack, Text, Progress } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CreateLessonSchema } from '../../schemas/lessons.ts';
import { FormInput, FormSelect, FormTextarea } from '../../components/forms/FormElements';
import { useCreateLesson } from './components/createLessonHooks.js';
import UploadVideo from './components/UploadVideo.jsx';
import { useGetLesson } from '../Lesson/components/lessonHooks.js';
import { useUser } from '../../store/useAppStore.js';
import { useGetTeachers, useGetUsersClasses } from '../AdminOrganizations/components/orgHooks';
import trackEvent from '../../utlis/trackEvents.js';

const defaultValues = {
  name: '',
  lessonDate: '',
  summary: '',
  goal: '',
  videoKey: '',
  lessonId: '',
  class: '',
};
const CreateLesson = () => {
  const navigate = useNavigate();
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [isUploading, setIsUploading] = React.useState(false);
  const [chosenFiles, setChosenFiles] = React.useState([]);
  const [defaultClass, setDefaultClass] = React.useState('');
  const [searchParams] = useSearchParams();
  const reUploadId = searchParams.get('id');
  const user = useUser();
  const isntTeacher = user?.role.filter((role) => role === 'teacher' || role === 'practicing-teacher').length === 0;
  const [classOptions, setClassOptions] = useState([]);
  const [selectedTeacherEmail, setSelectedTeacherEmail] = useState('');
  const { data: lessonData } = useGetLesson(reUploadId || '');

  const { data: teachers } = useGetTeachers();

  const { handleSubmit, formState, register, control, setValue, watch, reset } = useForm(
    {
      defaultValues,
      resolver: zodResolver(CreateLessonSchema),
    },
    [lessonData],
  );

  const { createNewLesson } = useCreateLesson(reset);

  const selectedTeacherId = watch('teacher');
  const { data: userClasses } = useGetUsersClasses({
    userId: !isntTeacher ? user._id : selectedTeacherId?.value,
  });

  const teacherOptions = useMemo(() => {
    if (teachers) {
      return teachers.filter((t) => t.firstName).map((t) => ({ label: `${t.firstName} ${t.lastName}`, value: t._id }));
    }
    return [];
  }, [teachers]);

  useEffect(() => {
    if (selectedTeacherId && !reUploadId) {
      setValue('class', '');
    }
    if (selectedTeacherId) {
      const selectedTeacher = teachers.find((t) => t._id === selectedTeacherId.value);
      setSelectedTeacherEmail(selectedTeacher.email);
    }
  }, [selectedTeacherId]);

  useEffect(() => {
    if (defaultClass && !reUploadId) {
      const defaultClassOption = classOptions.find((c) => c.value === defaultClass);
      setValue('class', defaultClassOption);
    }
  }, [defaultClass]);

  useEffect(() => {
    if (userClasses) {
      const options = userClasses.map((c) => ({ label: c?.name, value: c._id }));
      setClassOptions(options);
    }
  }, [userClasses]);

  useEffect(() => {
    if (lessonData) {
      const updatedDefaultValues = {
        ...defaultValues,
        lessonId: lessonData.lesson.id,
        name: lessonData.lesson?.name,
        lessonDate: lessonData.lesson.lessonDate,
        summary: lessonData.lesson.summary,
        goal: lessonData.lesson.goal,
        class: {label: lessonData.lesson.classData?.name, value: lessonData.lesson.classId},
        teacher: {label: `${lessonData.lesson.teacherData.firstName} ${lessonData.lesson.teacherData.lastName}`, value: lessonData.lesson.teacherId}
      };
      reset(updatedDefaultValues);
      setDefaultClass(lessonData.lesson.classId);
    }
  }, [lessonData, reset]);

  const watchKey = watch('videoKey');

  const handleProgress = async (p) => {
    const progress = (p.loaded / p.total) * 100;
    if (progress <= 5) {
      setIsUploading(true);
      setUploadProgress(1.5);
    } else if (progress < 100) {
      setUploadProgress(Math.round(progress));
    } else {
      setUploadProgress(100);
      setIsUploading(false);
      setChosenFiles([]);
    }
  };

  const createLesson = (data) => {
    data.file = chosenFiles[0];
    trackEvent({
      page: 'Create Lesson',
      params: '',
      event: reUploadId ? 'Reupload Lesson' : 'Create Lesson',
      me: user,
      description: reUploadId ? 'Reupload Lesson' :'Create Lesson',
      details: reUploadId ? `Reupload Lesson: ${data?.name} in ${data.class.label}` :`Created Lesson: ${data?.name} in ${data.class.label}`,
    })
    if (reUploadId) {
      data.reupload = true;
    }
    createNewLesson.mutate({ data, handleProgress }); 
    //remove ?id= from url
    navigate('/lesson/create');
    reset({ ...defaultValues });
    // navigate(-1);
  };
  const cancel = () => {
    reset({ ...defaultValues });
    navigate(-1);
  };
  return (
    <VStack w='100%'>
      <Heading as='h1' fontSize='2xl' mb='4' alignSelf='start' ml='10%' mt='6'>
        Create Lesson
      </Heading>

      <Box
        as='form'
        onSubmit={handleSubmit(createLesson)}
        id='create-lesson'
        w='100%'
        display='flex'
        flexDirection='column'
        gap={4}
      >
        <VStack>
          <HStack display='flex' flexDirection='row' width='80%'>
            {isntTeacher && (
              <FormSelect
                options={teacherOptions}
                width='100%'
                name='teacher'
                label='Teacher'
                control={control}
                placeholder='Select Teacher'
                helperText='Select teacher to associate video with'
                marginBottom='40px'
                isDisabled={defaultClass}
              />
            )}
            <FormSelect
              options={classOptions}
              name='class'
              label='Class'
              width='100%'
              placeholder='Select Class'
              control={control}
              helperText='Select class to associate video with'
              marginBottom='40px'
              isDisabled={defaultClass}
            />
          </HStack>
          <HStack w='80%'>
            <FormInput
              name='name'
              label='Lesson Name'
              type='text'
              helperText='The only special characters allowed are "-" and "_"'
              register={register}
              errors={formState.errors}
              isDisabled={lessonData}
              w='100%'
            />
            <FormInput
              name='lessonDate'
              label='Lesson Date'
              w='100%'
              type='date'
              register={register}
              errors={formState.errors}
              isDisabled={lessonData}
              helperText=' '
            />
          </HStack>
          <Box w='80%' alignSelf='center'>
            <FormTextarea
              name='summary'
              label='Lesson Summary'
              register={register}
              errors={formState.errors}
              w='100%'
            />
            <FormTextarea
              name='goal'
              label='Lesson Goal'
              register={register}
              errors={formState.errors}
              w='100%'
            />
          </Box>

          <UploadVideo
            setValue={setValue}
            watch={watch}
            control={control}
            chosenFiles={chosenFiles}
            setChosenFiles={setChosenFiles}
            teachers={teachers}
            defaultClass={defaultClass}
            teacherEmail={isntTeacher ? selectedTeacherEmail : user.email}
          />
          {watchKey && (
            <HStack w='80%'>
              <Text>Video Key: {watchKey}</Text>
              <Text color='red'>{formState.errors.videoKey?.message}</Text>
            </HStack>
          )}
        </VStack>
        <HStack w='80%' alignSelf='center' justifyContent='center'>
          <Button variant='uva-outline' onClick={cancel} size='lg'>
            Cancel
          </Button>
          <Button variant='uva' type='submit' size='lg'>
            Create Lesson
          </Button>
        </HStack>
      </Box>
      {isUploading && (
        <Box textAlign='center' width='40%'>
          <Box as='span'>{uploadProgress}%</Box>
          <Progress value={uploadProgress} width='100%' marginTop='15' hasStripe isAnimated />
        </Box>
      )}
    </VStack>
  );
};

export default CreateLesson;
