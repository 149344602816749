import PropTypes from 'prop-types';
import { Image } from '@chakra-ui/react';

const Logo = ({ width, ...rest }) => (
  <Image src="https://aiai-static-assets.s3.amazonaws.com/AIAI_transparent.png" alt='uva aiai logo' width={width} objectFit='contain' {...rest} />
);
Logo.propTypes = {
  width: PropTypes.number,
};
Logo.defaultProps = {
  width: 50,
};
export default Logo;
