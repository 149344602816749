import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import Form from '../../../components/forms/Form';
import { Box } from '@chakra-ui/react';
import { FormInput, FormSelect } from '../../../components/forms/FormElements';
import { useAddOrganization, useGetOrganizationsByType, useUpdateOrganization } from './orgHooks';
import { US_STATES } from '../../../utlis/US_STATES';
import { SchoolSchema } from '../../../schemas/orgs.ts';
import { useUser } from '../../../store/useAppStore';
import trackEvent from '../../../utlis/trackEvents';

const defaultValues = {
  schoolId: '',
  name: '',
  city: '',
  state: { label: '', value: '' },
  parent: { name: '', _id: '' },
};
const SchoolForm = ({handleClose, defaultEditValues=null}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const addSchool = useAddOrganization(handleClose, setErrorMessage);
  // THIS COULD BE EITHER A DISTRICT OR A TEACHER PREP INSTITUTION
  const { data: options } = useGetOrganizationsByType({ orgType: ['institute', 'district'] });
  const updateOrganization = useUpdateOrganization();
  const user = useUser();

  const createSchool = (data) => {
    const organization = {
      ...data,
      type: 'school',
    };
    trackEvent({
      page: 'AdminOrgs',
      params: '',
      event: 'Create School',
      me: user,
      description: 'Create school',
      details: `Created School: ${data.name}, ${data.city}, ${data.state}, parent: ${data.parent}`,
      value: 0,
    })
    addSchool.mutate(organization);
  };

  const updateSchool = (data) => {
    const organization = {
      ...defaultEditValues,
      name: data.name,
      city: data.city,
      state: data.state,
      parent: data.parent,
    };
    trackEvent({
      page: 'AdminOrgs',
      params: '',
      event: 'Edit School',
      me: user,
      description: 'Edit school',
      details: `Edited School: ${data.name}, ${data.city}, ${data.state}, parent: ${data.parent}`,
      value: 0,
    })
    updateOrganization.mutate(organization);
    handleClose()
  }

  return (
    <Form
      id='create-school'
      onSubmit={defaultEditValues ? updateSchool : createSchool}
      resolver={zodResolver(SchoolSchema)}
      display='flex'
      flexDirection='column'
      gap={4}
      defaultValues={defaultEditValues || defaultValues}
    >
      <FormInput name='schoolId' label='School ID' maxLength='10' helperText='10 characters max' />
      <FormInput name='name' label='School Name' />
      <Box color='red.500'>{errorMessage}</Box>
      <FormInput name='city' label='City' />
      <FormSelect name='state' label='State' options={US_STATES} />
      <FormSelect
        name='parent'
        label='District or Teacher Preparation Institution (Parent)'
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option}
        options={options}
      />
    </Form>
  );
};
export default SchoolForm;
