// Create a generic component called CreateOrganization
import { useEffect, useState } from 'react';
import { Box, Button, Flex, HStack, Stack, VStack, Text,
        Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, Divider } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SupportTeam from '../../components/Contacts/SupportTeam';
import { FormInput, FormSelect, FormTextarea, FormRadios } from '../../components/forms/FormElements';

import { ClassSchema } from '../../schemas/orgs.ts';
import {
  useAddOrganization,
  useGetOrganizationsByType,
  useGetTeachers,
} from '../AdminOrganizations/components/orgHooks';
import { useUser } from '../../store/useAppStore';
import PageTitle from '../../components/PageTitle/PageTitle';
import { GRADES, SUBJECT_COLUMNS } from '../../constants';
import trackEvent from '../../utlis/trackEvents';
import { getParameterByName } from '../../utlis/utils';


// function that takes thats the current school year, ex 2023-24 and returns an array of the previous 2 years the current year and the next two years
const getSchoolYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  // eslint-disable-next-line no-plusplus
  for (let i = -2; i < 3; i++) {
    const val = `${currentYear + i}-${currentYear + i + 1}`;
    years.push({ label: val, value: val });
  }
  return years;
};
// function that takes the current date and returns the next time june 30th comes around in yyyy-mm-dd format
const getNextJune30 = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();
  const nextJune30 = new Date(currentYear, 5, 30);
  if (currentMonth > 5 || (currentMonth === 5 && currentDay > 30)) {
    nextJune30.setFullYear(currentYear + 1);
  }
  return nextJune30.toISOString().split('T')[0];
};
const getNextJune30FromYear = (years) => {
  const endYear = years.split('-')[1];
  const nextJune30 = new Date(endYear, 5, 30);
  return nextJune30.toISOString().split('T')[0];
};
// SET DEFAULT VALUES SO THEY CAN BE CLEARED
const defaultValues = {
  userId: { _id: '', firstName: '', lastName: '', isPracticingTeacher: false },
  name: '',
  description: '',
  grade: { label: '', value: '' },
  period: '',
  // subject: { label: '', value: '' },
  subject: '',
  subjectId: '',
  classId: '',
  districtId: '',
  institute: { _id: '', name: '' },
  district: { _id: '', name: '' },
  parent: { _id: '', name: '' },
  schoolYear: { label: '', value: '' },
  term: '',
  endDate: `${getNextJune30()}`,
};

// ****************************************** CreateOrganization entry point *************************************** //
const CreateOrganization = () => {
  const [requiredSupport, setRequiredSupport] = useState(false)
  const [selectSubjectOpen, setSelectSubjectOpen] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const { data: organizations } = useGetOrganizationsByType({
    orgType: ['institute', 'district', 'school'],
  });
  const { data: teachers } = useGetTeachers();
  const backUrl = getParameterByName('from');
  const user = useUser();
  const role = typeof user.role === 'string' ? user.role : user.role.join(', ');
  const isTeacher = role.includes('teacher');
  const [isPracticingTeacher, setIsPracticingTeacher ]= useState(role.includes('practicing'));
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleClose = () => {
    addClass.reset();
    if (isTeacher) {
      navigate('/my-classes');
    } else {
       navigate('/organizations');
    }
  };

  const addClass = useAddOrganization(handleClose, setErrorMessage, true);

  const { handleSubmit, formState, register, watch, control, setValue, reset } = useForm({
    defaultValues,
    resolver: zodResolver(ClassSchema),
  });
  
  const selectedTeacher = watch('userId')

  const checkIfCandidate = () => {
    let isCandidate = false;
    selectedTeacher.role.forEach((role) => {
      if (role.includes('candidate')) {
        isCandidate = true
      }
    })
    return isCandidate
  }

  const checkIfPracticing = () => {
    let isPracting = false;
    selectedTeacher.role.forEach((role) => {
      if (role.includes('practicing')) {
        isCandidate = true
      }
    })
    setIsPracticingTeacher(isPracting);
    return isPracting
  }


  const supportTeam = watch('supportTeam')

  useEffect(() => {
    if(selectedTeacher?.role) {
      setRequiredSupport(checkIfCandidate());
      setIsPracticingTeacher();
    }
  }, [selectedTeacher]);

  useEffect(() => {
    if (isTeacher) {
      setValue( 'userId', {
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        isPracticingTeacher,
        role: user.role,
      }
      )
    }
  }, [isTeacher, setValue, user._id, user.firstName, user.lastName])

  const subject = watch('subject');
  useEffect(() => {
    const s = subject;
    if (selectSubjectOpen) {
      setSelectSubjectOpen(false);
    }
  }, [subject])
  
  const createClass = (data) => {
      const newClass = {
        ...data,
        type: 'class',
      };
      trackEvent({
        page: 'Create Class',
        params: '',
        event: 'Create Class',
        me: user,
        description: 'Create Class',
        details: `Created Class: ${data.name}`,
      })
      addClass.mutate(newClass);
  };


  const instituteFilter = (options, field) => {
    // options.unshift({ _id: '', name: '', type: 'institute' })
    const institutes = options.filter((el) => el.type === 'institute')
    institutes.unshift({ _id: '', name: 'Not Applicable', type: 'institute' })
    return institutes;
  };
  const institute = watch('institute');
  const district = watch('district');
  const parent = watch('parent');

  useEffect(() => {
    if (institute?._id) {
      setValue('district', { _id: '', name: '' });
      setValue('parent', { _id: '', name: '' });
    }
  },[institute])
  useEffect(() => {
    if (district?._id) {
      setValue('parent', { _id: '', name: '' });
    }
  },[district])
  const descriptionLength = watch('description');
  const districtFilter = (options, field) => {
    const districts = options.filter((el) => el.type === 'district')
    districts.unshift({ _id: '', name: 'Not Applicable', type: 'district' })
    const filteredDistricts = districts.filter((el) => el?.parent === institute?._id)
    filteredDistricts.unshift({ _id: '', name: 'Not Applicable', type: 'district' })
    if (!institute?._id) return districts;
    return filteredDistricts;
  };
  const schoolFilter = (options, field) => {
    // options.unshift({ _id: '', name: '', type: 'school' })
    const schools = options.filter(
      (el) => el.type === 'school' && (el?.parent === institute?._id || el?.parent === district?._id),
    )
    schools.unshift({ _id: '', name: '', type: 'school' })
    if (!institute?._id && !district?._id) return [];
    return schools
  };
  
  const cancel = () => {
    reset({ ...defaultValues });
    if (backUrl === 'dashboard') {
      navigate('/');
      return;
    } else if (isTeacher) {
      navigate('/my-classes');
    } else {
       navigate('/organizations');
    }
  };
  const districtId = watch('districtId');
  const classId = watch('classId');
  const subjectId = watch('subjectId');
  const grade = watch('grade');
  const name = watch('name');
  const schoolYear = watch('schoolYear');

  useEffect(() => {
    let valid = false;

    let validForCandidate = requiredSupport ? false : (district.name || institute.name);
    if (requiredSupport) {
      validForCandidate = supportTeam.length > 0 && institute.name;
    }
    if (name 
        && subject 
        && schoolYear && schoolYear.value 
        && grade.value 
        && parent.name
        && validForCandidate) {
      valid = true;
    }
    setFormValid(valid);
  }, [subject, name, grade, schoolYear, institute, district, parent, supportTeam ])
  const renderClassForm = () => (
    <Box
      as='form'
      id='create-district'
      onSubmit={handleSubmit(createClass)}
      display='flex'
      flexDirection='column'
      gap={4}
      maxW={900}
      mx={8}
    >
      {isTeacher 
      ?
      <Box>
        <Text>For teacher: {user.firstName} {user.lastName}</Text>
        {/* {formState.errors.userId && <Text color='red'>{formState.errors.userId._id.message}</Text>} */}
      </Box>
        
      :
      <FormSelect
        name='userId'
        label='Practicing Teacher / Teacher Candidate*'
        options={teachers && teachers.length ? teachers.filter((t) => t.firstName) : []}
        control={control}
        register={register}
        errors={formState.errors}
        key='teacher'
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        getOptionValue={(option) => option}
        width={400}
      />
      }
      <FormInput
        width={500}
        name='name'
        label='Class Name*'
        register={register}
        errors={formState.errors}
      />

      <FormTextarea
        name='description'
        label='Description'
        register={register}
        errors={formState.errors}
        width={800}
        helperText={`${descriptionLength?.length || 0}/450`}
        maxLength={450}
      />
      <HStack gap={4}>
        <FormSelect
          name='grade'
          label='Grade*'
          options={GRADES}
          control={control}
          register={register}
          errors={formState.errors}
          width={275}
          key='grade'
        />
        <FormInput
          width={275}
          name='period'
          label='Period'
          register={register}
          errors={formState.errors}
        />
        {selectSubjectOpen
        ?
        <Modal isOpen={selectSubjectOpen} onClose={() => {setSelectSubjectOpen(false)}} size='xl'>
        <ModalOverlay />
        <ModalContent maxW="1100px">
          <ModalHeader>Select a Class Subject</ModalHeader>
          <ModalCloseButton />
          <ModalBody  pl={12}>
                <FormRadios
                  width={275}
                  name='subject'
                  label='Subject'
                  options={SUBJECT_COLUMNS}
                  control={control}
                  register={register}
                  errors={formState.errors}
                  key='subject'
                />
              </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => {setSelectSubjectOpen(false)}}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
        :
        <VStack align='stretch'  mt={'-7px'} spacing={2}>
          <Text fontSize={'sm'}>Subject*</Text>
            <HStack >
              <Text>{subject}</Text>
              <Button size='sm' ml={subject ? 4 : 0} onClick={() => setSelectSubjectOpen(true)}>
                {subject ? 'Change' : 'Select'}
                </Button>
            </HStack>
        </VStack>
        }
      </HStack>
      <HStack gap={4}>
        <FormInput
          width={275}
          name='subjectId'
          label='Subject ID'
          register={register}
          helperText={`${subjectId?.length || 0}/10`}
          maxLength={10}
          errors={formState.errors}
        />
        <FormInput
          width={275}
          name='classId'
          label='Class ID'
          register={register}
          helperText={`${classId?.length || 0}/10`}
          maxLength={10}
          errors={formState.errors}
        />
        <FormInput
          width={275}
          name='districtId'
          label='District ID'
          register={register}
          helperText={`${districtId?.length || 0}/10`}
          maxLength={10}
          errors={formState.errors}
        />
      </HStack>

      <VStack gap={4}>
          <Flex gap={4} flexWrap='wrap' flexDirection='row'>
        <FormSelect
          name='institute'
          width={275}
          label={`Teacher Preparation Institution ${requiredSupport ? '*' : ''}`}
          options={organizations}
          filter={instituteFilter}
          control={control}
          register={register}
          errors={formState.errors}
          key='institute'
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option}
        />
        <FormSelect
          name='district'
          width={275}
          label='District'
          options={organizations}
          filter={districtFilter}
          control={control}
          register={register}
          errors={formState.errors}
          key='district'
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option}
        />
        <FormSelect
          name='parent'
          label='School*'
          width={275}
          options={organizations}
          filter={schoolFilter}
          control={control}
          register={register}
          errors={formState.errors}
          key='school'
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option}
          noOptionsMessage={() => 'Select a Teacher Preparation Institution or district first'}
        />

        <FormSelect
          name='schoolYear'
          label='School Year*'
          options={getSchoolYears()}
          control={control}
          register={register}
          errors={formState.errors}
          onChange={(e) => {
            setValue('endDate', getNextJune30FromYear(e.value));
            setValue('schoolYear', e);
          }}
          width={275}
          key='school-year'
        />
        <FormInput
          width={275}
          name='term'
          label='Term'
          register={register}
          errors={formState.errors}
        />
        <FormInput
          width={275}
          name='endDate'
          label='Class End Date'
          type='date'
          register={register}
          errors={formState.errors}
        />
        </Flex>
        <Divider m='2' borderColor='#888' />
        <SupportTeam setValue={setValue} watch={watch} isRequired={requiredSupport} contactParentOrg={requiredSupport ? institute?._id : parent?._id}/>
       
        <Divider m='2' borderColor='#888' />
        <Flex gap={4}>
        <Button variant='uva-outline' onClick={cancel} >
          Cancel
        </Button>
        <Button variant='uva' type='submit' isDisabled={!formValid}>
          Create Class
        </Button>
        </Flex>
      </VStack>
      {/* SUPPORT MEMBERS */}
    </Box>
  );
  return (
    <>
      <PageTitle title='Create Class' />
      <Stack p={6}>
        {renderClassForm()}
      </Stack>
    </>
  );
};
CreateOrganization.propTypes = {};
export default CreateOrganization;
