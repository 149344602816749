import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, HStack, Box, Text } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { useUser } from '../../../store/useAppStore';

const UploadVideo = ({
  setValue,
  watch,
  chosenFiles,
  setChosenFiles,
  teacherEmail
}) => {
  const [viewVideoSrc, setViewVideoSrc] = useState(null);
  console.log(teacherEmail)

  const formKey = watch('videoKey');
  useEffect(() => {
    if (chosenFiles.length > 0) {
      const epoch = new Date().getTime();
      chosenFiles[0].name.replace('/', '_')
      const extention = chosenFiles[0]?.name.split('.').pop();
      const videoKey = `${teacherEmail}/${chosenFiles[0]?.name.split('.')[0]}-${epoch}.${extention}`;
      setValue('videoKey', videoKey);
    }
  }, [setValue, chosenFiles]);

  useEffect(() => {
    if (chosenFiles.length > 0) setViewVideoSrc(URL.createObjectURL(chosenFiles[0]));
    else setViewVideoSrc(null);
  }, [chosenFiles]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setChosenFiles([...chosenFiles, ...acceptedFiles]);
    },
    [chosenFiles],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });
  const files = chosenFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  return (
    <Box
      display='flex'
      width='100%'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Box w='100%' mt='6' justifyContent='center' alignItems='center' display='flex'>
        {!viewVideoSrc ? (
          formKey === '' && (
            <Box {...getRootProps()} w='40%'>
              <Box
                as='section'
                border='gray'
                display='flex'
                alignItems='center'
                justifyContent='center'
                borderRadius='8px'
                borderWidth='2px'
                borderStyle='dashed'
                backgroundColor='#c7c7c7'
                height='200px'
                width='100%'
              >
                <input className='h-full w-full' {...getInputProps()} />
                <Box
                  display='flex'
                  h='100%'
                  flexDirection='column'
                  justifyContent='center'
                  textAlign='center'
                  fontFamily='sans-serif'
                  padding='12px'
                >
                  <Text>Drop a file here or select it below</Text>
                  <Button type='button' onClick={open}>
                    Select File
                  </Button>
                </Box>
              </Box>
            </Box>
          )
        ) : (
          <Box as='aside' className='text-center'>
            <video controls={true} src={viewVideoSrc} />
          </Box>
        )}
      </Box>
      {files.length > 0 && (
        <Box as='aside' className='text-center'>
          <Text>selected file: </Text>
          <ul>{files}</ul>
        </Box>
      )}
    </Box>
  );
};
export default UploadVideo;
