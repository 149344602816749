/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import api from '../../queries/api';
import { useUser } from '../../store/useAppStore';
/* 
NOTE:
Query functions either need to throw an error or reject a promise in order for the onError callback to fire.
 */
const listCompares = async () => {
  const response = await api.get('/lessons/compare-list');
  return response.data.compares;
};

const getCompares = async ({queryKey}) => {
  const [, { lessonList }] = queryKey;
  const response = await api.post(`/lessons/get-compare-data`, { lessonList } );
  return response.data;
};

const getCompareSaves = async () => {
  const response = await api.get(`/lessons/get-compare-saves` );
  return response.data;
};


// ****************** exposed hook interfaces ************************* //
export const useListCompares = () => {
  const user = useUser();
  const toast = useToast();
  const query = useQuery({
    queryKey: ['compare-list', { user: user._id, userRole: user.role }],
    queryFn: listCompares,
    refetchOnWindowFocus: false,
    retry: false,
  });
  useEffect(() => {
    if (query.error) {
      console.log('GET COMPARISON LIST ERROR ', query.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching the comparisons list. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [query.error]);
  return query;
};


export const useGetCompares = (lessonList) => {
  const toast = useToast();
  const getCompareData = useQuery({
    queryKey: ['get-compare-data', { lessonList }],
    queryFn:getCompares,
    refetchOnWindowFocus: false,
    retry: false,
  });
  useEffect(() => {
    if (getCompareData.error) {
      console.log('GET COMPARED LESSONS ERROR ', getCompareData.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching compared lessons. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [getCompareData.error]);
  return getCompareData;
}

export const useGetCompareSaves = () => {
  const toast = useToast();
  const query = useQuery({
    queryKey: ['get-compare-saves', {}],
    queryFn:getCompareSaves,
    refetchOnWindowFocus: false,
    retry: false,
  });
  useEffect(() => {
    if (query.error) {
      console.log('GET SAVED COMPARES ERROR ', query.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching saved compared. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [query.error]);
  return query;
}

export const useSaveCompare = (saveId) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: async (newSave) => {
      await api.post('/lessons/save-compare', newSave);
    },
  });
  useEffect(() => {
    if (mutation.error) {
      console.log('EDIT SAVE COMPARE ERROR ', mutation.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching this comparison. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [mutation.error]);
  useEffect(() => {
    if (mutation.data) {
      queryClient.invalidateQueries({ queryKey: ['compare-save', { saveId }] });
    }
  }, [mutation.data]);
  return mutation;
};