import {
  Box,
  chakra,
  Container,
  HStack,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import Logo from './Logo';
import { LinkComp } from '../../components';

const ContactForm = ({ isOpen, onClose, handleContact, errors, register }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Contact Us</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <form onSubmit={handleContact} id='signin'>
          <VStack
            p='2'
            w='full'
            // bg={useColorModeValue('whiteAlpha.600', 'blackAlpha.600')}
            borderRadius='md'
            gap='4'
          >
            <FormControl isInvalid={errors.email}>
              <FormLabel>Your Email</FormLabel>
              <Input type='email' {...register('email', { required: 'Your email is required' })} />
              <FormErrorMessage> {errors.email && errors.email.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.subject}>
              <FormLabel>Subject</FormLabel>
              <Input type='subject' {...register('subject', { required: 'A subject required' })} />
              <FormErrorMessage> {errors.subject && errors.subject.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.message}>
              <FormLabel>Message</FormLabel>
              <Textarea
                type='message'
                {...register('message', { required: 'A message is required' })}
              />
              <FormErrorMessage> {errors.message && errors.message.message}</FormErrorMessage>
            </FormControl>
          </VStack>
        </form>
      </ModalBody>

      <ModalFooter>
        <Button variant='ghost' onClick={onClose}>
          Close
        </Button>
        <Button colorScheme='blue' mr={3} type='submit' value='submit' form='signin' variant='uva'>
          Submit
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
const Footer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleContact = () => {
    // TODO: API call for form submit
  };
  return (
    <>
      <HStack
        bg={useColorModeValue('secondary.900', 'secondary.950')}
        color='white'
        py={4}
        spacing={4}
        justify='space-between'
        align='center'
      >
        <Stack direction='row' spacing={6} alignItems='center' ml='8'>
          <Logo />
          <LinkComp to='/' label='Home' />
          <LinkComp to='/about' label='About' />
          <LinkComp onClick={onOpen} label='Contact Us' to='' />
        </Stack>
        <Text mr='8'>© {dayjs().year()} UVA AIAI. All rights reserved.</Text>
      </HStack>
      <ContactForm
        isOpen={isOpen}
        onClose={onClose}
        register={register}
        handleContact={handleSubmit(handleContact)}
        errors={errors}
      />
    </>
  );
};
export default Footer;
