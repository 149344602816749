import { Box, Button, VStack, HStack, IconButton } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useListSharedLessons, useUpdateShare } from '../../Lesson/components/lessonHooks';
import { useUser } from '../../../store/useAppStore';
import trackEvent from '../../../utlis/trackEvents';

const SharedLessonNotification = () => {
    const navigate = useNavigate();

    const user = useUser();
    const { data: sharedLessons, error: sharedLessonsError, refetch: refetchSharedLessons } = useListSharedLessons({ userId: user._id });
    const updateShare = useUpdateShare()
  const [unSeenLessons, setUnSeenLessons] = useState([]);

  useEffect(() => {
    setUnSeenLessons(sharedLessons?.filter((lesson) => lesson.shareStatus === "new"));
  }, [sharedLessons]);


  const setLessonViewed = (lessonId, status = 'cleared') => {

    updateShare.mutate(
      { lessonId, status },
      {
        onSuccess: () => {
          refetchSharedLessons();
        },
        onError: (err) => { alert(err) }
      },
    );
  }

  const viewLessonClick = (lesson) => {
    setLessonViewed(lesson._id, 'viewed'); 
    trackEvent({
      page: window.location.pathname === '/' ? 'Dashboard' : 'SharedLesson',
      params: '',
      event: 'View Shared Lesson',
      me: user,
      description: 'View Shared lesson',
      details: `Viewed Shared Lesson: ${lesson.lessonDetails.name}`,
      value: 0,
    })
    navigate(`/lesson/${lesson._id}`); 
  }
    
    if (!unSeenLessons || unSeenLessons.length === 0) {
      return null;
    }

  return (
    <span>
      {unSeenLessons?.map((lesson) => (
        <VStack key={`shared-lessons-${Math.random()}`} mb={2} align='stretch'>
          <Box
            bg='#f9dcbe'
            //  borderColor='red'
            //  border='2px'
            borderRadius='8px'
            w='auto'
            h='auto'
            p='8px'
            // m='40px'
            position="relative"
          >
            <HStack padding={0} key={lesson._id}>
              <Box mr='12px'>
                {lesson.teacherData.firstName} shared their lesson{' '}
                <b>{lesson.lessonDetails.name}</b>, with you
              </Box>
              <Button mr='12px' size='sm' variant='uva' onClick={() => { viewLessonClick(lesson)}}>
                View Lesson
              </Button>{' '}
              <div style={{ position: 'absolute', right: 8, top: 0 }}>
                <IconButton icon={<SmallCloseIcon />} background="transparent" size='sm' onClick={() => { setLessonViewed(lesson._id, 'cleared') }} />
              </div>
            </HStack>
          </Box>
        </VStack>
      ))}
    </span>
  );

};

export default SharedLessonNotification;
