import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import api from '../../queries/api';

/**
 * @description Hook to delete a lesson comment, and then invalidate the comment query
 * @param {string} lessonId
 */
export const useDeleteComment = (lessonId) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: async (id) => {
      await api.delete(`/comments/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lesson-comments', { lessonId }] });
    },
    onError: (err) => {
      console.log('EDIT COMMENT ERROR ', err);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching this lesson. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    },
  });
};
export const useAddComment = (lessonId) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: async (newComment) => {
      await api.post('/comments/new', newComment);
    },
  });
  useEffect(() => {
    if (mutation.error) {
      console.log('EDIT COMMENT ERROR ', mutation.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching this lesson. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [mutation.error]);
  useEffect(() => {
    if (mutation.data) {
      queryClient.invalidateQueries({ queryKey: ['lesson-comments', { lessonId }] });
    }
  }, [mutation.data]);
  return mutation;
};
export const useEditComment = (lessonId) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: async (newComment) => {
      await api.post('/comments/edit', newComment);
    },
    // THIS WILL AWAIT THE QUERY INVALIDATION BEFORE RESOLVING ISLOADING
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['lesson-comments', { lessonId }] }),
    onError: (err) => {
      console.log('EDIT COMMENT ERROR ', err);
      toast({
        title: 'Something went wrong.',
        description: 'There was an error fetching this lesson. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    },
  });
  useEffect(() => {
    if (mutation.error) {
      console.log('EDIT COMMENT ERROR ', mutation.error);
      toast({
        title: 'Something went wrong.',
        description: 'There was an editing this comment. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [mutation.error]);
  useEffect(() => {
    if (mutation.data) {
      queryClient.invalidateQueries({ queryKey: ['lesson-comments', { lessonId }] });
    }
  }, [mutation.data]);
  return mutation;
};

const getLessonComments = async ({ queryKey }) => {
  const [, { lessonId }] = queryKey;
  const response = await api.get(`/comments/${lessonId}`);
  return response.data.comments;
};
export const useGetLessonComments = (lessonId) => {
  const toast = useToast();
  const query = useQuery({
    queryKey: ['lesson-comments', { lessonId }],
    queryFn: getLessonComments,
    enabled: !!lessonId,
    refetchOnWindowFocus: false,
    retry: false,
  });
  useEffect(() => {
    if (query.error) {
      console.log('GET LESSON COMMENTS ERROR ', query.error);
      toast({
        title: 'Something went wrong.',
        description: `There was an error fetching this lesson's comments. Please try again.`,
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [query.error]);
  return query;
};

const getUserComments = async ({ queryKey }) => {
  const [, { userId }] = queryKey;
  const response = await api.get(`/comments/user/${userId}`);
  return response.data.comments;
};
export const useGetUserComments = (userId) => {
  const toast = useToast();
  const query = useQuery({
    queryKey: ['lesson-comments', { userId }],
    queryFn: getUserComments,
    enabled: !!userId,
    refetchOnWindowFocus: false,
    retry: false,
  });
  useEffect(() => {
    if (query.error) {
      console.log('GET LESSON COMMENTS ERROR ', query.error);
      toast({
        title: 'Something went wrong.',
        description: `There was an error fetching your lesson's comments. Please try again.`,
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [query.error]);
  return query;
};