import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
  VStack,
  useColorModeValue,
  Heading,
  Text
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

import VisitorPage from '../../components/VisitorPage/VisitorPage';
import api from '../../queries/api';
import { useNavigate } from 'react-router-dom';

const RecoverPW = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const handleRecover = async (data) => {
    setIsLoading(true);

    const response = await api.post('/users/auth/password/forgot', data);

    if(response){
      setEmailSent(true);
      toast({
        title: 'Success!',
        description: 'Email has been sent!',
        status: 'success',
        duration: 7000,
        isClosable: true,
      });
    }


    setIsLoading(false)
    // navigate('/');
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <VisitorPage>
      <Center h='full' w='full'>
        <form onSubmit={handleSubmit(handleRecover)} id='recoverpw'>
        <VStack
          w='lg'
          bg={useColorModeValue('whiteAlpha.800', 'blackAlpha.100')}
          borderRadius='lg'
          gap='3'
          shadow='md'
          pb='2rem'
        >
          {!emailSent ? (
            <>
          <Heading
            as='h2'
            size='lg'
            pt='4'
            color={useColorModeValue('secondary.900', 'secondary.100')}
          >
            Forgot Password
          </Heading>
          <Text p='3'> Enter your email address below to receive an email to reset your password</Text>
            <FormControl p='3' isInvalid={errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                type='email'
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Please Enter A Valid Email!',
                  },
                })}
              />
              <FormErrorMessage> {errors.email && errors.email.message}</FormErrorMessage>
            </FormControl>
            <Button type='submit' value='submit' form='recoverpw' variant='uva' isLoading={isLoading}>
              Reset Password
            </Button>
            </>
          ) : (
            <>
            <Heading
              as='h2'
              size='lg'
              pt='4'
              color={useColorModeValue('secondary.900', 'secondary.100')}
            >
              Reset Password Email Sent
            </Heading>
            <Text p='3'> A reset password email was sent to the email address you provided. Be sure to check you Spam folder if you don't see the email.</Text>
              <Button variant='uva' onClick={() => navigate('/signin')}>
                Close
              </Button>
              </>
          )}
          </VStack>
        </form>
      </Center>
    </VisitorPage>
  );
};
export default RecoverPW;
