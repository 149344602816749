import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateUser, useFilterUsers } from '../userHooks';
import { FormInput, FormSelect } from '../../../components/forms/FormElements';
import { AddUserSchema } from '../../../schemas/users.ts';
import { ROLES } from '../../../constants';
import { roleSelectHelper } from '../helpers';
import { useGetOrganizationsByType } from '../../AdminOrganizations/components/orgHooks';
import { useUser } from '../../../store/useAppStore';
import trackEvent from '../../../utlis/trackEvents';

const displayRolls = (roll) => {
  switch (roll) {
    case 'admin':
      return 'Admin';
    case 'instructor':
      return 'Instructor';
    case 'student':
      return 'Student';
    default:
      return '';
  }
};

const defaultValues = {
  role: [],
  school: { _id: '', name: '' },
  district: { _id: '', name: '' },
  institute: { _id: '', name: '' },
};

const CreateUserDrawer = ({ isOpen, users, onClose }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const user = useUser();
  const { handleSubmit, formState, register, watch, control, reset, clearErrors, resetField } =
    useForm({
      defaultValues,
      resolver: zodResolver(AddUserSchema),
    });
  const { data: organizations } = useGetOrganizationsByType({
    orgType: ['institute', 'district', 'school'],
  });

  const role = watch('role');
  useEffect(() => {
    clearErrors(['school', 'institute']);
  }, [role]);

  const institute = watch('institute');
  useEffect(() => {
    resetField('district');
    resetField('school');
  }, [institute]);
  const district = watch('district');
  useEffect(() => {
    resetField('school');
  }, [district]);

  const instituteFilter = (options, field) => {
    // options.unshift({ _id: '', name: '', type: 'institute' })
    const institutes = options.filter((el) => el.type === 'institute');
    institutes.unshift({ _id: '', name: 'Not Applicable', type: 'institute' });
    return institutes;
  };
  const districtFilter = (options, field) => {
    const districts = options.filter((el) => el.type === 'district');
    districts.unshift({ _id: '', name: 'Not Applicable', type: 'district' });
    const filteredDistricts = districts.filter((el) => el?.parent === institute?._id);
    filteredDistricts.unshift({ _id: '', name: 'Not Applicable', type: 'district' });
    if (!institute?._id) return districts;
    return filteredDistricts;
  };
  const schoolFilter = (options, field) => {
    // options.unshift({ _id: '', name: '', type: 'school' })
    const schools = options.filter(
      (el) =>
        el.type === 'school' && (el?.parent === institute?._id || el?.parent === district?._id),
    );
    schools.unshift({ _id: '', name: '', type: 'school' });
    if (!institute?._id && !district?._id) return [];
    return schools;
  };
  const roleFilter = (options, value) => roleSelectHelper(value);

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const addUser = useCreateUser(handleClose, setErrorMessage)
  const createUser = (data) => {
    trackEvent({
      page: 'AdminUsers',
      params: '',
      event: 'Invite User',
      me: user,
      description: 'Invite User',
      details: `invited user data: ${data}`,
      value: 0,
    })
    addUser.mutate(data);
  };
  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      size='md'
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Invite a User</DrawerHeader>
        <DrawerBody>
          <VStack px='4' gap='3'>
            <Box
              as='form'
              onSubmit={handleSubmit(createUser)}
              id='create-user'
              w='100%'
              display='flex'
              flexDirection='column'
              gap={4}
            >
              <FormInput
                name='email'
                label='Email'
                type='email'
                register={register}
                errors={formState.errors}
              />
              <Box color='red.500'>{errorMessage}</Box>
              <FormSelect
                name='role'
                label='Roles'
                options={ROLES}
                filter={roleFilter}
                isMulti
                control={control}
                register={register}
                errors={formState.errors}
              />
              <FormSelect
                name='institute'
                label='Teacher Preparation Institution'
                options={organizations}
                filter={instituteFilter}
                control={control}
                register={register}
                errors={formState.errors}
                key='institute'
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option}
                isClearable
              />
              <FormSelect
                name='district'
                label='District'
                options={organizations}
                filter={districtFilter}
                control={control}
                register={register}
                errors={formState.errors}
                key='district'
                isClearable
                clearValue={() => resetField('district')}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option}
              />
              <FormSelect
                name='school'
                label='School'
                isClearable
                options={organizations}
                filter={schoolFilter}
                control={control}
                register={register}
                errors={formState.errors}
                clearValue={() => resetField('school')}
                key='school'
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option}
              />
            </Box>
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <HStack>
            <Button variant='outline' mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='uva' type='submit' form='create-user'>
              Invite
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateUserDrawer;
