import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  HStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink
} from '@chakra-ui/react';

const PageTitle = (props) => {
  const { title, breadCrumbs, children } = props;
  return (
    <HStack className='font-semibold text-xl p-6' justifyContent='space-between'>
      {/* <Text>
        {title}
      </Text> */}
      {!breadCrumbs && (
        <Text>
          {title}
        </Text>
      )}
      {breadCrumbs && (
        <Breadcrumb>
          {breadCrumbs.map(crumb => (
            <span key={`bc-${crumb.label}`}>
              {crumb.link
                ?
                <span>
                <BreadcrumbItem>
                  <BreadcrumbLink href={crumb.link}>{crumb.label}</BreadcrumbLink>
                </BreadcrumbItem>
                &nbsp;/&nbsp; 
                </span>
                :
                <BreadcrumbItem>
                  <Text>{crumb.label}</Text>
                </BreadcrumbItem>
              }
            </span>
          ))}
        </Breadcrumb>
      )
     }
      {children}
    </HStack> 
  );
};

export default PageTitle;
