import { Box, Stack, Text, Button, Image, Center } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VisitorPage from '../../components/VisitorPage/VisitorPage';
import api from '../../queries/api';

const VisitorHome = () => {
  const [someState, setSomeState] = useState(false);

  const getSettings = async () => {
    const settings = await api.get('/info/settings?key=messages');
  }

  useEffect( () => {
    getSettings(); 
  }, [])
  
  return (
    <VisitorPage>
      <Stack p={10}>
        <Center>
      <Text fontSize={32} color='primary.400' textAlign='center' mt='27vh'>Welcome</Text>
        </Center>
      </Stack>
  </VisitorPage>
  );
};
export default VisitorHome;
