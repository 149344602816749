import {
  Box,
  Button,
  Center,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useAppActions } from '../../store/useAppStore';
import { LinkComp } from '../../components';
import Form from '../../components/forms/Form';
import { FormInput } from '../../components/forms/FormElements';
import { SignUpSchema } from '../../schemas/users.ts';
import api from '../../queries/api';
import VisitorPage from '../../components/VisitorPage/VisitorPage';

const SignUp = () => {
  // CONFIRM THE USER EXISTS IN DB BASED ON URL PARAM id
  // GET ENTER FIRST, LAST, EMAIL, PW CONFIRM PW
  // POST TO /users/auth/signup
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get('id');
  const { authenticateUser, setToken } = useAppActions();
  const toast = useToast();
  useToast();
  const [isLoading, setIsLoading] = useState(false);
  const handleSignIn = async (data) => {
    try {
      setIsLoading(true);
      const payload = {
        ...data,
        userId,
      };
      const response = await api.post('/users/auth/signup', payload);
      if (response.data._id) {
        console.log(response.data);
        authenticateUser(response.data);
        toast({
          title: 'Success!',
          description: 'Welcome to AIAI!',
          status: 'success',
          duration: 7000,
          isClosable: true,
        });
        navigate('/', { replace: true });
        setIsLoading(false);
        return null;
      }
      throw new Error(response.errorMessage);
    } catch (err) {
      console.log(err);
      toast({
        title: 'Error Signing Up',
        description: err?.response?.data?.message || err.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      navigate('/', { replace: true });
      setIsLoading(false);
      return null;
    }
  };
  const bgColor = useColorModeValue('whiteAlpha.800', 'blackAlpha.600');
  const col = useColorModeValue('secondary.900', 'secondary.100');

  useEffect(() => {
    if (!userId) {
      toast({
        title: 'Error',
        description:
          'No user was found to register. Please follow the link in your email, or contact an administator.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  }, [userId, toast]);
  if (!userId)
    return <VisitorPage />;

  return (
    <VisitorPage>
      <Center h='full' w='full'>
        <VStack bg={bgColor} borderRadius='lg' gap='4' shadow='md'>
          <Heading as='h2' size='lg' pt='4' color={col}>
            Register for AIAI
          </Heading>
          <Form
            onSubmit={handleSignIn}
            id='signup'
            resolver={zodResolver(SignUpSchema)}
            w='md'
            display='flex'
            flexDirection='column'
            gap={4}
            px='16'
            py='4'
          >
            <FormInput name='firstName' label='First Name' type='text' />
            <FormInput name='lastName' label='Last Name' type='text' />
            <FormInput name='email' label='Email' type='email' />
            <FormInput
              name='password'
              label='Password'
              type='password'
              helperText='Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
            />
            <FormInput name='confirm' label='Confirm Password' type='password' />
          </Form>
          <Button type='submit' value='submit' form='signup' variant='uva' isLoading={isLoading}>
            Register
          </Button>
          <Box pb='4'>
            <Text>
              Already have an account?{' '}
              <LinkComp to='/signin' label='Sign In' textColor='cyan.500' fontSize='sm' />
            </Text>
            <Text>
              Forgot your password?{' '}
              <LinkComp to='/recover' label='Reset Password' textColor='teal.500' fontSize='sm' />
            </Text>
          </Box>
        </VStack>
      </Center>
    </VisitorPage>
  );
};
export default SignUp;
