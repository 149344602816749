import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import Form from '../../../components/forms/Form';
import { FormInput } from '../../../components/forms/FormElements';
import { InstituteSchema } from '../../../schemas/orgs.ts';
import { useAddOrganization, useUpdateOrganization } from './orgHooks';
import { useUser } from '../../../store/useAppStore';
import trackEvent from '../../../utlis/trackEvents';

const defaultValues = {
  name: '',
  area: '',
};
const InstituteForm = ({handleClose, defaultEditValues=null}) => {
  // console.log(defaultEditValues)
  const [errorMessage, setErrorMessage] = useState('');
  const addInstitute = useAddOrganization(handleClose, setErrorMessage);
  const updateInstituteHook = useUpdateOrganization();
  const user = useUser();


  const createInstitute = (data) => {
    const organization = {
      ...data,
      type: 'institute',
      parent: null,
    };
    trackEvent({
      page: 'AdminOrgs',
      params: '',
      event: 'Create Institution',
      me: user,
      description: 'Create Institution',
      details: `Created Institution: ${data.name} in ${data.area}`,
      value: 0,
    })
    addInstitute.mutate(organization);
  };

  const updateInstitute = (data) => {
    const organization = {
      ...defaultEditValues,
      name: data.name,
      area: data.area,
    };
    trackEvent({
      page: 'AdminOrgs',
      params: '',
      event: 'Edit Institution',
      me: user,
      description: 'Edit Institution',
      details: `Edited Institution: ${data.name} in ${data.area}`,
      value: 0,
    })
    updateInstituteHook.mutate(organization);
    handleClose()
  }
  return (
    <Form
      id='create-institute'
      onSubmit={defaultEditValues ? updateInstitute : createInstitute}
      resolver={zodResolver(InstituteSchema)}
      display='flex'
      flexDirection='column'
      gap={4}
      defaultValues={defaultEditValues || defaultValues}
    >
      <FormInput name='name' label='Name of Institution' />
      <Box color='red.500'>{errorMessage}</Box>
      <FormInput name='area' label='Certification Area/TE Program' />
    </Form>
  );
};
export default InstituteForm;
